import React from 'react';
import { Dropdown } from 'antd';
import {modalTypes, useModalStore} from "store/modal";
import {DeleteModal} from "common/ui/modals/deleteModal/deleteModal";
import {useTranslation} from "react-i18next";
import "./table-action.scss";

export const TableActions = ({item,icon}) => {
  const {key,id, name} = item;
  const networkId= key || id;
  const { t } = useTranslation();
  const { openModal } = useModalStore((state) => state);
  const items = [
    {
      label: (<span>{t("initial.editName")}</span>),
      key: modalTypes.editProject,
    },
    {
      label: (<span>{t("initial.deleteProject")}</span>),
      key: modalTypes.deleteProject,
    },
  ];

  /*
  * @key: string = modalTypes
  * */
  const onClick = ({key}) => {
    openModal(key, {network: networkId, editName: name});
  };


	return <div onClick={(e) => {e.stopPropagation()}}>
    {/* on click params*/}
		<Dropdown
      onClick={(e) => {
        e.stopPropagation();
      }}
			rootClassName="table-action"
			menu={{items, onClick}}
			trigger={['click']}
		>
      <div onClick={e =>{e.stopPropagation();}}>
        {icon}
      </div>
		</Dropdown>
		<DeleteModal />
	</div>
}
