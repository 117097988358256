import Ethereum from "../../icons/cripto-icons/ethereum";
import Tron from "../../icons/cripto-icons/tron";
import BnbChain from "../../icons/cripto-icons/bnbChain";
import React, {useMemo} from "react";

const hardNetwork = [
  {
    code: "ETH",
    id: "633c8f01-a15c-4bf4-a83e-c3019bf04749",
    name: "Ethereum",
    icon:<Ethereum/>
  },
  {
    code: "TRX",
    id: "36514840-477a-4804-bd6e-d17985a0a677",
    name: "Tron",
    icon:<Tron/>
  },
  {
    code: "BSC",
    id: "5360a136-11bf-4702-8ca0-99f4589fd45f",
    name: "BNB Chain",
    icon:<BnbChain/>
  }
]

export const CreateItems = () => {
  return  useMemo(() => {
    return hardNetwork.map(item => ({
      label: <> {item.icon} {item.name} ({item.code}) </>,
      key: item.id
    }))
  }, [hardNetwork]);
}