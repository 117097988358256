import React, {useEffect} from "react";
import {DrawerComponent} from "common/ui/drawerComponent/drawerComponent";
import {modalTypes, useModalStore} from "store/modal";
import {useDrawerStore} from "store/drawer";
import {DetailsTab} from "../details-tab";
import {onNodeClickSetActive} from "../../utils/onNodeClickSetActive";
import {nodeCustomEvents} from "../../graph";
import UpdatePage from "../../utils/updatePage";

export const BlockDetails = ({graph}) => {
  const { openModal } = useModalStore((state) => state);
  const {node, setNode} = useDrawerStore(state => state);
  const {nodeClickQueryParams, updateItem,selectedNodeId} = UpdatePage();

  useEffect(() => {
    const onDelete = (event) => {
      if (event.target.cfg.name === 'delete-icon') {
        openModal(modalTypes.deleteProject, {network: event.item.getModel().id,
          editName: event.item.getModel().title,type:event.item.getModel().type}
        );
      }
    }

    const handleNodeClick = (event) => {
      onDelete(event);
      nodeClickQueryParams(event)
      if (event.target.cfg.name !== 'delete-icon') {
        onNodeClickSetActive(graph, event);
        setNode(event.item);
      }
    };

    if (!!graph) {
      graph.on('node:click', handleNodeClick);
      //TODO for mobile select node
      // graph.on('node:touchstart', handleNodeClick);
      graph.on('edge:click', handleNodeClick);
      graph.on('edge:touchstart', handleNodeClick);
    }
  }, [graph,setNode, openModal,nodeClickQueryParams]);

  useEffect(() => {
   updateItem({graph,setNode});
  }, [graph,updateItem,setNode]);

  const onClose = () => {
    if (graph) {
      graph.setItemState(node, nodeCustomEvents.clicked, false);
      graph.setItemState(node, nodeCustomEvents.edgeActive, false);
      graph.setItemState(node, nodeCustomEvents.edgeHover, false);
      window.history.replaceState({}, "", window.location.pathname);
    }
    setNode(null);
  }

  // if (getDeviceSize().isMobile) {
  //   return (
  //     <BottomSheetComponent  open={node} graph={graph} onClose={onClose} item={node}>
  //       { node && <DetailsTab item={node} /> }
  //     </BottomSheetComponent>
  //   );
  // }

  return (
    <DrawerComponent graph={graph} open={selectedNodeId && node} onClose={onClose}>
      {!!node && <DetailsTab item={node} graph={graph} />}
    </DrawerComponent>
  );
};

