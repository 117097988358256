import React from "react";
import {getDeviceSize} from "common/utils/responsive";
import Plus from "common/icons/plus";

const Columns = ({t}) => [
    {
      title: t("common.hashDate"),
      dataIndex: "hash",
      key: "hash",
      render: (item, row) => {
        return (
          <div className={row.status === "out" ? "active" : ""}>
            <p>{item.hash}</p>
            <span>{item.date}</span>
            {getDeviceSize().isMobile && <Plus
              className="plus-icon"
              pathColor="#D64686"
              viewBox="0 0 16 16"
              width={20}
              height={20}
            />}
          </div> || 'No Hash Available'
        );
      },
    },
    {
      title: t("common.value"),
      dataIndex: "value",
      key: "value",
      render: (item, row) => {
        return (
          <div className={row.status === "out" ? "active" : ""}>
            <b>{item.amount}</b>
            <span>{item.currency}</span>
          </div>
        );
      },
    },
    {
      title: t("common.counterparty"),
      dataIndex: "counterparty",
      key: "counterparty",
      render: (item, row) => {
        return (
          <div className={`action ${row.status === "incoming" ? "active" : ""}`}>
            <div id="action-wrapper">
              <div>
                <b>{item.name}</b>
                {item.tag && <span>{item.tag}</span>}
              </div>
              <div className='plus-icon'></div>
            </div>
          </div>
        );
      },
    },
  ];
export default Columns;
