import i18n from "../../../i18n";
import {ELanguages} from "../../../i18n/constants";


export const  countDecimals = (value) => {
  if ((value % 1) !== 0)
    return value.toString().split(".")[1].length;
  return 0;
};

export const  moneyFormat = (num,short) => {
  num = Math.abs(Number(num))
  const decimalSep = i18n.language === ELanguages.enUS ? '.' : ','

  const replace = (n) => {
    return n.toString().replace('.',decimalSep)
  }

  const localSep = (fixed,frac) => {
    return replace(fixed.toFixed(frac))
  }

  function toFixedTrunc(x, n) {
    const v = (typeof x === 'string' ? x : x.toString()).split('.');
    if (n <= 0) return v[0];
    let f = v[1] || '';
    if (f.length > n) return `${v[0]}.${f.substr(0,n)}`;
    while (f.length < n) f += '0';
    return `${v[0]}.${f}`
  }
  const getBigValue = (num,des) => {
    return replace(toFixedTrunc(num,short ? des + 1 : des))
  }
  const getValue = (num ,des) => {
    if(des < countDecimals(num)) {
      return replace(toFixedTrunc(num,des))
    } else {
      return replace(toFixedTrunc(num,countDecimals(num)))
    }
  }

  if (num >= 1.0e+15) {
    const number = num.toExponential(short ? 2 : 1).replace('.',decimalSep);
    return number.replace("e+", "e")
  }

  const trillion = num / 1.0e+12;
  const billions = num/1.0e+9
  const millions = num/1.0e+6
  const thousands = num/1.0e+3
  const zero = 0

  return num >= 1.0e+12 && trillion >= 100  ? getBigValue(trillion,0)  + "T"
    : num >= 1.0e+12 && trillion >= 10  ? getBigValue(trillion,1)   + "T"
      : num >= 1.0e+12                     ? getBigValue(trillion,2)   + "T"
        : num >= 1.0e+9 && billions >= 100  ? getBigValue(billions,0)  + "B"
          : num >= 1.0e+9 && billions >= 10   ? getBigValue(billions,1)   + "B"
            : num >= 1.0e+9                     ? getBigValue(billions,2)   + "B"
              : num >= 1.0e+6 && millions >= 100  ? getBigValue(millions,0)  + "M"
                : num >= 1.0e+6 && millions >= 10   ? getBigValue(millions,1)   + "M"
                  : num >= 1.0e+6                     ? getBigValue(millions,2)    + "M"
                    : num >= 1.0e+3 && thousands >= 100 ? getBigValue(thousands,0) + "K"
                      : num >= 1.0e+3 && thousands >= 10  ? getBigValue(thousands,1)  + "K"
                        : num >= 1.0e+3                     ? getBigValue(thousands,2)  + "K"
                          : num >= 1.0e+2                     ? getValue(num,short ? 2 : 1)
                            : num >= 10                         ? getValue(num,short ? 3 : 2)
                              : num >= 1                          ? getValue(num,short ? 4 : 3)
                                : num >= 0.001                    ? getValue(num,short ? 5 : 3)
                                  : num >= 0.00001                    ? (short && getValue(num, 5)) || localSep(zero,short ? 5 : 3)
                                    : localSep(zero,short ? 5 : 3)
}
