import React, {useMemo} from 'react';
import { Flex, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import './styles.scss'

export const ESpinnerType = {
  loading: "loading",
}
/*
* @iconType: string - enum SpinnerType
* */
const Spinner = (props) => {
  const { iconType } = props;

  const icon = useMemo(() => {
    switch (iconType) {
      case ESpinnerType.loading:
        return <Spin indicator={<LoadingOutlined spin />} className='custom-spinner' size="medium" {...props} />
      default:
        return <Spin className="default-spinner" size="large" {...props}/>
    }
  }, [iconType]);

  return (
    <Flex gap="middle">
      {icon}
    </Flex>
  )
}
export default Spinner;
