import React from 'react';
import {Button, ConfigProvider} from 'antd';
import './button.scss';

// Button Props
export const ButtonComponent = (rest) => {
	const { colorType, lineWidth, text, type } = rest;

	const colorPrimary = () => {
		switch (colorType) {
			case 'pink':
				return {
					color: '#fff',
					bg: '#D64686'
				};
			case 'red':
				return {
					color: '#fff',
					bg: '#E5424C'
				};
			case 'primary':
				return {
					color: '#fff',
					bg: '#748ADA'
				};
			case 'white':
				return {
					color: '#232325',
					bg: '#fff',
					border: '#D9D9D9',
				};
			default:
				return {
					color: '#fff',
					bg: '#E8E9F0'
				};
		}
	}

	return <ConfigProvider
		theme={{
			components: {
				Button: {
					colorTextLightSolid: colorPrimary().color,
					colorText: colorPrimary().color,
					colorPrimaryHover: colorPrimary().color,
					colorPrimaryActive: colorPrimary().color,

					colorBorder: colorPrimary().border || 'transparent',

					colorBgContainer: colorPrimary().bg,
					lineWidth: lineWidth || 0,
				},
			},
		}}
	>
		<Button size="large" {...rest} htmlType={type || 'button'}  className={`button ${rest.className || ''}`}>{text}</Button>
	</ConfigProvider>;
}

export default ButtonComponent;
