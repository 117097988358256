import React, {useEffect, useMemo} from 'react';
import {ConfigProvider, Dropdown} from 'antd';
import MenuOptions from "common/icons/menuOptions";
import {useNetworkStore} from "store/networks";
import {modalTypes, useModalStore} from "store/modal";

export const HeaderMenuDropdown = (rest) => {
  const { openModal } = useModalStore((state) => state);
  const {networks,getNetworks} = useNetworkStore((state) => state);

  useEffect( () => {
    getNetworks();
  }, []);
  const onChange = (e) => {
    openModal(modalTypes.createProject, {network: e.key});
  }


  const mobileItem = useMemo(() => {
    return networks.map(item => ({
      label: `${item.name} (${item.code})`,
      key: item.id
    }))
  }, [networks]);

  const items = rest.items || mobileItem ;
	const { icon, width } = rest;

	return <ConfigProvider
		theme={{
			components: {
				Dropdown: {
					colorError: '#E5424C',
					paddingXXS: 0,
					borderRadiusSM: 0,
				}
			}
		}}
	>
		<Dropdown
			align={{
				offset: [0, 11],
			}}
			overlayStyle={{
				width: width || 160,
			}}
			menu={{items, onClick: onChange }}
			trigger={['click']}
			{...rest}
		>
			{icon ? icon : <MenuOptions />}
		</Dropdown>
	</ConfigProvider>;
}

