import {useEffect} from "react";
import {useParams} from "react-router-dom";
import {useProjectStore} from "store/projects";
import InitialGraph from "./InitialGraph";
import Spinner from "common/ui/spinner";

export default function () {
	const { projectId } = useParams();
	const {objects, getObjects} = useProjectStore(s => s);

	useEffect(() => {
		if (!!projectId) {
			getObjects({id: projectId});
		}
	}, [projectId]);

	if (!objects?.wallets) {
		return <Spinner />
	}

	return <InitialGraph objects={objects} />
}
