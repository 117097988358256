export const EStorageKeys = {
	TOKEN: "token",
	TIMER: "timer",
	GREETING: "greeting",
	I18NEXT_LNG: "i18nextLng",
	UPDATE_RULES: "update_rules:id",
};

export function setStorage(key, data) {
	localStorage.setItem(key, JSON.stringify(data));
}

export function getStorageData(key) {
	try {
		const item = localStorage.getItem(key);
		if (item) {
			return JSON.parse(item);
		}
	} catch (err) {
		console.error(err);
	}
}

export function cleanStorage() {
	localStorage.clear();
}
