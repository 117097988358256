import React, {useEffect, useState} from "react"
import {useParams} from "react-router-dom";

import ArrowRight from "common/icons/arrowRight";
import Plus from "common/icons/plus";
import compressText from "common/utils/compressText";
import AssetAmount from "common/ui/assetAmount";
import Spinner, {ESpinnerType} from "common/ui/spinner";
import {useProjectStore} from "store/projects";
import {getNodes} from "../../../utils";
import {useAddNewTransfer} from "../../../utils/addTransfer";
import {useTranslation} from "react-i18next";
import "../search-panel.scss";
/*
* @type: string - searchItemType;
* @account: [];
* @transfers: [];
* */
export const SearchItem = ({wallets, transfers, fetching, searchValue, graph}) => {
  const { addWallet, walletTransferLoader, objects } = useProjectStore(s => s);
  const { projectId } = useParams();
  const { postNewTransfer } = useAddNewTransfer({graph, projectId});
  const [newWallet, setWallet] = useState();
  const {t} = useTranslation();

  useEffect(() => {
    if (newWallet) {
      const nodes = getNodes(objects?.transfers, objects.wallets);
      for (const node of nodes) {
        if (node.id === newWallet.id) {
          graph.addItem('node', node);
        }
      }
      setWallet(null);
    }
  }, [newWallet]);

  const addItem = async (ext_id, isWallet = false) => {
    if (isWallet) {
      const body = {id: projectId, ext_id, cb: (wallet) => setWallet(wallet)};
      await addWallet(body);
    } else {
      // to create new transfer
      await postNewTransfer(ext_id);
    }
  };
  if (!searchValue?.length) {
    return null;
  }

  if(fetching) {
    return <div className="item">
      <Spinner iconType={ESpinnerType.loading}/>
    </div>
  }

	if (!wallets?.length && !transfers?.length) {
		return <div className="item"><span>{t("common.noData")}</span></div>;
	}

  if (!!wallets?.length) {
    return wallets.map(account => (<div className="item">
      <p className='item__data-address'>{compressText(account.address ,37)}</p>
      {walletTransferLoader &&  <Spinner iconType={ESpinnerType.loading} className='spinner' size='small'/>}
      {!walletTransferLoader && (account.id  ? <span className='select'></span> :
        <Plus className="add" onClick={(() => addItem(account.ext_id, true))}/>)}
    </div>));
  }

  if (!!transfers?.length) {
    return transfers.map(trf => (<div className="item">
      <div>
        <p>{compressText(trf.sender_address, 6,6)}</p>
        <ArrowRight/>
        <p>{compressText(trf.recipient_address,6,6)}</p>
      </div>
      <div>
        <b>
          <AssetAmount
            amount={trf.amount}
            asset={trf.token_symbol || null}
            disableSign={true}
          />
        </b>
        {walletTransferLoader &&  <Spinner iconType={ESpinnerType.loading} className='spinner' size='small'/>}
        {!walletTransferLoader && (trf.id  ? <span className='select'></span> :
          <Plus className="add" onClick={(() => addItem(trf.ext_id))}/>)}
      </div>
    </div>))
  }
};
