import React, {useCallback, useMemo} from "react";
import {Skeleton} from "antd";
import {useProjectStore} from "store/projects";
import {useTranslation} from "react-i18next";
import {DEFAULT_PAGE_LIMIT} from "../../constants";
import './styles.scss'


const ShowMore = ({loadPage,page}) => {
  const { t } = useTranslation();
  const {projects, showMoreLoader, updateStatusShowMore, hideShowMoreButton } = useProjectStore(state => state);

  const showMore = useMemo(() => {
    return (projects?.next && !hideShowMoreButton
      && (page === 1 || projects?.results?.length > DEFAULT_PAGE_LIMIT));
  }, [projects?.next, hideShowMoreButton,projects?.results]);

  const onClick = useCallback( () => {
    if (loadPage) {
      updateStatusShowMore(false);
      loadPage(projects.next, false);
    }
  }, [loadPage]);

  if(showMore) {
    if (!showMoreLoader) {
      return <div className="table-footer" onClick={onClick}>
        <span>{t("action.showMore")}</span>
      </div>
    }

    return <div className='show-more-container'>
      <Skeleton.Button
        active={true}
        size="default"
        className="show-more__skeleton"
      />
    </div>;
  }
}
export default ShowMore;
