import {nodeCustomEvents} from "../graph";

let el;
export function onEdgeClickState(graph) {
	graph.on('edge:click', (ev) => {
		const edge = ev.item;
		if (el) {
			graph.setItemState(el, nodeCustomEvents.edgeActive, false);
		}
		graph.setItemState(edge, nodeCustomEvents.edgeActive, true);
		el = edge;
	});
}
