import React, {memo, useState} from "react";
import {getDeviceSize} from "common/utils/responsive";
import CounterParty from "./components/counterParty";
import ActionButton from "./components/actionButton";
import ItemWrapper from "./components/itemWrapper";
import HashMobile from "./components/hashMobile";
import HashDesktop from "./components/hashDesktop";
import {setNodeActiveOnTableHover} from "pages/Graph/utils/setNodeActiveOnTableHover";

const Item = memo(({ item,graph}) => {
  const [rowHover, setRowHover] = useState();
  const { isMobile } = getDeviceSize();
  const { onMouseLeave, onMouseEnter } = setNodeActiveOnTableHover({graph});

  const handleHover = (value) => {
    setRowHover(value);
  };

  return (
    <>
      <HashMobile item={item} rowHover={rowHover} isMobile={isMobile}/>
      <ItemWrapper
        item={item}
        handleHover={handleHover}
        rowHover={rowHover}
        onMouseLeave={onMouseLeave}
        onMouseEnter={onMouseEnter}
      >
        <HashDesktop item={item} isMobile={isMobile}/>
        <CounterParty item={item} handleHover={handleHover}/>
        {!isMobile && <ActionButton item={item} rowHover={rowHover}/>}
      </ItemWrapper>
    </>
  )
}, (prev, next) => prev.ext_id === next.ext_id);

export default Item;
