import React, {useState} from 'react';
import {modalTypes, useModalStore} from "store/modal";
import ModalContainer from "../modalContainer";
import ButtonComponent from "../../button/button";
import RedWarnIcon from "../../../icons/redWarnIcon";
import {useTranslation} from "react-i18next";
import {useProjectStore} from "store/projects";
import {useParams} from "react-router-dom";
import "./deleteModal.scss";

// delete props
export const DeleteModal = () => {
  const { modalType, closeModal, params } = useModalStore((state) => state);
  const { deleteProject ,getProjects,deleteWalletProject,getObjects,deleteTransferProject} = useProjectStore((state) => state);
  const { t } = useTranslation();
	const [loading, setLoading] = useState(false);
  const {projectId} = useParams()

  const onClick = async () => {
		if (loading) return;
    const eventParams = { id: params?.network };

    if(params.type) {
      setLoading(true);
      await deleteWalletProject(eventParams)
      if(!!projectId) {
        await getObjects({id:projectId})
      }
    }

    if(eventParams && !params.type) {
			setLoading(true);
      await deleteProject(eventParams);
      await getProjects();
    }
    closeModal();
		setLoading(false);
  };


  return (

		<ModalContainer closeModal={closeModal} width={416} open={modalType === modalTypes.deleteProject}
                    modalTypes={modalTypes}>
			<div className="modal-delete-wrapper">
				<div className="modal-delete-content">
					<RedWarnIcon/>
					<h6>{t("initial.deleteMemberTitle",
            {name: params?.editName ? params?.editName?.name || params?.editName : null})}</h6>
					<p>{t("initial.deleteModalText")} </p>
				</div>
				<div className="modal-delete-footer">
					<ButtonComponent lineWidth={1} onClick={() => closeModal()} colorType="white" text={t("action.cancel")} />
					<ButtonComponent colorType="red" loading={loading} onClick={onClick} text={t("action.delete")} />
				</div>
			</div>
		</ModalContainer>
	);
};
