import {nodeCustomEvents} from "../graph";

/**
 * graph: G6 graph element
 * event
 * */
export const onNodeClickSetActive = (graph, event) => {
  const clickedNodes = graph.findAllByState('node', nodeCustomEvents.clicked);
  for (let nodeItem of clickedNodes) {
    graph.setItemState(nodeItem, nodeCustomEvents.clicked, false);
  }
  graph.setItemState(event.item, nodeCustomEvents.clicked, true);
}
