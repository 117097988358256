import React, {useEffect, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useLocation} from "react-router-dom";

import {useProjectStore} from "store/projects";
import {modalTypes} from "store/modal";
import {useMeStore} from "store/me";

import {getDeviceSize} from "common/utils/responsive";
import {HeaderMenuDropdown} from "common/ui/headerMenuDropdown/headerMenuDropdown";
import RedPlus from "common/icons/redPlus";
import SelectLanguage from "common/ui/selectLanguage";
import {paths} from "router/paths";

const HeaderRight = () => {
  const { isMobile } = getDeviceSize();
  const { projects } = useProjectStore(state => state);
  const { getMeInfo, me } = useMeStore(s => s);
  const {pathname} = useLocation();
  const {t} = useTranslation();

  const isMobileWithProject = useMemo(() => {
    return isMobile && !!projects.results?.length;
  }, [projects.results, isMobile]);

  useEffect(() => {
    if (!me) {
      getMeInfo();
    }
  }, [])

  const items = [
    {
      label: (<span>{t("initial.editName")}</span>),
      key: modalTypes.editProject,
    },
    {
      label: (<span>{t("initial.deleteProject")}</span>),
      danger: true,
      key: modalTypes.deleteProject,
    },
  ];

  const headerRight = useMemo(() => {
    switch (pathname) {
      case paths.main:
        return isMobileWithProject ? (
          <HeaderMenuDropdown width={215} icon={<RedPlus />} />
        ) : null;

      default:
        return <HeaderMenuDropdown items={items} />;
    }
  }, [isMobileWithProject, pathname]);

  return <div className='header-right'>
    {isMobile ? headerRight :
      <>
        <SelectLanguage/>
        <div className="menu">
          <span>{me?.username}</span>
        </div>
      </>}
  </div>
}
export default HeaderRight