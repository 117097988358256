import {getPoints} from "../utils";

/**
 * transfers
 * */
export function getTransfers(data) {
	const transfersById = {};
	const result = [];

	function creatingTransferData(transfer, key, direction) {
		if (!transfersById[transfer[key]]) {
			transfersById[transfer[key]] = [{...transfer, direction}];
		} else {
			transfersById[transfer[key]].push({...transfer, direction});
		}
	}

	for (const transfer of data) {
		creatingTransferData(transfer, 'input_account_id', 1);
		creatingTransferData(transfer, 'output_account_id', 0);
	}

	for (let item in transfersById) {
		transfersById[item] = getPoints(data).transfers;
	}

	for (const trans of Object.values(transfersById)) {
		result.push(...trans);
	}

	return result;
}
