import { useMemo } from "react";
import {formatAssetAmount} from "common/utils/formatAssetAmount";
import eToNumber from "common/utils/eToNumber";
import Hint from "../hint";

const AssetAmount = ({amount, asset, sign, disableSign,className, align,isIncoming,valueUsd}) => {
  const assetAmount = useMemo(() =>
      formatAssetAmount({ amount, asset, sign, disableSign,isIncoming,valueUsd }),
    [amount, asset, sign, disableSign,isIncoming,valueUsd]);

  return (
    <Hint
      content={<span>{eToNumber(amount || 0)}{'\u00A0'}{asset}</span>}
      trigger="hover"
      align={align}
    >
      <div className={className}>
        {assetAmount}
      </div>
    </Hint>
  );
};

export default AssetAmount;
