import {nodeCustomEvents} from "../graph";

export function setNodeActiveOnTableHover({graph}){
	const changeState = (record, value) => {
		if (!record.id) return;

		const transfer = graph.findById(record.id);

		if (transfer) {
			graph.setItemState(transfer, nodeCustomEvents.edgeHover, value);
		}
	}

	return {
		onMouseLeave: (wallet) => changeState(wallet, false),
		onMouseEnter: (wallet) => changeState(wallet, true),
	}
}
