import compressText from "common/utils/compressText";
import {formatAssetAmountString} from "common/utils/formatAssetAmount";
import {edgePercentageAnchors} from "./utils/edgePercentageAnchors";
import {getTransfers} from "./utils/getTransfers";
import {getWalletHeight} from "./utils/getWalletHeight";

function setPoints(wallet, points) {
	return wallet.map(item => {
		const node = {
			...item,
			title: compressText(item.entity_name || item.address, 6, 6),
			name: 'node',
			layoutOrder: 0,
			x: item.pos_x,
			y: item.pos_y,
			style: {
				height: 51,
			},
		};

		if (points[item.id]) {
			return {
				...node,
				anchorPoints: points[item.id],
				style: {
					height: getWalletHeight(points[item.id]),
				}
			}
		}

		return node;
	});
}

export function getNodes(transfers = [], wallets = []) {
	const mergedData = getPoints(transfers).nodes;
	const result = setPoints(wallets, mergedData);
	return result;
}

function setIndexByDate(data) {
	const result = {};
	const sortedData = getTransfers(data)

	for (const item of sortedData) {
		if (!result[item.id]) {
			result[item.id] = item;
		} else {
			result[item.id] = {...result[item.id], ...item};
		}
	}

	return Object.values(result);
}

// hard data
export const getEdges = (data) => {
	if (!data.length) {
		return [];
	}

	return setIndexByDate(data)
		.map(item => {
			return {
				source: item.sender_id,
				target: item.recipient_id,
				type: 'hvh',
				id: item.id,
				text: formatAssetAmountString({amount: item.value_usd, asset: item.token_symbol}),
				sourceAnchor: item.sourceAnchor,
				targetAnchor: item.targetAnchor,
			}
		});
}

function sortByOccurredAt(data) {
	return data.sort((a, b) => new Date(b.occurred_at) - new Date(a.occurred_at))
}

export function getPoints(data) {
	const nodes = {};
	const sortedData = sortByOccurredAt(data);
	const transfers = [];

	function sortData(item, key, direction) {
		if (!nodes[item[key]]) {
			nodes[item[key]] = [{...item, direction}];
		} else {
			nodes[item[key]].push({...item, direction});
		}
	}
	// should sort by date
	sortedData.forEach(item => {
		sortData(item, 'recipient_id', 0);
		sortData(item, 'sender_id', 1);
	});

	for (const [id, val] of Object.entries(nodes)) {
		nodes[id] = val.map((item, index) => {
			const obj = {};
			obj[item.direction ? 'sourceAnchor' : 'targetAnchor'] = index;
			transfers.push({...item, ...obj});
			return [item.direction, edgePercentageAnchors(val.length)[index]]
		});
	}

	return {
		nodes,
		transfers,
	};
}
