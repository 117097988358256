import {create} from 'zustand';
import {persist} from "zustand/middleware";
import request from "../api";
import {showError, showSuccess} from "common/utils/notification";
import {DEFAULT_PAGE_LIMIT} from "pages/Initial/initial-table/constants";

export const useProjectStore = create(persist((set, get) => ({
	projects: {},
  singleProject:{},
	searchData: {},
	objects: {
		transfers: null,
		wallets: null,
	},
	params: {
		size: DEFAULT_PAGE_LIMIT,
		page: 1,
	},
	fetching: false,
	showMoreLoader: false,
	hideShowMoreButton: false,
	walletTransferLoader:  false,
	graphData: {
		nodes: [],
		combos: [],
		edges: [],
	},
	/*
	* @data: Object[]
	* */
	updatePosition: async (data) => {
		const updates = [];
		for (const { id, pos_x, pos_y } of data) {
			updates.push(request.post(`/graph/wallets/${id}/update-position/`, { pos_x, pos_y }));
		}

		await Promise.all(updates);
	},
	/**
	 * DTO data to wallet object {id, address, pos_x, pos_y}
	 * @wallets: wallet[
	 *   {
	 * 			"id": string,
	 * 			"address": string,
	 * 			"entity_name": string,
	 * 			"entity_category": string,
	 * 			"pos_x": number,
	 * 			"pos_y": number
	 * 		}
	 * ]
	 * @data: [
	 * {
	 *     "id": string,
	 *     "title": string,
	 *     "name": "node",
	 *     "layoutOrder": number,
	 *     "x": number,
	 *     "y": number,
	 *     "type": string,
	 *     "_order": number
	 * }
	 * ]
	 * */
	updateObjectsPosition: async (data) => {
		const wallets = data.map(item => ({
			id: item.id,
			pos_x: item.x,
			pos_y: item.y,
			name: item.title,
		}));

		set((state) => {
			state.updatePosition(wallets);
			return ({graphData: {...state.objects, wallets}});
		});
	},
	updateStatusShowMore: (status) => {
		set(() => ({hideShowMoreButton: status}));
	},
	/*
	* @params: {page: integer, size: integer}
	* */
	getProjects: async (fetching) => {
		set(() => ({fetching}));
		try {
			const {data} = await request.get(`/graph/projects`, {params: get().params});
			set(state => state.projects = data);
		} catch (_) {
			showError();
		} finally {
			set(() => ({fetching: false}));
		}
	},
	loadProjects: async () => {
		set(() => ({showMoreLoader: true}));
		try {
			const {data} = await request.get(`/graph/projects`, {params: get().params});
			const results = (get().projects?.results || []).concat(data?.results);
			set(() => ({
				projects: {...data, results},
			}));
		} catch (_) {
			showError();
		} finally {
			set(() => ({showMoreLoader: false}));
		}
	},
	/*
	* filters: {
	* 	page: number;
	* 	size: number;
	* }
	* reload flag for recalling request to /projects/
	* */
	updateProjectParams: async ({params, reload, fetching}) => {
		set(() => ({params}));

		if (reload) {
			await get().getProjects(fetching);
		}
	},

	/*
	* @id: uniq string of project id
	* */
	deleteProject: async (data) => {
		try {
			await request.post(`/graph/projects/${data.id}/delete/`);

			function removeItem(results) {
				const index = results.findIndex(item => item.id === data.id);
				results.splice(index, 1);
				return results;
			}

			set((state) => ({
				projects: {
					...state.projects,
					results: removeItem(state.projects?.results)
				},
			}));

			showSuccess();
		} catch (error) {
			showError();
		}
	},
	/*
	* @data: {name: string}
	* @id: uniq string of project id
	* */
	editProject: async (project, cb) => {
		try {
			const {data} = await request.post(`/graph/projects/${project.id}/edit/`, {name: project.name});
			// update by item id
      set(() => ({singleProject: data}));

      if (!!get().projects?.results) {
        set((state) => ({
          projects: {
            ...state.projects,
            results: state.projects.results.map(item => item.id === data.id ? data : item)
          }
        }));
      }

			cb();
			showSuccess();
		} catch (_) {
			showError();
		}
	},
	/*
	* network берем из запроса API/catalog/networks/
	* @data: { network: $uuid, name: string }
	* @cb: () => void;
	* */
	createProject: async (data, cb) => {
		try {
			await request.post('/graph/projects/create/', {network: data.id, name: data.name});
			showSuccess();
			get().getProjects();
			cb();
		} catch (_) {
			showError();
		}
	},
	/*
	* @id: Project ID string
	* */
	getObjects: async ({id}) => {
		try {
			const {data} = await request.get(`/graph/projects/${id}/objects/`);
			const wallets = data.wallets.map(item => ({...item, x: item.pos_x, y: item.pos_y}));
			set(() => ({objects: {wallets, transfers: data.transfers}}));
		} catch (error) {
			if (error.status === 404) {
				window.location.replace('/');
			}
			showError();
		}
	},
	/*
	* @data: { query: String - search input, id: String - project if };
	* return => {
			query: string,
			accounts: [{
				ext_id: string
				account_id: string,
				address: string
			}],
			transfers: [{
				ext_id: string;
				transfer_id: string;
				tx_hash: string;
				amount: number;
				sender_address: string;
				recipient_address: string;
			}]
		}
	* */
	searchProject: async ({ query, id, cb}) => {
		try {
			const { data } = await request.post(`/graph/projects/${id}/search/`, {query});
			set(() => ({searchData: data}));
		} catch (_) {
			showError();
		} finally {
			if (cb) {
				cb();
			}
		}
	},
	addWallet: async ({id, ext_id}) => {
		set(() => ({walletTransferLoader:true}))
		try {
			const { data } = await request.post(`/graph/projects/${id}/add-wallet/`, {ext_id});
			set(state => ({
				searchData: {
					...state.searchData,
					wallets: state.searchData.wallets.map((item) => item.address === data.address ? ({...item, id: data.id}) : item)
				}
			}));
			showSuccess();
			await get().getObjects({id});
		} catch (_) {
			showError();
		} finally {
			set(() => ({walletTransferLoader:false}))
		}
	},

	addTransfer: async ({id, ext_id}) => {
		set(() => ({walletTransferLoader:true}))
		try {
			const { data } = await request.post(`/graph/projects/${id}/add-transfer/`, {ext_id});
			set(state => ({
				searchData: {
					...state.searchData,
					transfers: state.searchData.transfers.map((item) => item.tx_hash === data.tx_hash ? ({...item, id: data.id}) : item)
				}
			}));
			showSuccess();
			await get().getObjects({id});
		} catch (_) {
			showError();
		} finally {
			set(() => ({walletTransferLoader:false}))
		}
	},

	deleteWalletProject:async (data) => {
		try {
			await request.post(`/graph/wallets/${data.id}/delete/`);
			function removeItem(results) {
				const index = results.findIndex(item => item.id === data.id);
				results.splice(index, 1);
				return results;
			}
			set((state) => ({
				objects: {
					...state.objects,
					wallets: removeItem(state.objects?.wallets)
				},
			}));
			showSuccess();
		} catch (error) {
			showError();
		}
	},
	deleteTransferProject:async (data) => {
		try {
			await request.post(`/graph/transfers/${data.id}/delete/`);
			function removeItem(results) {
				const index = results.findIndex(item => item.id === data.id);
				results.splice(index, 1);
				return results;
			}
			set((state) => ({
				objects: {
					...state.objects,
					transfers: removeItem(state.objects?.transfers)
				},
			}));
			showSuccess();
		} catch (error) {
			showError();
		}
	},
  getSingleProject: async ({id}) => {
    try {
      const {data} = await request.get(`/graph/projects/${id}/`);
      // console.log(data,'data')
      set(state => state.singleProject =  data);
    } catch (_) {
      showError();
    }
  },
	resetSearchProject: async () => {
		set(() => ({searchData:null}));
	},
}), {
	name: 'graph',
	partialize: (state) => ({graphData: state.graphData})
}));



