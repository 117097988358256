import G6 from "@antv/g6";
import useQuery from "hooks/useQuery";
import {useRef, useState} from "react";
import {EQuery} from "hooks/useQueryState";
import {useNavigate} from "react-router-dom";
import {colors, nodeCustomEvents} from "../graph";

const UpdatePage = () => {
  const queryParams = useQuery();
  const [selectedNodeId, setSelectedNodeId] = useState(queryParams.get(EQuery.walletId) || null);
  const previousNodeRef = useRef(null);
  const navigate = useNavigate();

  const nodeClickQueryParams = (event) => {
    const nodeId = event.item.getModel().id;
    const newSearch = `?${queryParams.toString()}`;
    queryParams.set(EQuery.walletId, nodeId);
    setSelectedNodeId(nodeId);
    navigate({ search: newSearch });
  }

  const updateItem = ({ graph, setNode }) => {
    if (selectedNodeId && graph) {
      const nodeItem = graph.findById(selectedNodeId);
      const isEdge = nodeItem.getType() === 'edge';

      if (nodeItem) {
        if (previousNodeRef.current !== nodeItem) {
          if (previousNodeRef.current) {
            if (previousNodeRef.current.getType() === 'edge') {
              graph.updateItem(previousNodeRef.current, {
                style: {
                  stroke: colors.edgeColor,
                  lineWidth:1,
                  endArrow:{path: G6.Arrow.triangle(5, 5, 5), d: 5, fill: colors.edgeColor},
                }
              });
              graph.setItemState(previousNodeRef.current, nodeCustomEvents.edgeActive, false);
              graph.setItemState(previousNodeRef.current, nodeCustomEvents.edgeHover, false);
            } else {
              graph.updateItem(previousNodeRef.current, { style:null });
              graph.setItemState(previousNodeRef.current, nodeCustomEvents.clicked, false);
            }
          }
          graph.updateItem(nodeItem, {
            style: {
              stroke: colors.hoverPointStroke,
              shadowBlur: !isEdge && 10,
              shadowColor: !isEdge && 'rgba(0, 0, 0, 0.15)',
              shadowOffsetX:!isEdge && 3,
              shadowOffsetY: !isEdge && 3,
              lineWidth:!isEdge ?  2 : 1,
              endArrow: isEdge && {path: G6.Arrow.triangle(5, 5, 5), d: 5, fill: colors.hoverPointStroke}
            },
          });
          if (isEdge) {
            graph.setItemState(nodeItem, nodeCustomEvents.edgeActive, true);
            graph.setItemState(nodeItem, nodeCustomEvents.edgeHover, true);
          } else {
            graph.setItemState(nodeItem, nodeCustomEvents.clicked, true);
          }
          setNode(nodeItem);
          previousNodeRef.current = nodeItem;
        }
      }
    }}
  return {
    nodeClickQueryParams,
    updateItem,
    selectedNodeId
  }
}
export default UpdatePage;
