export function findItemPlace(blocks) {
	const blockWidth = 150;
	const blockHeight = 60;
	const gridSpacing = 10;

	function canPlaceBlock(newX, newY, blocks) {
		for (let block of blocks) {
			const blockX = block.pos_x || 0;
			const blockY = block.pos_y || 0;
			if (newX < blockX + blockWidth && newX + blockWidth > blockX &&
				newY < blockY + blockHeight && newY + blockHeight > blockY) {
				return false;
			}
		}
		return true;
	}

	function findClosestBlock(blocks) {
		let closestBlock = blocks[0];
		let minDistance = Infinity;

		for (let block of blocks) {
			const blockX = block.pos_x || 0;
			const blockY = block.pos_y || 0;
			const distance = Math.abs(blockX);
			if (distance < minDistance) {
				minDistance = distance;
				closestBlock = block;
			}
		}

		return closestBlock;
	}

	function findNearbyPlace(blocks, blockWidth, blockHeight) {
		const closestBlock = findClosestBlock(blocks);

		const closestX = closestBlock.pos_x || 0;
		const closestY = closestBlock.pos_y || 0;

		const possiblePositions = [
			{ pos_x: closestX, pos_y: closestY + blockHeight + gridSpacing },
			{ pos_x: closestX, pos_y: closestY - blockHeight - gridSpacing },
			{ pos_x: closestX + blockWidth + gridSpacing, pos_y: closestY },
			{ pos_x: closestX - blockWidth - gridSpacing, pos_y: closestY },
		];

		for (let pos of possiblePositions) {
			if (canPlaceBlock(pos.pos_x, pos.pos_y, blocks)) {
				return { pos_x: pos.pos_x, pos_y: pos.pos_y };
			}
		}

		return { pos_x: closestX + blockWidth + gridSpacing, pos_y: closestY + blockHeight + gridSpacing };
	}

	if (blocks.length === 0) {
		return { pos_x: 0, pos_y: 0 };
	}

	return findNearbyPlace(blocks, blockWidth, blockHeight);
}
