import React, {useMemo} from "react";
import Hint from "../hint";
import {moneyFormatWithAsset} from "common/utils/tokenAssetFormat/moneyFormatAsset";
import {moneyFullFormat} from "common/utils/tokenAssetFormat/moneyFullFormat";

const  MoneyAmount =  ({amount, asset,reverse,className,sign,isIncoming,disableSign,short,fullNumber,brackets,style,countDec,valueUsd})  =>  {
  const moneyAssetAmount = useMemo(() => {
    return moneyFormatWithAsset(
      {amount,asset,reverse,sign,isIncoming,className,disableSign,short,fullNumber,countDec,valueUsd})
  },[amount,asset,reverse,isIncoming,sign,className,disableSign,short,fullNumber,countDec,valueUsd])

  return (
    <Hint
      content={<span>{moneyFullFormat({amount})}{'\u00A0'}{asset}</span>}
      trigger="hover"
      style={style}
    >
      <div>
        {brackets && <span>{"("}</span>}{'\u00A0'}
        {moneyAssetAmount}
        {brackets && <span>{")"}</span>}
      </div>
    </Hint>
  );
}
export default MoneyAmount