import React from 'react';
import { ConfigProvider, Modal} from 'antd';

const ModalContainer = (rest) => {
  const {open, closeModal, children,modalTypes} = rest;

  const styles = {
    content: {
      padding: 0,
    },
    mask:{
      backgroundColor: modalTypes?.deleteProject ? 'rgba(0, 0, 0, 0.13)' : 'rgba(0, 0, 0, 0.45)'
    }
  };
	return (
		<ConfigProvider
      modal={{styles}}>
			<Modal
				centered
				closeIcon={null}
				open={open}
				onOk={closeModal}
				okText={null}
				onCancel={closeModal}
				width={636}
				footer={null}
				{...rest}
			>{children}</Modal>
		</ConfigProvider>
	);
};
export default ModalContainer;
