import i18n from "../../../i18n";
import {ELanguages} from "../../../i18n/constants";
import {commafy} from "common/utils/commafy";
import eToNumber from "common/utils/eToNumber";
import compressText from "common/utils/compressText";

export const moneyFullFormat = ({amount,countDec}) => {
  const length = amount?.toString().length
  if ( amount === null || amount === undefined) return `???`;
  const decimalSep = i18n.language === ELanguages.enUS ? '.' : ',';
  const result = `${commafy(eToNumber(amount)).toString().replace('.',decimalSep)}`
  return compressText(result,(countDec || length + 3))
}