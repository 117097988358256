import React from "react";
import {Controller} from "react-hook-form";
import {Form, Input} from "antd";
import PasswordShowIcon from "../../icons/passwordShowIcon";
import PasswordHidden from "../../icons/passwordHidden";

const ControlledInput = ({control,className,type,name, label, errorText}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Form.Item
          validateStatus={typeof errorText === 'string' ? 'error' : ''}
          label={label}>
          {type === 'password' ?
            <Input.Password
              {...field}
              className={className}
              type={type}
              iconRender={visible => (
                <div>{visible ? <PasswordShowIcon /> : <PasswordHidden />}</div>
              )}/> :
            <Input
              {...field}
              className={className}
              type={type}/>}
          <p className={`validate${errorText ? ' validate-active' : ''}`}>{errorText}</p>
        </Form.Item>
      )}
    />
  )
}
export default ControlledInput;
