import React from "react";
import cn from "classnames";
import {COLUMN_CLASSNAME, EColumns} from "../../../../../../constants";
import CopyTooltip from "common/ui/copyTooltip";
import compressText from "common/utils/compressText";
import {getCategoryTranslations} from "common/utils/categoryTranslation";
import MoneyAmount from "common/ui/moneyAmount";

const CounterParty = ({item,handleHover}) => {
  return (
    <>
      <div className={cn(COLUMN_CLASSNAME, `_${EColumns.value}`)}>
        <p>
          <span className='counterparty-entity'><MoneyAmount
            className='amount'
            amount={item.amount}
            short={true}
            asset={item.token_symbol}
            isIncoming={item.is_incoming}
            disableSign={false}/></span>
        </p>
        <span><MoneyAmount
          className='usd-value'
          valueUsd={true}
          amount={item.value_usd}
          asset={'$'}
          short={true}
          reverse={true}/></span>
      </div>
      <div
        className={cn(
          COLUMN_CLASSNAME,
          `_${EColumns.counterparty}`,
        )}
      >
        <CopyTooltip text={item.counterparty_address}>
          <p
            className={"hovered"}
            onMouseEnter={() => handleHover(false)}
            onMouseLeave={() => handleHover(true)}
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
            }}>
              <span
                className='counterparty-entity'>{item.counterparty_entity ?
                compressText(item.counterparty_entity,13) :
                compressText(item.counterparty_address, 6,4)}</span>
          </p>
        </CopyTooltip>
        <span>{getCategoryTranslations(item.counterparty_category)}</span>
      </div>
    </>
  )
}
export default CounterParty;