export const SignFormatAssetAmount = {
  MIN: "−",
  PLS: "+",
  AppEql: "≈"
}

export const _getSing = ({sign, disableSign,isIncoming,valueUsd}) => {
  if (disableSign) return '';
  if (sign) return sign;
  if(valueUsd) return SignFormatAssetAmount.AppEql;
  return (isIncoming) ? SignFormatAssetAmount.PLS : SignFormatAssetAmount.MIN ;
};