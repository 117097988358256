import React, { useMemo} from "react";
import Spinner, {ESpinnerType} from "common/ui/spinner";
import {useProjectStore} from "store/projects";
import Item from "./components/item";

const VirtualList = ({graph, loading}) => {
	const {walletTransfers } = useProjectStore(state => state);
  const isLoading = useMemo(() => {
   return  !walletTransfers || walletTransfers?.count >= 20 || loading
  },[walletTransfers,loading])

	return (
		<div className={"list_body__wrapper"}>
			{(walletTransfers?.transfers || [])?.map(item => <Item
				key={item.id || item.ext_id}
				{...item}
				item={item}
        graph={graph}
			/>)}
      <div className={isLoading ? 'loader-container' : ''}>
        { isLoading && <div className="loading">
            <Spinner iconType={ESpinnerType.loading} size="large"/>
          </div> }
      </div>
    </div>
  );
};

export default VirtualList;
