import round from "lodash/round";

import i18n from "i18next";

export const SignFormatAssetAmount = {
  MIN: "−",
  PLS: "+",
  AppEql: "≈"
}
const getMaxNumberOfSignificantDigits = (value) => {
  const rounded = round(value, 3);
  return rounded >= 1000 ? Math.ceil(rounded).toString().length : 4;
};

const formatAmount = (value, digits = 2) => Number(value).toLocaleString(i18n.language, {
  minimumSignificantDigits: digits,
  maximumSignificantDigits: getMaxNumberOfSignificantDigits(value)
});
const _amountStringRepresentation = (amount) =>
  amount >= 1000 ? formatAmount(Math.ceil(amount)) : formatAmount(amount);

const _formatStringRepresentation = ({amount, asset, sign}) => {
  const $amount = _amountStringRepresentation(amount);

  return <>{sign} {$amount} {asset}</>;
};

const _power = (value) => Math.floor(Math.log10(value));

const _amountNormalizedRepresentation = (amount) => {
  const exp = _power(amount);
  const w = Math.pow(10, exp);

  return {
    exp,
    mant: round(amount / w, 2)
  };
};

const _formatNormalizedRepresentation = ({amount, asset, sign}) => {
  const {mant, exp} = _amountNormalizedRepresentation(amount);

  return <>{sign} {mant} ∙ 10<sup>{exp}</sup> {asset}</>;
};

const _getSing = (amount, sign, disableSign,isIncoming,valueUsd) => {
  if (disableSign) return false;
  if (sign) return sign;
  if(valueUsd) return SignFormatAssetAmount.AppEql;
  return (isIncoming) ? SignFormatAssetAmount.PLS : SignFormatAssetAmount.MIN ;
};

export const formatAssetAmount = ({amount, asset, sign, disableSign,isIncoming,valueUsd}) => {
  if (!asset) return "???";
  if (amount === null || amount === undefined) {
    return "??? " + asset;
  }

  const $sign = _getSing(amount, sign, disableSign,isIncoming,valueUsd);
  const absAmount = Math.abs(amount);

  if (
    amount !== 0 &&
    (absAmount > 9_999_999_999
      || absAmount < 0.000_001
      || (absAmount > -6 && absAmount < -1 && String(absAmount).length > 10))
  ) {
    return _formatNormalizedRepresentation({amount: absAmount, asset, sign: $sign});
  }
  return _formatStringRepresentation({amount: absAmount, asset, sign: $sign});
};

export const formatAssetAmountString = ({ amount, asset }) => {
  if (!asset || amount === null || amount === undefined) return `??? ${asset || ''}`;

  const absAmount = Math.abs(amount);

  if (
    absAmount > 9_999_999_999 ||
    absAmount < 0.000001 ||
    (absAmount > -6 && absAmount < -1 && String(absAmount).length > 10)
  ) {
    return `${_amountNormalizedRepresentation(absAmount)} ${asset}`;
  }

  return `${_amountStringRepresentation(absAmount)} ${asset}`;
};

export default formatAssetAmount;
