const TransferIcon = (props) => (
  <svg
    width="11"
    height="10"
    viewBox="0 0 11 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path d="M10.1426 3.0715L7.4093 5.42864V4.12443H4.64258V2.0188L7.4093 2.0188V0.714355L10.1426 3.0715Z"
          stroke={props?.stroke || "#232325"} stroke-width="0.6" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M5.85693 7.55042H3.0826V8.85735L0.356934 6.53895L3.0826 4.14307V5.41872H5.85693V7.55042Z" stroke={props?.stroke || "#232325"}
          stroke-width="0.6" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
)
export default TransferIcon;