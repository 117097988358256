import React, {useEffect} from "react";
import { useParams} from "react-router-dom";

import { Layout } from 'antd';
import {Content} from "antd/lib/layout/layout";
import {useProjectStore} from "store/projects";

import HeaderLeft from "./contents/components/headerLeft";
import HeaderCenter from "./contents/components/headerCenter";
import HeaderRight from "./contents/components/headerRight";
import "./mainHeader.scss";

const { Header } = Layout;

export const MainHeader = () => {
  const { getSingleProject, singleProject } = useProjectStore(state => state);
  const { projectId } = useParams();

  useEffect(() => {
    if(!!projectId) {
      getSingleProject({id:projectId});
    }
  }, [projectId, getSingleProject]);

	return <Content>
		<Header>
      <div className='header-wrapper'>
        <HeaderLeft/>
        <HeaderCenter />
        <HeaderRight singleProject={singleProject}/>
      </div>
		</Header>
	</Content>
};
