import React, {useMemo} from "react";
import cn from "classnames";
import {debounce} from "lodash";
import {useParams} from "react-router-dom";

import {COLUMN_CLASSNAME, EColumns} from "../../../../../../constants";
import Button from "common/ui/buttonComponent/Button";
import IconEyeOpen from "common/icons/IconEyeOpen";
import IconCheck from "common/icons/IconCheck";
import IconPlus from "common/icons/IconPlus";
import {useProjectStore} from "store/projects";
import Spinner, {ESpinnerType} from "common/ui/spinner";
import {useAddNewTransfer} from "../../../../../../../../../utils/addTransfer";

let itemExtId = null;
const ActionButton = ({item, rowHover}) => {
  const { projectId } = useParams();
  const {walletTransferLoader, graphEl, updateWalletTransfers } = useProjectStore(s => s)
  const { postNewTransfer } = useAddNewTransfer({graph: graphEl, projectId});
  const isAddingItem = item.ext_id === itemExtId && walletTransferLoader;

  const onAddItemClick = () => {
    itemExtId = item.ext_id;

    if (item?.ext_id && !item.id) {
      postNewTransfer(item?.ext_id)
        .then(transfer => {
          updateWalletTransfers({...item, id: transfer?.id});
        });
    }
  }

  const icons = useMemo(() => {
    if (item.id && rowHover) {
      return <IconEyeOpen className='eyeOpen'/>;
    }
    if (item.id) {
      return <IconCheck className={'iconCheck'}/>;
    }
    if(isAddingItem) {
      return <Spinner iconType={ESpinnerType.loading} size='large'/>
    }
    return <IconPlus className={'iconPlus'} onClick={debounce(onAddItemClick, 100)}/>;
  }, [ rowHover, item,isAddingItem]);

  return (
    <div className={cn(COLUMN_CLASSNAME, `_${EColumns.actions}`)}>
      <Button className={cn({["_active"]: item.id})}>
        {icons}
      </Button>
    </div>
  )
}
export default ActionButton;