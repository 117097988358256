import { useRef } from "react";
import { Dropdown } from "antd";
import { useTranslation } from "react-i18next";
import {LANGUAGES} from "../../../i18n/constants";
import {onChangeLanguageHandler} from "../../../i18n/utils";
import {languageOption} from "common/utils/language";
import ArrowIcon from "../../icons/arrowIcon";
import './styles.scss'


const SelectLanguage= ({ placement, renderInWrapper}) => {
  const { i18n } = useTranslation();
  const wrapRef = useRef(null);
  const activeLang = languageOption(i18n.language);

  return (
    <div className="select-language" ref={wrapRef}>
      <Dropdown
        menu={{
          items: Object.keys(LANGUAGES).map(lng => ({
              key: lng,
              onClick: () => onChangeLanguageHandler(lng),
            label: LANGUAGES[lng].nativeName
        }))
        }}
        trigger={["click"]}
        placement={placement}
        className="select-language__dropdown__container"
        overlayClassName="select-language__dropdown"
        getPopupContainer={() => renderInWrapper ? wrapRef?.current || document.body : document.body}
      >
        <div><activeLang.icon/> {activeLang.nativeName} <ArrowIcon className="arrow"/></div>
      </Dropdown>
    </div>
  );
};

export default SelectLanguage;
