import axios from "axios";
import { setStorage, getStorageData, EStorageKeys } from "../common/utils/storageHelper";
import { showError } from "../common/utils/notification";
import { checkAuthPath } from "../common/utils/checkAuthPath";
import { handleError } from "../common/utils/errorApiHandler";

import i18n from "../i18n";
import {paths} from "../router/paths";

const baseURL = process.env.REACT_APP_MAIN_API;

const instance = axios.create({
	withCredentials: true,
	baseURL,
});

const formAcceptLanguage = (lng) => {
	lng = lng || localStorage.getItem(EStorageKeys.I18NEXT_LNG);

	switch (lng) {
		case "ru":
			return "ru-RU"; // замените на нужное значение
		case "en":
			return "en-US"; // замените на нужное значение
		default:
			return lng || navigator.language;
	}
};

instance.interceptors.request.use(
	(config) => {
		const token = getStorageData(EStorageKeys.TOKEN);

		if (token && token.access_token) {
			config.headers.Authorization = `Bearer ${token.access_token}`;
		}

		config.headers["Accept-Language"] = formAcceptLanguage(i18n.language);
		return config;
	},
	(error) => Promise.reject(error)
);

instance.interceptors.response.use(
	(response) => response,
	(error) => {
		const originalRequest = error.config;

		const token = getStorageData(EStorageKeys.TOKEN);

		if (token && token.refresh_token && error.response?.status === 401 && !originalRequest?._retry) {
			originalRequest._retry = true;
			return axios
				.post(`${baseURL}/auth/update-token/`, {
					refresh: token.refresh_token,
				})
				.then((res) => {
					if (res.status === 200) {
						setStorage(EStorageKeys.TOKEN, {
							...res.data,
							refresh_token: token.refresh_token,
						});
						return axios(originalRequest);
					}
				})
				.catch((err) => {
					if (err.response.status === 401) {
						localStorage.clear();
						return (window.location.href = paths.signIn);
					}
				});
		}

		if (!token?.refresh_token && error.response?.status === 401 && !originalRequest?._retry) {
			localStorage.clear();
			if (!checkAuthPath()) return (window.location.href = paths.signIn);
		}

		const ignoreShowError = originalRequest.url.includes("public");

		if (!ignoreShowError && (error.response?.status === 404 || error.response?.status === 500 || error.response?.status === 400)) {
			showError(i18n.t("errors.somethingWentWrong"));
		} else if (typeof error.response === 'undefined') {
			showError(i18n.t("errors.networkError"));
		}

		const { parsedErrors, detail } = handleError(error);

		if (detail) showError(detail);
		return Promise.reject({
			...error,
			parsedErrors,
		});
	}
);

export default instance;
