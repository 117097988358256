import {colors, nodeCustomEvents} from "../graph";

export const OnHoverClickEvent = ({name, value, item}) => {
  try {
    const group = item.getContainer();
    const children = group.get('children');
    const node = children[0];
    const image = children[3];
    const isClicked = item.hasState(nodeCustomEvents.clicked);
    // hide show image
    image.cfg.visible = value;

    const shadowAttr = (node,name) => {
      if(name) {
        node.attr('stroke', colors.hoverPointStroke);
      }
      node.attr('shadowBlur', 10);
      node.attr('shadowColor', 'rgba(0, 0, 0, 0.15)');
      node.attr('shadowOffsetX', 3);
      node.attr('shadowOffsetY', 3);
    }
    const defaultAttr = (node,name) => {
      if(name) {
        node.attr('stroke', colors.pointStroke);
      }
      node.attr('shadowBlur', 0);
      node.attr('shadowColor', 'transparent');
      node.attr('shadowOffsetX', 0);
      node.attr('shadowOffsetY', 0);
    }

    // on click node
    if (name === nodeCustomEvents.clicked) {
      if (value) {
        shadowAttr(node,name)
      } else {
        defaultAttr(node,name)
      }
    }

    // Handle node hover
    if (name === nodeCustomEvents.hoverNode) {
      if (value) {
        shadowAttr(node)
      } else {
        if (!isClicked) {
          defaultAttr(node)
        }
      }
    }
    if (name === 'showAnchors') {
      const anchorPoints = item.getContainer().findAll(ele => ele.get('name') === 'anchor-point');
      anchorPoints.forEach(point => {
        if (value || point.get('links') > 0) point.show()
        else point.hide()
      })
    }
  } catch (error) {
    console.error("Error in OnHoverClickEvent:", error);
  }
}
