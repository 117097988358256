import { Popover as PopoverAnt } from "antd";
import {getDeviceSize} from "common/utils/responsive";
import './styles.scss'

const Hint = ({
  content,
  trigger,
  children,
  open,
  align,
  className,
  style,
}) => {
  const isMobile = getDeviceSize()

  return (
    <PopoverAnt
      placement="bottomLeft"
      content={content}
      trigger={!isMobile ? "click" : trigger}
      overlayClassName="hint-overlay"
      align={align || { targetOffset: [6, 15] }}
      open={open}
      mouseEnterDelay={0.7}
      className={className}
    >
      <div className="hint-content" style={style}>
        {children}
      </div>
    </PopoverAnt>
  );
};

export default Hint;
