// NODES
import {blockHeight} from "./graph";
import {sortBy} from "lodash/collection";
import compressText from "common/utils/compressText";
import {formatAssetAmountString} from "common/utils/formatAssetAmount";

export const account_data = [
	{
		"address": "664c9e75f8d7217f614820a3",
		"entity_name": "664c9e756a5661a37cf8c7a4",
		"entity_category": "category",
		"id": '0',
		"pos_x": 100.546009,
		"pos_y": 100.496492
	},
];

export const transfers = [
	{
		"id": "66616878bcca55b36f749e91",
		"occurred_at": "Thu Jun 01 2024 13:42:48 GMT+0600 (Киргизия)",
		"input_account_id": '0',
		"output_account_id": '1',
		"token_symbol": "blue",
		"amount": 1,
		"value_usd": "usd"
	},
	{
		"id": "66616878b13a33fae1c95e7e",
		"occurred_at": "Thu Jun 02 2024 13:42:48 GMT+0600 (Киргизия)",
		"input_account_id": '0',
		"output_account_id": '1',
		"token_symbol": "brown",
		"amount": 2,
		"value_usd": "usd"
	},
	{
		"id": "66616878cbf158744a163ab8",
		"occurred_at": "Thu Jun 03 2024 13:42:48 GMT+0600 (Киргизия)",
		"input_account_id": '0',
		"output_account_id": '1',
		"token_symbol": "brown",
		"amount": 3,
		"value_usd": "usd"
	},
	{
		"id": "666168786eefc674d6589187",
		"occurred_at": "Thu Jun 04 2024 13:42:48 GMT+0600 (Киргизия)",
		"input_account_id": '1',
		"output_account_id": '2',
		"token_symbol": "blue",
		"amount": 4,
		"value_usd": "usd"
	},
	{
		"id": "666168781d1901f2ed7f1222",
		"occurred_at": "Thu Jun 03 2024 13:42:48 GMT+0600 (Киргизия)",
		"input_account_id": '1',
		"output_account_id": '2',
		"token_symbol": "green",
		"amount": 10,
		"value_usd": "usd"
	},
	{
		"id": "666168788a5a9378a6ccf599",
		"occurred_at": "Thu Jun 11 2024 13:42:48 GMT+0600 (Киргизия)",
		"input_account_id": '1',
		"output_account_id": '3',
		"token_symbol": "brown",
		"amount": 11,
		"value_usd": "usd"
	},
	{
		"id": "666168781ecaf5311e275538",
		"occurred_at": "Thu Jun 11 2024 13:42:48 GMT+0600 (Киргизия)",
		"input_account_id": '2',
		"output_account_id": '3',
		"token_symbol": "brown",
		"amount": 12,
		"value_usd": "usd"
	}
];

export function getHeight(anchors) {
	if (anchors.length > blockHeight.moreThan5.count) {
		return blockHeight.moreThan5.height;
	}

	if (anchors.length > blockHeight.moreThan4.count) {
		return blockHeight.moreThan4.height;
	}

	if (anchors.length > blockHeight.moreThan3.count) {
		return blockHeight.moreThan3.height;
	}

	return 51;
}

function setPoints(accounts, points) {
	return accounts.map(item => {
		const node = {
			...item,
			title: compressText(item.address, 6, 6),
			name: 'node',
			layoutOrder: 0,
			style: {
				height: 51,
			},
		};

		if (points[item.id] && item?.sender_id !== item?.recipient_id) {
			return {
				...node,
				anchorPoints: points[item.id],
				style: {
					height: getHeight(points[item.id]),
				}
			}
		}

		return node;
	});
}

export function getNodes(transfers = [], wallets = []) {
	const mergedData = getPoints(transfers);
	const result = setPoints(wallets, mergedData);
	return result;
}

function sortTransferCopy(data) {
	return sortByOccurredAt(data).map((item, index) => {
			const newKey = {};
			newKey[item.direction === 1 ? 'sourceAnchor' : 'targetAnchor'] = index;
			return {...item, ...newKey};
		});
}

function getTransfers(data) {
	const transfersById = {};
	const result = [];

	function creatingTransferData(transfer, key, direction) {
		if (!transfersById[transfer[key]]) {
			transfersById[transfer[key]] = [{...transfer, direction}];
		} else {
			transfersById[transfer[key]].push({...transfer, direction});
		}
	}

	for (const transfer of data) {
		creatingTransferData(transfer, 'input_account_id', 1);
		creatingTransferData(transfer, 'output_account_id', 0);
	}

	for (let item in transfersById) {
		transfersById[item] = sortTransferCopy(transfersById[item]);
	}

	for (const trans of Object.values(transfersById)) {
		result.push(...trans);
	}

	return result;
}

function setIndexByDate(data) {
	const result = {};
	const sortedData = getTransfers(data)

	for (const item of sortedData) {
		if (!result[item.id]) {
			result[item.id] = item;
		} else {
			result[item.id] = {...result[item.id], ...item};
		}
	}

	return Object.values(result);
}

// hard data
export const getEdges = (data) => {
	if (!data.length) {
		return [];
	}

	return setIndexByDate(data)
		.map(item => {
			return {
				source: item.recipient_id,
				target: item.sender_id,
				type: 'hvh',
				id: item.id,
				text: formatAssetAmountString({amount: item.value_usd, asset: item.token_symbol}),
				sourceAnchor: item.sourceAnchor,
				targetAnchor: item.targetAnchor,
			}
		});
}

export const getGraphProps =(data) =>  ({
	data,
	config: {
		padding: [20, 50],
		defaultLevel: 3,
		defaultZoom: 0.8,
		modes: {default: ['zoom-canvas', 'drag-canvas', 'drag-node']},
	},
});

export function getPercentagesCopy(n, position = 0) {
	const edgeHeight = 8
	const range = n < 5 ? 4 : 2
	const dotRadius = 4.5
	const totalHeight = edgeHeight * 2 + (dotRadius * 2 * n) + range * (n-1)

	const calcPercentage = (height) =>
		Number((height * 100 / totalHeight / 100).toFixed(2))

	let currentDotHeight = edgeHeight + dotRadius
	const respArr = [calcPercentage(currentDotHeight)];

	for(let i = 1; i < n; i++) {
		currentDotHeight = currentDotHeight + range + dotRadius * 2
		respArr.push(calcPercentage(currentDotHeight))
	}

	return respArr
}

function sortByOccurredAt(data) {
	return sortBy(data, item => -new Date(item.occurred_at));
}

function getPoints(data) {
	const itemsById = {};
	const sortedByDate = sortByOccurredAt(data);

	function sortData(item, key, direction) {
		if (!itemsById[item[key]]) {
			itemsById[item[key] || Date.now()] = [{...item, direction}];
		} else {
			itemsById[item[key] || Date.now()].push({...item, direction});
		}
	}

	sortedByDate.forEach(item => {
		sortData(item, 'recipient_id', 1);
		sortData(item, 'sender_id', 0);
	});

	for (const [id, val] of Object.entries(itemsById)) {
		itemsById[id] = itemsById[id].map((item, index) => {
			return [item.direction, getPercentagesCopy(val.length)[index]]
		});
	}

	return itemsById;
}
