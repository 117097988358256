import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-chained-backend';
import HttpApi from 'i18next-http-backend';
import LocalStorageBackend from "i18next-localstorage-backend";
import LanguageDetector from "./languageDetector";
import {AVAILABLE_LANGUAGES, ELanguages, FALLBACK_LNG} from "./constants";

const version = process.env.REACT_APP_LANG_VERSION;

const NewHttpApi = new HttpApi(null, {
  queryStringParams: { v: version },
});

export const getFallbackLng = () => {
  const lng = process.env.REACT_APP_LANGUAGE;
  if (lng && AVAILABLE_LANGUAGES.includes(lng)) {
    return { [lng]: [lng] };
  }
  return FALLBACK_LNG;
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    fallbackLng: getFallbackLng(),
    load: "currentOnly",
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
    detection: {
      order: ['customDetector', 'navigator', 'querystring', 'cookie', 'localStorage', 'htmlTag']
    },
    backend: {
      backends: [
        LocalStorageBackend,
        NewHttpApi
      ],
      backendOptions: [
        {
          versions: {
            [ELanguages.enUS]: `v${version}`,
            [ELanguages.ruRU]: `v${version}`
          },
        }
      ]
    }
  });

export default i18n;
