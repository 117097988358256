export const EApiError = {
	DETAIL_ERROR: "detail",
};

export const detailError = (e) =>
	e.response && e.response.data[EApiError.DETAIL_ERROR];

export const handleError = (e) => {
	let detail;
	let parsedErrors;

	if ((e?.response?.status === 400 || e?.response?.status === 401 || e?.response?.status === 403)
		&& e?.response?.data) {
		detail = detailError(e);
		parsedErrors = e.response.data;
	}

	return { detail, parsedErrors };
};
