import {debounce} from "lodash";

export const graphOnDragCanvas = ({graph, canvasPosition, updateCanvasPosition, updateObjectsPosition,updateNodePosition, objects}) => {
	/**
	 * save graph position after drag and drop on the Canvas
	 * */
	const coordinates = {
		x: canvasPosition?.x,
		y: canvasPosition?.y,
	};

	graph?.on('node:dragend', (event) => {
		updateNodePosition(event?.item.getModel());
	});

	graph.on('canvas:dragstart', debounce(event => {
		coordinates.x =  event.canvasX - coordinates.x;
		coordinates.y = event.canvasY - coordinates.y;
	}, 100));

	graph?.on('canvas:drag', debounce((event) => {
		const x = event.canvasX - coordinates.x;
		const y = event.canvasY - coordinates.y;

		coordinates.x = x;
		coordinates.y = y;

		updateCanvasPosition({x, y});
	}, 100));
}
