import React, {useEffect} from "react";
import {useProjectStore} from "store/projects";
import Transfers from "./transfers";
import Overview from "./overview";
import "./details-tab.styles.scss";

export const DetailsTab = ({item, graph}) => {
  const { getWalletOverview, drawerData, getTransferOverview, drawerLoader} = useProjectStore(s => s);

  useEffect(() => {
    const model = item?.getModel();
    const type = model?.type;
    const id = model?.id;

    if (type === 'hvh') {
      getTransferOverview({id:id})
    } else {
      getWalletOverview({id:id})
    }
  }, [item, getTransferOverview, getWalletOverview]);

  if(drawerData?.tx_hash) {
    return <Transfers drawerData={drawerData} fetching={drawerLoader}/>
  }
  return <Overview graph={graph} />
};
