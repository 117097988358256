// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.default-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 25px !important;
  height: 25px;
}

.custom-spinner {
  position: absolute;
  top: 5px;
  right: 6px;
}
.custom-spinner svg {
  position: unset;
}`, "",{"version":3,"sources":["webpack://./src/common/ui/spinner/styles.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,QAAA;EACA,SAAA;EACA,sBAAA;EACA,YAAA;AACF;;AAEA;EACE,kBAAA;EACA,QAAA;EACA,UAAA;AACF;AAAE;EACE,eAAA;AAEJ","sourcesContent":[".default-spinner {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  width: 25px !important;\n  height: 25px;\n}\n\n.custom-spinner {\n  position: absolute;\n  top: 5px;\n  right: 6px;\n  svg {\n    position: unset;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
