import React, {useState, useRef, useEffect, useCallback} from "react";
import {useParams} from "react-router-dom";
import {useProjectStore} from "store/projects";
import EditIcon from "common/icons/edit";
import {getDeviceSize} from "common/utils/responsive";
import Spinner, {ESpinnerType} from "common/ui/spinner";
import {useGetIcons} from "./getIcons";
import './styles.scss'

const EditableInput = () => {
  const { singleProject } = useProjectStore(state => state);
  let name = singleProject.name;
  const inputRef = useRef(null);
  const [inputVisible, setInputVisible] = useState(false);
  const { projectId } = useParams();
  const { isMobile} = getDeviceSize()
  const { editProject, resetStateByKey } = useProjectStore((state) => state);
  const [text, setText] = useState(singleProject.name);
  const iconCrypto = useGetIcons(singleProject?.network_code);

  const onClose = () => {
    setInputVisible(false);
  }

  const handleClick = async () => {
    if(text !== name) {
      await editProject({ id: projectId, name: text.trim()?.length > 1 ? text : singleProject.name }, onClose);
    }
    name = text;
    onClose();
  }

  const handleEditClick = () => {
    setInputVisible(true);
    setText(singleProject.name);
    setTimeout(() => inputRef.current?.focus(), 0);
  };

  const onClickOutside = useCallback((e) => {
    if (inputRef.current && !inputRef.current.contains(e.target)) {
      handleClick();
    }
  }, [text]);

  useEffect(() => {
    if (inputVisible) {
      document.addEventListener("pointerdown", onClickOutside);
    }
    return () => {
      document.removeEventListener("pointerdown", onClickOutside);
    };
  },[onClickOutside, inputVisible]);

  useEffect(() => {
    return () => {
      resetStateByKey([{key: 'singleProject', value: {}}]);
    }
  }, []);

  const onChange = useCallback(({target: {value}}) => {
    setText(value);
  }, [setText]);

  if (!singleProject.name) {
    return <div className="editable-input-wrapper">
      <div><Spinner iconType={ESpinnerType.loading}/></div>
    </div>
  }

  return (
    <div className="editable-input-wrapper">
      {!inputVisible && iconCrypto}
      <div className='edit-content' onClick={!isMobile ? handleEditClick : undefined}>
        {inputVisible ? <input ref={inputRef} value={text} onChange={onChange} /> : <span>{singleProject?.name}</span>}
        {!isMobile && !inputVisible && <EditIcon/>}
      </div>
    </div>
  )
}
export default EditableInput;
