// import G6 from "@antv/g6";
// import ReactDOM from "react-dom";
// import {getDeviceSize} from "common/utils/responsive";
//
// const rectType = 'rect-node';
// const edgeType = 'cubic-horizontal';
//
// const colors = {
// 	text: '#000',
// 	description: '#626368',
// 	edgeColor: '#BABFD5',
// 	pointStroke: '#D7D8E0',
// 	hoverPointStroke: '#D64686',
// 	white: '#fff',
// 	background: '#FAFAFC',
// };
// const fontFamily = 'Arial';
//
// export const nodeCustomEvents = {
// 	clicked: 'clicked',
// 	hoverNode: 'hoverNode',
// 	graphActive: 'graphActive',
// }
//
// export const blockHeight = {
// 	moreThan5: {
// 		count: 5,
// 		height: 80,
// 	},
// 	moreThan4: {
// 		count: 4,
// 		height: 69,
// 	},
// 	moreThan3: {
// 		count: 3,
// 		height: 64,
// 	},
// };
//
// const Util = G6.Util;
// const abs = Math.abs;
// const DRAG_OFFSET = 10;
// const body = document.body;
// const ALLOW_EVENTS = [16, 17, 18];
//
// const fonts = {
// 	size: {
// 		14: 14,
// 		12: 12,
// 		10: 10,
// 	}
// }
// const {isMobile} = getDeviceSize()
// export const initGraph = (graph, data, ref, hasStorageData) => {
// 	if (!data) {
// 		return;
// 	}
// 	const width = document.body.clientWidth
// 	const height = document.body.clientHeight - 50;
// 	const defaultEvents = ['drag-canvas'];
// 	// const defaultEvents = [];
// 	const isFireFox = navigator.userAgent.toLocaleLowerCase().includes('firefox');
// 	if (!isFireFox) {
// 		defaultEvents.push('scroll-canvas');
// 	} else {
// 		defaultEvents.push({
// 			type: 'zoom-canvas',
// 			sensitivity: 1,
// 		});
// 	}
//   if (!isMobile) {
//     defaultEvents.push('drag-node');
//   }
//
// 	const container = ReactDOM.findDOMNode(ref.current);
//
// 	graph = new G6.Graph({
// 		container,
// 		width,
// 		height,
// 		fitCenter: true,
// 		modes: {
// 			default: defaultEvents,
// 			availableDrag: ['drag-canvas', 'drag-node'],
// 			noneAvailable: ['drag-canvas'],
// 		},
// 		layout: {
// 			// type: hasStorageData ? '' : 'dagre',
// 			rankdir: 'LR',
// 			nodeSep: 5,
// 			nodeSize: 51,
// 			nodesepFunc: (item) => {
// 				if (item.style.height === 64) {
// 					return 8;
// 				}
// 				if (item.style.height === 69) {
// 					return 10;
// 				}
//
// 				if (item.style.height === 80) {
// 					return 16;
// 				}
// 				return 1;
// 			},
// 			ranksepFunc: (item) => {
// 				if (item.id === 'edge2') {
// 					return 100;
// 				}
// 				return 70;
// 			},
// 		},
// 		// defaultNode: {
// 		// 	type: rectType,
// 		// 	style: {
// 		// 		width: 136,
// 		// 		fill: colors.white,
// 		// 		stroke: colors.pointStroke,
// 		// 		lineWidth: 1,
// 		// 		radius: 2,
// 		// 	},
// 		// },
// 		// defaultEdge: {
// 		// 	type: edgeType,
// 		// 	style: {
// 		// 		stroke: colors.edgeColor,
// 		// 		lineWidth: 1,
// 		// 		startArrow: {
// 		// 			path: G6.Arrow.triangle(0, 0, 12),
// 		// 			d: 12,
// 		// 			fill: colors.edgeColor,
// 		// 		},
// 		// 		endArrow: {
// 		// 			path: G6.Arrow.triangle(5, 5, 5),
// 		// 			d: 5,
// 		// 			fill: colors.edgeColor,
// 		// 		},
// 		// 	},
// 		// },
// 	});
//
// 	graph.data(data);
// 	graph.render();
//
// 	graph.on('node:mouseenter', (e) => graph.setItemState(e.item, null, true));
// 	graph.on('node:mouseleave', (e) => graph.setItemState(e.item, null, false));
//
// 	window.onresize = () => {
// 		if (!graph || graph.get('destroyed')) return;
// 		if (!container || !container.scrollWidth || !container.scrollHeight) return;
// 		graph.changeSize(container.scrollWidth, container.scrollHeight);
// 	};
// 	return graph;
// };
//
// export const registerFn = () => {
// 	G6.registerBehavior('zoom-canvas-exclude-lockedNode', {
// 		getDefaultCfg() {
// 			return {
// 				sensitivity: 2,
// 				minZoom: 0.1,
// 				maxZoom: 10,
// 			};
// 		},
// 		getEvents() {
// 			return {
// 				wheel: 'onWheel',
// 			};
// 		},
// 		onWheel(e) {
// 			alert('wheel');
// 			// e.preventDefault();
// 			// if (!this.shouldUpdate(e, this)) {
// 			// 	return;
// 			// }
// 			// const graph = this.graph;
// 			// const canvas = graph.get('canvas');
// 			// const point = canvas.getPointByClient(e.clientX, e.clientY);
// 			// const sensitivity = this.get('sensitivity');
// 			// let ratio = graph.getZoom();
// 			// // To be Compatible with IE, Firefox, and Chrome
// 			// if (e.wheelDelta < 0) {
// 			// 	ratio = 1 - DELTA * sensitivity;
// 			// } else {
// 			// 	ratio = 1 + DELTA * sensitivity;
// 			// }
// 			// const zoom = ratio * graph.getZoom();
// 			// if (zoom > this.get('maxZoom') || zoom < this.get('minZoom')) {
// 			// 	return;
// 			// }
// 			// graph.zoom(ratio, { x: point.x, y: point.y });
// 			// const lockedNodes = this.graph.findAll('node', (node) => !node.hasLocked());
// 			// lockedNodes.forEach((node) => {
// 			// 	let matrix = Util.clone(node.get('group').getMatrix());
// 			// 	const center = node.getModel();
// 			// 	matrix = Util.transform(matrix, [
// 			// 		['t', -center.x, -center.y],
// 			// 		['s', 1 / ratio, 1 / ratio],
// 			// 		['t', center.x, center.y],
// 			// 	]);
// 			// 	node.get('group').setMatrix(matrix);
// 			// });
// 			// graph.emit('wheelzoom', e);
// 		},
// 	});
//
// 	// G6.registerEdge(
// 	// 	'hvh',
// 	// 	{
// 	// 		setState(name, value, item) {
// 	// 			console.log(name, value, item);
// 	// 			const group = item.getContainer();
// 	// 			const shape = group.get('children')[2];
// 	// 			console.log({shape});
// 	// 			if (name === 'active') {
// 	// 				if (value) {
// 	// 					shape.attr('stroke', '#909');
// 	// 				} else {
// 	// 					shape.attr('stroke', '#991');
// 	// 				}
// 	// 			}
// 	// 		},
// 	// 		afterDraw(cfg, group) {
// 	// 			const shape = group.get('children')[0];
// 	// 			const midPoint = shape.getPoint(0.5);
// 	// 			const rectWidth = cfg.text.length * 10;
// 	//
// 	// 			group.addShape('rect', {
// 	// 				attrs: {
// 	// 					width: rectWidth,
// 	// 					height: 22,
// 	// 					fill: colors.background,
// 	// 					x: midPoint.x - (rectWidth / 2),
// 	// 					y: midPoint.y - 12,
// 	// 					zIndex: 10,
// 	// 				},
// 	// 			});
// 	// 			group.addShape('text', {
// 	// 				attrs: {
// 	// 					text: cfg?.text,
// 	// 					x: midPoint.x - 10,
// 	// 					y: midPoint.y,
// 	// 					fontSize: 12,
// 	// 					textBaseline: 'middle',
// 	// 					fill: '#000',
// 	// 					zIndex: 10,
// 	// 					fontFamily,
// 	// 				},
// 	// 				name: 'text',
// 	// 			});
// 	// 		},
// 	// 		update: undefined,
// 	// 	},
// 	// 	edgeType,
// 	// );
//
// 	G6.registerNode(rectType, {
// 		// draw anchor-point circles according to the anchorPoints in afterDraw
// 		afterDraw(cfg, group) {
// 			const bbox = group.getBBox();
// 			const anchorPoints = this.getAnchorPoints(cfg)
// 			const textColor = cfg?.textColor ? cfg.textColor :colors.text;
//
// 			group.addShape('text', {
// 				attrs: {
// 					text: cfg?.title,
// 					y: cfg?.description ? -5 : 0,
// 					fontSize: 12,
// 					textAlign: 'center',
// 					textBaseline: 'middle',
// 					fill: textColor,
// 					zIndex: 0,
// 					fontFamily,
// 				},
// 				name: 'title',
// 				draggable: true,
// 			});
// 			group.addShape('text', {
// 				attrs: {
// 					text: cfg?.description,
// 					y: 10,
// 					fontSize: 12,
// 					textAlign: 'center',
// 					textBaseline: 'middle',
// 					fill: textColor,
// 					zIndex: 0,
// 					fontFamily,
// 				},
// 				name: 'description',
// 				draggable: true,
// 			});
// 			group.addShape('image', {
// 				attrs: {
// 					x: bbox.x + bbox.width - 23,
// 					y: bbox.y + 3,
// 					width: 18,
// 					height: 18,
// 					img: '/basket.svg',
// 					cursor: 'pointer',
// 				},
// 				name: 'delete-icon',
// 				visible: isMobile && true,
// 			});
//
// 			anchorPoints.forEach((anchorPos, i) => {
// 				const fill = cfg?.anchorPointFill?.[i];
//
// 				group.addShape('circle', {
// 					attrs: {
// 						r: 4,
// 						x: bbox.x + bbox.width * anchorPos[0],
// 						y: bbox.y + bbox.height * anchorPos[1],
// 						fill: fill ? fill : colors.white,
// 						stroke: colors.pointStroke,
// 						lineWidth: 1,
// 					},
// 					name: 'anchor-point',
// 					anchorPointIdx: i,
// 					links: i,
// 					visible: true,
// 				});
// 			});
// 		},
// 		getAnchorPoints(cfg) {
// 			return cfg.anchorPoints || [];
// 		},
// 		// response the state changes and show/hide the link-point circles
// 		setState(name, value, item) {
// 			console.log('setState', name, value, item);
// 			const group = item.getContainer();
// 			const children = group.get('children');
// 			const node = children[0];
// 			const image = children[3];
//
// 			// hide show image
// 			image.cfg.visible = value;
//
// 			// on click node
// 			// if (name === nodeCustomEvents.clicked) {
// 			// 	changeNodeOnClick(value, node);
// 			// }
// 			//
// 			// onNodeHover(value, node);
//
// 			if (name === 'showAnchors') {
// 				const anchorPoints = item.getContainer().findAll(ele => ele.get('name') === 'anchor-point');
// 				anchorPoints.forEach(point => {
// 					if (value || point.get('links') > 0) point.show()
// 					else point.hide()
// 				})
// 			}
// 		}
// 	}, 'rect');
// };
//
// const changeNodeOnClick = (value, node) => {
// 	if (value) {
// 		node.attr('stroke', colors.hoverPointStroke);
// 	} else {
// 		node.attr('stroke', colors.pointStroke);
// 	}
// }
//
// const onNodeHover = (value, node) => {
// 	if (value) {
// 		node.attr('shadowBlur', 10);
// 		node.attr('shadowColor', 'rgba(0, 0, 0, 0.15)');
// 		node.attr('shadowOffsetX', 3);
// 		node.attr('shadowOffsetY', 3);
// 	} else {
// 		node.attr('shadowBlur', 0);
// 		node.attr('shadowColor', 'transparent');
// 		node.attr('shadowOffsetX', 0);
// 		node.attr('shadowOffsetY', 0);
// 	}
// }
//
// export const onNodeClickSetActive = (graph, event) => {
// 	const clickedNodes = graph.findAllByState('node', nodeCustomEvents.clicked);
// 	for (let nodeItem of clickedNodes) {
// 		graph.setItemState(nodeItem, nodeCustomEvents.clicked, false);
// 	}
// 	graph.setItemState(event.item, nodeCustomEvents.clicked, true);
// }
