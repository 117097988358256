import React from "react"
import CopyContent from "common/ui/copyContent";
import AssetAmount from "common/ui/assetAmount";
import formatDate, {EFormats} from "common/utils/formatDate";
import compressText from "common/utils/compressText";
import {smartTrim} from "components/creation/utils";
import RedBasketIcon from "common/icons/redBusketIcon";
import {modalTypes, useModalStore} from "store/modal";
import Spinner from "common/ui/spinner";
import {EParamsType} from "common/ui/modals/deleteModal/deleteModal";
import {useTranslation} from "react-i18next";
import '../details-tab.styles.scss'

const Transfers = ({drawerData, fetching}) => {
  const {t} = useTranslation();
  const { openModal } = useModalStore((state) => state);
  const handleDelete = () => {
    openModal(modalTypes.deleteProject, {network:drawerData.id,editName:"transfer",type:EParamsType.tx_hash})
  }

  if(fetching) {
    return (
      <div className='drawer__transfer'>
        <Spinner/>
      </div>
    )
  }
	return (
    <div className="drawer__transfer">
      <div className="drawer__header">
        <h4>{t("common.transfer")}</h4>
        <p>
          <CopyContent children={compressText(drawerData?.tx_hash,48)} copyText={drawerData?.tx_hash}/>
        </p>
      </div>
      <div className="drawer__content">
        <div className="drawer__table_row">
          <div className="drawer__table_row_wrapper">
            <span>{t("common.txHash")}:</span>
            <span>{drawerData?.tx_hash ?
              <CopyContent children={smartTrim(drawerData?.tx_hash, 12)} copyText={drawerData?.tx_hash}/> : "―"}</span>
          </div>
          <div className="drawer__table_row_wrapper">
            <span>{t("common.occurredAt")}:</span>
            <span>{drawerData?.occurred_at ? formatDate(drawerData?.occurred_at, EFormats.LT) : "―"}</span>
          </div>
          <div className="drawer__table_row_wrapper">
            <span>{t("common.amountCrypto")}:</span>
            <span>{drawerData?.amount ?
              <AssetAmount
                amount={drawerData.amount}
                asset={drawerData?.token_symbol}
                disableSign={true}/> : "―"}</span>
          </div>
          <div className="drawer__table_row_wrapper">
            <span>{t("common.amountUsd")}:</span>
            <span>{drawerData?.value_usd ?
              <AssetAmount
                amount={drawerData.value_usd}
                asset="USD"
                disableSign={true}/> : "―"}</span>
          </div>
        </div>
        <div className="drawer__table_row">
          <div className="drawer__table_row_wrapper">
            <span>{t("common.senderAddress")}:</span>
            <span>
              <CopyContent children={smartTrim(drawerData?.sender_address, 8)}
                 copyText={drawerData?.sender_address}/>
            </span>
          </div>
          <div className="drawer__table_row_wrapper">
            <span>{t("common.senderEntity")}:</span>
            <span>{
              drawerData?.sender_entity_name ?
                <CopyContent children={smartTrim(drawerData?.sender_entity_name, 8)}
                  copyText={drawerData?.sender_entity_name}/> : "―"}
            </span>
          </div>
          <div className="drawer__table_row_wrapper">
            <span>{t("common.senderCategory")}:</span>
            <span>{drawerData?.sender_entity_category ? drawerData?.sender_entity_category : "―"}</span>
          </div>
        </div>
        <div className="drawer__table_row">
          <div className="drawer__table_row_wrapper">
            <span>{t("common.recipientAddress")}:</span>
            <span>
               <CopyContent children={smartTrim(drawerData?.recipient_address, 8)}
                  copyText={drawerData?.recipient_address}/>
            </span>
          </div>
          <div className="drawer__table_row_wrapper">
            <span>{t("common.recipientEntity")}:</span>
            <span>{drawerData?.recipient_entity_name ?
              <CopyContent children={smartTrim(drawerData?.recipient_entity_name, 8)}
                           copyText={drawerData?.recipient_entity_name}/> : "―"}</span>
          </div>
          <div className="drawer__table_row_wrapper">
            <span>{t("common.recipientCategory")}:</span>
            <span>{drawerData?.recipient_entity_category ? drawerData?.recipient_entity_category : "―"}</span>
          </div>
        </div>
        <div className='delete-transfer'>
          <div className="delete-content" onClick={handleDelete}>
            <RedBasketIcon/>
            <span>{t("initial.deleteTransfer")}</span>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Transfers;
