import React, {useMemo} from "react";
import Tron from "common/icons/cripto-icons/tron";
import Ethereum from "common/icons/cripto-icons/ethereum";
import BnbChain from "common/icons/cripto-icons/bnbChain";

export function useGetIcons(name) {
	return useMemo(() => {
		switch (name) {
			case "ETH":
				return <Ethereum/>;
			case "TRX":
				return <Tron/>
			default:
				return <BnbChain/>;
		}
	},[name])
}
