import React from "react";
import "./details-tab.styles.scss";
import Copy from "../../common/icons/copy";
import {DetailTable} from "./detail-table/detail-table";
import {smartTrim} from "../creation/utils";

// export const DetailsTab = () => {
// 	return <div className="drawer">
// 		<div className="drawer__header">
// 			<h4>Unknown</h4>
// 			<p>0xEf3ff6667a8851C99f8BA2681D9D92933be88805а <Copy /> </p>
// 		</div>
//
// 		<div className="drawer__content">
// 			<div className="drawer__table_row">
// 				<div className="drawer__table_row_wrapper">
// 					<span>Category:</span>
// 					<span>Exchange</span>
// 				</div>
// 				<div className="drawer__table_row_wrapper">
// 					<span>Balance:</span>
// 					<span>420 USD</span>
// 				</div>
// 			</div>
// 			<div className="drawer__table_row">
// 				<div className="drawer__table_row_wrapper">
// 					<span>Inflow:</span>
// 					<b>5,000 USD</b>
// 				</div>
// 				<div className="drawer__table_row_wrapper">
// 					<span>Outflow:</span>
// 					<b>4,000 USD</b>
// 				</div>
// 			</div>
// 			<div className="drawer__table_row">
// 				<div className="drawer__table_row_wrapper">
// 					<span>number of addresses:</span>
// 					<span>800 addresses</span>
// 				</div>
// 				<div className="drawer__table_row_wrapper">
// 					<span>number of tX:</span>
// 					<span>1,800 transactions</span>
// 				</div>
// 			</div>
// 			<div className="drawer__table_row">
// 				<div className="drawer__table_row_wrapper">
// 					<span>First tX:</span>
// 					<span>10.07.2022 10:30 pm</span>
// 				</div>
// 				<div className="drawer__table_row_wrapper">
// 					<span>Last tX:</span>
// 					<span>20.07.2023 12:30 pm</span>
// 				</div>
// 			</div>
// 			<DetailTable/>
// 		</div>
// 	</div>;
// }
// import React from "react";
// import "./details-tab.styles.scss";
// import Copy from "../../common/icons/copy";
// import { DetailTable } from "./detail-table/detail-table";
// import { smartTrim } from "../Graph/utils";
//
export const DetailsTab = () => {
  let longText = "0xEf3ff6667a8851C99f8BA2681D9D92933be88805а";
  const isMobile = window.innerWidth <= 768;
  const smallText = smartTrim(longText, 12);

  return (
    <div className="drawer">
      <div className="drawer__header">
        <h4>Unknown</h4>
        <p>
          0xEf3ff6667a8851C99f8BA2681D9D92933be88805а <Copy />{" "}
        </p>
      </div>

      <div className="drawer__content">
        <div className="drawer__table_row">
          <div className="drawer__table_row_wrapper">
            <span>Address:</span>
            {isMobile ? (
              <span className="address-index">
                {smallText} <Copy />
              </span>
            ) : (
              <span className="address-index">
                0xEf3ff6667a8851C99f8BA2681D9D92933be88805a <Copy />
              </span>
            )}
          </div>
          <div className="drawer__table_row_wrapper">
            <span>Category:</span>
            <span>Exchange</span>
          </div>
          <div className="drawer__table_row_wrapper">
            <span>Balance:</span>
            <span>420 USD</span>
          </div>
        </div>
        <div className="drawer__table_row">
          <div className="drawer__table_row_wrapper">
            <span>Inflow:</span>
            <b>5,000 USD</b>
          </div>
          <div className="drawer__table_row_wrapper">
            <span>Outflow:</span>
            <b>4,000 USD</b>
          </div>
        </div>
        <div className="drawer__table_row">
          <div className="drawer__table_row_wrapper">
            <span>number of addresses:</span>
            <span>800 addresses</span>
          </div>
          <div className="drawer__table_row_wrapper">
            <span>number of tX:</span>
            <span>1,800 transactions</span>
          </div>
        </div>
        <div className="drawer__table_row">
          <div className="drawer__table_row_wrapper">
            <span>First tX:</span>
            <span>10.07.2022, <em>10:30 pm</em></span>
          </div>
          <div className="drawer__table_row_wrapper">
            <span>Last tX:</span>
            <span>20.07.2023, <em>12:30 pm</em></span>
          </div>
        </div>
        <DetailTable />
      </div>
    </div>
  );
};
