import {useRef} from "react";
import { useTranslation } from "react-i18next";
import { Dropdown } from "antd";

import {isFullLangStyle, LANGUAGES} from "i18n/constants";
import {languageOption} from "common/utils/language";
import ArrowIcon from "../../icons/arrowIcon";
import './styles.scss'
import i18n from "../../../i18n";

export const onChangeLanguageHandler = (lng) => {
  i18n.changeLanguage(lng);
};

const SelectLanguage= () => {
  const { i18n } = useTranslation();
  const ref = useRef(null);
  const activeLang = languageOption(i18n.language);

  return (
    <div className="select-language" ref={ref}>
      <Dropdown
        menu={{
          items: Object.keys(LANGUAGES).map(lng => ({
              key: lng,
              onClick: () => onChangeLanguageHandler(lng),
            label: LANGUAGES[lng].nativeName
        }))
        }}
        trigger={["hover"]}
        className="select-language__dropdown__container"
        overlayClassName="select-language__dropdown"
      >
        <div>{isFullLangStyle && <activeLang.icon/>} {activeLang.nativeName} <ArrowIcon className="arrow"/></div>
      </Dropdown>
    </div>
  );
};

export default SelectLanguage;
