import {Link, useLocation} from "react-router-dom";
import React, {useMemo} from "react";
import {useTranslation} from "react-i18next";

import KytLogo from "common/icons/kytLogo";
import BackButton from "common/icons/backButton";
import {getDeviceSize} from "common/utils/responsive";
import {paths} from "router/paths";

const HeaderLeft = () => {
  const { isMobile } = getDeviceSize();
  const {t} = useTranslation();
  const {pathname} = useLocation();

  const { backButton, path } = useMemo(() => {
    switch (pathname) {
      case paths.main:
        return { backButton: <KytLogo />, path: process.env.REACT_APP_BASE_URL };
      default:
        return { backButton: <><BackButton /> {!isMobile && t("initial.projects")}</>, path: paths.main };
    }
  }, [pathname, t,isMobile])

  return <Link className="logo" to={path}>{backButton}</Link>
}
export default HeaderLeft;