import React from "react";
import cn from "classnames";
import {COLUMN_CLASSNAME, EColumns} from "../../../../../../constants";
import formatDate, {EFormats} from "common/utils/formatDate";
import ActionButton from "../actionButton";

const HashMobile = ({item,rowHover,isMobile}) => {
  if(isMobile) {
    return (
      <div className='item_wrapper top'>
        <div className={cn(COLUMN_CLASSNAME, `_${EColumns.hash}`)}>
          <p>{item.tx_hash}</p>
          <span>{item.occurred_at ? formatDate(item.occurred_at, EFormats.LT) : "―"}</span>
        </div>
        <ActionButton item={item} rowHover={rowHover}/>
      </div>
    )
  }
}
export default HashMobile;