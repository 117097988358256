import G6 from "@antv/g6";
import ReactDOM from "react-dom";
import {getDeviceSize} from "common/utils/responsive";
import {OnHoverClickEvent} from "./utils/onHoverClickEvent";

export const nodeCustomEvents = {
	clicked: 'clicked',
	hoverNode: 'hoverNode',
	edgeHover: 'edgeHover',
	edgeActive: 'edgeActive',
}

const rectType = 'rect-node';
const edgeType = 'cubic-horizontal';

export const colors = {
	text: '#000',
	description: '#626368',
	edgeColor: '#BABFD5',
	pointStroke: '#D7D8E0',
	hoverPointStroke: '#D64686',
	white: '#fff',
	background: '#FAFAFC',
};
const fontFamily = 'Arial';

export const blockHeight = {
	moreThan5: {
		count: 5,
		height: 80,
	},
	moreThan4: {
		count: 4,
		height: 69,
	},
	moreThan3: {
		count: 3,
		height: 64,
	},
};

const {isMobile} = getDeviceSize()
export const initGraph = ({graph, data, ref, hasStorageData, canvasPosition, updateCanvasPosition, updateNodePosition}) => {
	if (!data) return;

	const width = document.body.clientWidth
	const height = document.body.clientHeight - 50;
	const defaultEvents = ['drag-canvas'];
	const isFireFox = navigator.userAgent.toLocaleLowerCase().includes('firefox');

	if (!isFireFox) {
		defaultEvents.push('scroll-canvas');
	} else {
		defaultEvents.push({
			type: 'zoom-canvas',
			sensitivity: 1,
		});
	}
  if (!isMobile) {
    defaultEvents.push('drag-node');
  }

	const container = ReactDOM.findDOMNode(ref.current);

	graph = new G6.Graph({
		container,
		width,
		height,
		// fitCenter: true,
		modes: {
			default: defaultEvents,
			availableDrag: ['drag-canvas', 'drag-node'],
			noneAvailable: ['drag-canvas'],
		},
		// layout: {
		// 	// type:  'dagre',
		// 	// rankdir: 'RL',
		// 	nodeSep: 5,
		// 	nodeSize: 51,
		// 	nodesepFunc: (item) => {
		// 		if (item.style.height === 64) {
		// 			return 8;
		// 		}
		// 		if (item.style.height === 69) {
		// 			return 10;
		// 		}
		//
		// 		if (item.style.height === 80) {
		// 			return 16;
		// 		}
		// 		return 1;
		// 	},
		// 	ranksepFunc: (item) => {
		// 		if (item.id === 'edge2') {
		// 			return 100;
		// 		}
		// 		return 70;
		// 	},
		// },
		defaultNode: {
			type: rectType,
			style: {
				width: 136,
				fill: colors.white,
				stroke: colors.pointStroke,
				lineWidth: 1.5,
				radius: 2,
			},
		},
		defaultEdge: {
			type: edgeType,
			style: {
				stroke: colors.edgeColor,
				lineWidth: 1,
				endArrow: {
					path: G6.Arrow.triangle(5, 5, 5),
					d: 5,
					fill: colors.edgeColor,
				},
			},
		},
	});

	graph.read(data);
	/**
	 * set active (red border) and inactive for wallet block on click
	 * */
  graph.on('node:mouseenter', (e) => graph.setItemState(e.item, nodeCustomEvents.hoverNode, true));
  graph.on('node:mouseleave', (e) => {
    const group = e.item.getContainer();
    const deleteIcon = group.find((element) => element.get('name') === 'delete-icon');
    if (deleteIcon) {
      deleteIcon.hide();
    }
    graph.setItemState(e.item, nodeCustomEvents.hoverNode, false);
  });

	window.onresize = () => {
		if (!graph || graph.get('destroyed')) return;
		if (!container || !container.scrollWidth || !container.scrollHeight) return;
		graph.changeSize(container.scrollWidth, container.scrollHeight);
	};
	return graph;
};

export const registerFn = () => {
	G6.registerBehavior('zoom-canvas-exclude-lockedNode', {
		getDefaultCfg() {
			return {
				sensitivity: 2,
				minZoom: 0.1,
				maxZoom: 10,
			};
		},
		getEvents() {
			return {
				wheel: 'onWheel',
			};
		},
	});

	G6.registerEdge(
		'hvh',
		{
			setState(name, value, item) {
				const group = item.getContainer();
				const el = group.get('children')[0];
				if (name === nodeCustomEvents.edgeHover || name === nodeCustomEvents.edgeActive) {
					el.attr('stroke', value ? colors.hoverPointStroke : colors.edgeColor);
					el.attr("endArrow",value ?
            { path: G6.Arrow.triangle(5, 5, 5), d: 5, fill: colors.hoverPointStroke } :
            { path: G6.Arrow.triangle(5, 5, 5), d: 5, fill: colors.edgeColor });
				}
			},
			afterDraw(cfg, group) {
				const shape = group.get('children')[0];
				const midPoint = shape.getPoint(0.5);
				const rectWidth = cfg.text.length * 7;

				group.addShape('rect', {
					attrs: {
						width: rectWidth,
						height: 22,
						fill: colors.background,
						x: midPoint.x - (rectWidth / 2),
						y: midPoint.y - 12,
						zIndex: 10,
					},
				});
				group.addShape('text', {
					attrs: {
						text: cfg?.text,
						x: midPoint.x - (cfg?.text.length * 3.5),
						y: midPoint.y,
						fontSize: 12,
						textBaseline: 'middle',
						fill: '#000',
						zIndex: 10,
						fontFamily,
					},
					name: 'text',
				});
			},
			update: undefined,
		},
		edgeType,
	);

	G6.registerNode(rectType, {
		// draw anchor-point circles according to the anchorPoints in afterDraw
		afterDraw(cfg, group) {
			const bbox = group.getBBox();
			const textColor = cfg?.textColor ? cfg.textColor :colors.text;

			group.addShape('text', {
				attrs: {
					text: cfg?.title,
					y: cfg?.entity_category ? -5 : 0,
					fontSize: 12,
					textAlign: 'center',
					textBaseline: 'middle',
					fill: textColor,
					zIndex: 0,
					fontFamily,
				},
				name: 'title',
				draggable: true,
			});
			group.addShape('text', {
				attrs: {
					text: cfg?.entity_category,
					y: 10,
					fontSize: 12,
					textAlign: 'center',
					textBaseline: 'middle',
					fill: textColor,
					zIndex: 0,
					fontFamily,
				},
				name: 'description',
				draggable: true,
			});
			group.addShape('image', {
				attrs: {
					x: bbox.x + bbox.width - 23,
					y: bbox.y + 3,
					width: 18,
					height: 18,
					img: '/basket.svg',
					cursor: 'pointer',
				},
				name: 'delete-icon',
				visible: isMobile && true,
			});
		},
    getHorizontalAnchors(bbox, anchorPos) {
      return bbox.x + (bbox.width * anchorPos[0]) + (anchorPos[0] === 0 ? 0.7 : -0.7);
    },
		getAnchorPoints(cfg) {
			return cfg.anchorPoints || [];
		},
		afterUpdate(cfg, node) {
			const group = node.getContainer();
			const anchorPoints = this.getAnchorPoints(cfg);
			group.find(el => {
				if (el.get('name') === 'anchor-point') el.destroy();
				if (el.get('title') === 'title') el.destroy();
			});
			const bbox = group.getBBox();
			anchorPoints.forEach((anchorPos, i) => {
				const fill = cfg?.anchorPointFill?.[i];

				group.addShape('circle', {
					attrs: {
						r: 4,
						x: this.getHorizontalAnchors(bbox,anchorPos),
						y: Math.round(bbox.y) + Math.round(bbox.height) * anchorPos[1],
						fill: fill ? fill : colors.white,
						stroke: colors.pointStroke,
						lineWidth: 1.5,
					},
					name: 'anchor-point',
					anchorPointIdx: i,
					links: i, // cache the number of edges connected to this shape
					visible: true, // invisible by default, shows up when links > 1 or the node is in showAnchors state
				});
			});
		},
		// response the state changes and show/hide the link-point circles
		setState(name, value, item) {
      OnHoverClickEvent ({name,value,item})}
	}, 'rect');
};
