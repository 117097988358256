export const EStorageKeys = {
	TOKEN: "token",
	TIMER: "timer",
	GREETING: "greeting",
	I18NEXT_LNG: "i18nextLng",
	UPDATE_RULES: "update_rules:id",
};

export const getUpdateRulesKey = (id) => EStorageKeys.UPDATE_RULES.replace(":id", id);

export function setStorage(key, data) {
	localStorage.setItem(key, JSON.stringify(data));
}

export function getStorageData(key) {
	try {
		const item = localStorage.getItem(key);
		if (item) {
			return JSON.parse(item);
		}
	} catch (err) {
		console.error(err);
	}
}

export function removeStorageItem(key) {
	localStorage.removeItem(key);
}

export function setSessionStorage(key, data) {
	sessionStorage.setItem(key, JSON.stringify(data));
}

export function cleanStorage() {
	localStorage.clear();
}

export function getSessionStorageData(key) {
	try {
		const item = sessionStorage.getItem(key);
		if (item) {
			return JSON.parse(item);
		}
	} catch (err) {
		console.error(err);
	}
}

export function removeSessionStorageItem(key) {
	sessionStorage.removeItem(key);
}
