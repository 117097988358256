import React, { useState } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import { useAuthStore } from 'store/auth';
import {useNavigate} from "react-router-dom";
import {paths} from "../../router/paths";

const SignInPage = () => {
	const recaptchaRef = React.useRef();
	const [email, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const signIn = useAuthStore((state) => state.signIn);
  const navigate = useNavigate();

	const handleSubmit = async (e) => {
		e.preventDefault();
		const recaptcha = await recaptchaRef.current.executeAsync();
		signIn({email, password, recaptcha}, () => {
      navigate(paths.main);
    });
	};

	return (
		<form onSubmit={handleSubmit}>
			<ReCAPTCHA
				ref={recaptchaRef}
				size="invisible"
				sitekey={process.env.REACT_APP_GOOGLE_RE_CAPTCHA_CLIENT_SITE_KEY || ''}
			/>
			<div>
				<label htmlFor="email">Email:</label>
				<input
					type="text"
					id="email"
					value={email}
					onChange={(e) => setUsername(e.target.value)}
				/>
			</div>
			<div>
				<label htmlFor="password">Password:</label>
				<input
					type="password"
					id="password"
					value={password}
					onChange={(e) => setPassword(e.target.value)}
				/>
			</div>
			<button type="submit">Login</button>
		</form>
	);
};

export default SignInPage;
