export const colors = {
	main: "#748ADA",
	brown1: "#edd4c4",
	brown2: "#e1b79d",
	brown3: "#d59b76",
	brown4: "#c97e4f",
	brown5: "#b06536",
	brown6: "#894e2a",
	brown7: "#62381e",
	brown8: "#3b2212",
	brown9: "#21130a",

	grayMain: "#39393C",
	gray1: "#ffffff",
	gray2: "#f3f4f9",
	gray3: "#eeeff3",
	gray4: "#e8e9f0",
	gray5: "#d7d8e0",
	gray6: "#b9bbc2",
	gray7: "#8c8d94",
	gray8: "#626368",
	gray9: "#232325",
	grayLight: "#fafafc",

	blue2: "#99ACF2",
	blue3: "#475DAF",
	error2: "#E5424C",
	green1: "#F6FFED",
	green3: "#59bd83",
	red1: "#FFF1F0",
	red2: "#FFCCC7",
	red3: "#FFA39E",
	red4: "#ff7875",
	red5: "#ff4d4f",
	red6: "#e5424C",
	complementary: "#FF6D88",
	complementary2: "#9E83F8",
	darkBlue: "#00d5d7",
	lightBlue: "#00e7e6",
	lettuce: "#CDF964",
	pink: "#D64686",
};
