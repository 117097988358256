const codes = {
	zoomIn: 'zoomIn',
	zoomOut: 'zoomOut',
	fullScreen: 'fullScreen',
	fullScreenCenter: 'fullScreenCenter',
};

export function zoomCanvas({graph, canvas}) {
	let evCache = new Array();
	let prevDiff = -1;

	function changeZoom(zoomValue) {
		graph.zoomTo(zoomValue, graph.getViewPortCenterPoint(), true);
	}

	const zoomEvent = (code) => {
		try {
			const zoomCount = 0.3;
			let zoomValue = graph?.getZoom() || 0;

			switch (code) {
				case codes.zoomIn:
					zoomValue += zoomCount;
					changeZoom(zoomValue);
					break;
				case codes.zoomOut:
					zoomValue -= zoomCount;
					changeZoom(zoomValue);
					break;
			}
		} catch (error) {
			alert('ERROR', error);
		}
	};

	function pointerdown_handler(ev) {
		evCache.push(ev);
	}

	function pointermove_handler(ev) {
		try {
			for (let i = 0; i < evCache.length; i++) {
				if (ev.pointerId == evCache[i].pointerId) {
					evCache[i] = ev;
					break;
				}
			}

			// If two pointers are down, check for pinch gestures
			if (evCache.length == 2) {
				// Calculate the distance between the two pointers
				let curDiff = Math.sqrt(
					Math.pow(evCache[1].clientX - evCache[0].clientX, 2) +
					Math.pow(evCache[1].clientY - evCache[0].clientY, 2)
				);

				if (prevDiff > 0) {
					if (curDiff > prevDiff) {
						zoomEvent(codes.zoomIn);
					}
					if (curDiff < prevDiff) {
						zoomEvent(codes.zoomOut);
					}
				}
				prevDiff = curDiff;
			}
		} catch (er) {
			alert('err', er);
		}
	}

	function pointerup_handler(ev) {
		remove_event(ev);
		if (evCache.length < 2) prevDiff = -1;
	}

	function remove_event(ev) {
		// Remove this event from the target's cache
		for (let i = 0; i < evCache.length; i++) {
			if (evCache[i].pointerId == ev.pointerId) {
				evCache.splice(i, 1);
				break;
			}
		}
	}

	function initZoom() {
		canvas.onpointerdown = pointerdown_handler;
		canvas.onpointermove = pointermove_handler;

		canvas.onpointerup = pointerup_handler;
		canvas.onpointercancel = pointerup_handler;
		canvas.onpointerout = pointerup_handler;
		canvas.onpointerleave = pointerup_handler;
	}

	return {initZoom};
}
