// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.select-language {
  display: flex;
  align-items: center;
  justify-content: center;
}
.select-language__dropdown .ant-dropdown-menu-item {
  padding: 5px 12px;
}
.select-language__dropdown__container {
  transition: all 0.1s ease-in;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  text-decoration-line: underline;
  color: var(--accent-main);
}
.select-language__dropdown__container .flag {
  margin-right: 8px;
  width: 20px;
  height: 20px;
}
.select-language__dropdown__container .arrow {
  margin-left: 7px;
  fill: var(--accent-main);
  width: 10px;
  height: 10px;
  transform: rotate(-90deg);
}
.select-language__dropdown__container:hover {
  color: var(--blue-2);
}
.select-language__dropdown__container:hover .arrow {
  fill: var(--blue-2);
}
.select-language__dropdown__container:hover .flag {
  border-color: var(--blue-2);
}`, "",{"version":3,"sources":["webpack://./src/common/ui/selectLanguage/styles.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;AACF;AAEI;EACE,iBAAA;AAAN;AAEI;EACE,4BAAA;EACA,eAAA;EACA,aAAA;EACA,mBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,+BAAA;EACA,yBAAA;AAAN;AACM;EACE,iBAAA;EACA,WAAA;EACA,YAAA;AACR;AACM;EACE,gBAAA;EACA,wBAAA;EACA,WAAA;EACA,YAAA;EACA,yBAAA;AACR;AACM;EACE,oBAAA;AACR;AAAQ;EACE,mBAAA;AAEV;AAAQ;EACE,2BAAA;AAEV","sourcesContent":[".select-language {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  &__dropdown {\n    .ant-dropdown-menu-item {\n      padding: 5px 12px;\n    }\n    &__container {\n      transition: all .1s ease-in;\n      cursor: pointer;\n      display: flex;\n      align-items: center;\n      font-weight: 500;\n      font-size: 14px;\n      line-height: 22px;\n      text-decoration-line: underline;\n      color: var(--accent-main);\n      .flag {\n        margin-right: 8px;\n        width: 20px;\n        height: 20px;\n      }\n      .arrow {\n        margin-left: 7px;\n        fill: var(--accent-main);\n        width: 10px;\n        height: 10px;\n        transform: rotate(-90deg);\n      }\n      &:hover {\n        color: var(--blue-2);\n        .arrow {\n          fill: var(--blue-2);\n        }\n        .flag {\n          border-color: var(--blue-2);\n        }\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
