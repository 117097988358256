import {HeaderMenuDropdown} from "common/ui/headerMenuDropdown/headerMenuDropdown";
import React from "react";
import "../mainHeader.scss";
import KytLogo from "common/icons/kytLogo";
import RedPlus from "common/icons/redPlus";

export function InitialContent ({isMobile}) {

	return <div className="wrapper">
		<a className="logo"><KytLogo /></a>
		{isMobile && <div className="edit-wrapper">
			<span>Project</span>
		</div>}
		{isMobile ? <HeaderMenuDropdown width={215} icon={<RedPlus />} /> : <div className="menu">
			<span>john.doe@gmail.com</span>
		</div>}
	</div>
}
