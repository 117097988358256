import {colors, nodeCustomEvents} from "../graph";
import {setNodeBackground} from "./setNodeBackground";
import { mix_hexes} from "./fillColor";

export const OnHoverClickEvent = ({name, value, item}) => {
  try {
    const group = item.getContainer();
    const children = group.get('children');
    const node = children[0];
    const deleteImage = children[3];
    const isHover = item.hasState(nodeCustomEvents.hoverNode);
    // hide show deleteImage
    deleteImage.cfg.visible = value && isHover;
    const model = item.getModel();

    const shadowAttr = (node) => {
      node.attr('stroke', mix_hexes(setNodeBackground(model.entity_category).stroke, "#56594c"));
      node.attr('shadowBlur', 0);
      node.attr('shadowColor', 'transparent');
      node.attr('shadowOffsetX', 0);
      node.attr('shadowOffsetY', 0);
      node.attr('strokeOpacity', 1);
    }
    const defaultAttr = (node) => {
      node.attr('stroke',setNodeBackground(model.entity_category).stroke || colors.pointStroke);
    }

    if (name === nodeCustomEvents.clicked) {
      if (value) {
        shadowAttr(node)
      } else {
        defaultAttr(node)
      }
    }

    if (name === 'showAnchors') {
      const anchorPoints = item.getContainer().findAll(ele => ele.get('name') === 'anchor-point');
      anchorPoints.forEach(point => {
        if (value || point.get('links') > 0) point.show()
        else point.hide()
      })
    }
  } catch (error) {
    console.error("Error in OnHoverClickEvent:", error);
  }
}
