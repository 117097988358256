import  formatDate,{EFormats} from "common/utils/formatDate"

export const InitialTableItem = (projects) => projects && projects.map((item) => ({
  key: item.id,
  name: {
    name: item.name,
  },
  updates: {
    date:formatDate(item.updated_at,EFormats.D),
    time:formatDate(item.updated_at, EFormats.T)
  },
  blockchain: `${item.network_name} (${item.network_code})` || "―",
  // TODO assets in KYT
  asset: "―",
  accounts: item.account_count || "―",
}));