import {HeaderMenuDropdown} from "common/ui/headerMenuDropdown/headerMenuDropdown";
import React from "react";
import "../mainHeader.scss";
import KytLogo from "common/icons/kytLogo";
import RedPlus from "common/icons/redPlus";
import SelectLanguage from "../../../ui/selectLanguage";

export function InitialContent ({isMobile}) {

	return <div className="wrapper">
		<a className="logo"><KytLogo /></a>
		{isMobile && <div className="edit-wrapper">
			<span>Project</span>
		</div>}
    <div className='header-left'>
      <SelectLanguage
        placement="top"
        renderInWrapper={true}/>
      {isMobile ? <HeaderMenuDropdown width={215} icon={<RedPlus />} /> : <div className="menu">
        <span>john.doe@gmail.com</span>
      </div>}
    </div>
	</div>
}
