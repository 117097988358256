import React from "react";
import formatDate, {EFormats} from "common/utils/formatDate";
import compressText from "common/utils/compressText";
import AssetAmount from "common/ui/assetAmount";

export const walletTransferItem = (walletProjects) => walletProjects && walletProjects.map((item) => ({
  ...item,
  key: item?.ext_id,
  status: item.is_incoming === true ? "incoming" : "out",
  hash: {
    hash:compressText(item.tx_hash,12),
    date: item.occurred_at ? formatDate(item.occurred_at,EFormats.LT) : "―"
  },
  value: {
    amount: (item.amount ) ? <AssetAmount
      className='amount'
      amount={item.amount}
      asset={item.token_symbol}
      isIncoming={item.is_incoming}
      disableSign={false}/> : "―",
    currency:(item.value_usd ) ? <AssetAmount
      className='usd-value'
      amount={item.value_usd}
      asset={'USD'}
      valueUsd={true}
      disableSign={false}/> :  "―",
  },
  counterparty: {
    name: (item.counterparty_entity && item.counterparty_category) ?
      compressText(item.counterparty_entity,6,4) :
      compressText(item?.counterparty_address, 6,4) ,
    tag: (item.counterparty_category && item.counterparty_entity) ?
      item.counterparty_category : 'Unknown wallet',
    added: item.added,
  }
}));
