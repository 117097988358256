import * as React from "react";
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import "common/styles/common.scss";
import { paths } from "./paths";
import SignInPage from "../pages/SignIn";
import Protected from "./protected";
import {Initial} from "../pages/Initial/initial";
import Graph from "../pages/Graph";

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/">
      <Route element={<Protected />}>
        <Route index element={<Initial/>} />
      </Route>
      <Route path={paths.signIn} element={<SignInPage />} />
      <Route path={paths.projectDetails} element={<Graph />} />
      <Route path="*" element={<h1>Page not found</h1>} />
    </Route>
  )
);

