import {paths} from "../router/paths";
import RussianFlag from "../common/icons/russianFlag";
import BritishFlag from "../common/icons/britishFlag";
import {getDeviceSize} from "common/utils/responsive";

export const ELanguages = {
  enUS: "en-US",
  ruRU: "ru-RU",
};

const {isMobile} = getDeviceSize();
export const isSignIn = window.location.pathname.includes(paths.signIn);

export const isFullLangStyle = isMobile || isSignIn

export const AVAILABLE_LANGUAGES = [ELanguages.enUS, ELanguages.ruRU];

export const LANGUAGES = {
  [ELanguages.ruRU]: {
    nativeName: isFullLangStyle ? 'Русский' : 'RU',
    icon: RussianFlag
  },
  [ELanguages.enUS]: {
    nativeName: isFullLangStyle ? 'English' : 'EN',
    icon: BritishFlag
  },
};

export const FALLBACK_LNG = {
  [ELanguages.ruRU]: [ELanguages.ruRU],
  [ELanguages.enUS]: [ELanguages.enUS],
};
