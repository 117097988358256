import { useTranslation } from "react-i18next";
import AuthTemplate from "./components/AuthTemplate";
import SignInForm from "./components/SignInForm";


const SignInPage = () => {
  const { t } = useTranslation();

  return (
    <AuthTemplate title={t("auth.logIn")}>
      <SignInForm />
    </AuthTemplate>
  );
};

export default SignInPage;