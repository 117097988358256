// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.block {
  margin-top: 19%;
}
@media (max-width: 767px) {
  .block {
    margin-top: 50%;
  }
  .block button {
    width: 328px;
    height: 48px;
  }
}
.block__container {
  width: 282px;
  display: flex;
  flex-direction: column;
}
@media (max-width: 767px) {
  .block__container {
    width: fit-content;
  }
}

p {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  font-family: "Roboto";
  text-align: center;
  margin-bottom: 24px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Initial/empty-block/empty-block.scss","webpack://./src/common/styles/_mixins.scss"],"names":[],"mappings":"AAEA;EACE,eAAA;AADF;ACUE;EDVF;IAII,eAAA;EAAF;EAEE;IACE,YAAA;IACA,YAAA;EAAJ;AACF;AAEE;EACE,YAAA;EACA,aAAA;EACA,sBAAA;AAAJ;ACJE;EDCA;IAMI,kBAAA;EACJ;AACF;;AAGA;ECvBE,eDwBc;ECvBd,iBDuByB;ECtBzB,gBDsBoB;ECrBpB,qBAAA;EDsBA,kBAAA;EACA,mBAAA;AAGF","sourcesContent":["@import \"../../../common/styles/mixins\";\n\n.block {\n  margin-top: 19%;\n\n  @include mobile() {\n    margin-top: 50%;\n\n    button {\n      width: 328px;\n      height: 48px;\n    }\n  }\n  &__container {\n    width: 282px;\n    display: flex;\n    flex-direction: column;\n\n    @include mobile() {\n      width: fit-content;\n    }\n  }\n}\n\np {\n  @include font(16px, 400, 24px);\n  text-align: center;\n  margin-bottom: 24px;\n}\n\n","@mixin font($size: 14px, $weight: 400, $lineHeight: 18px) {\n  font-size: $size;\n  line-height: $lineHeight;\n  font-weight: $weight;\n  font-family: 'Roboto';\n}\n\n@mixin maxHeight() {\n  height: calc(100vh - 7px);\n}\n\n@mixin mobile {\n  @media (max-width: 767px) {\n    @content;\n  }\n}\n\n@mixin tablet {\n  @media (max-width: 1224px) {\n    @content;\n  }\n}\n\n@mixin laptop {\n  @media (max-width: 1440px) {\n    @content;\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
