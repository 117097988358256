import ReactDOM from "react-dom";

export function zoomOnMobile(el, setZoomValue, ref) {
	const container = ReactDOM.findDOMNode(ref.current);
	if (!!el) {
		let initialDistance = null;

		function getDistance(touches) {
			const dx = touches[0].clientX - touches[1].clientX;
			const dy = touches[0].clientY - touches[1].clientY;
			return Math.sqrt(dx * dx + dy * dy);
		}

		container.addEventListener("touchstart", (e) => {
			if (e.touches.length === 2) {
				initialDistance = getDistance(e.touches);
			}
		});

		container.addEventListener("touchmove", (e) => {
			if (e.touches.length === 2) {
				e.preventDefault();

				const newDistance = getDistance(e.touches);
				const zoomFactor = newDistance / initialDistance;
				let newZoom = el.getZoom() * zoomFactor;

				newZoom = Math.max(0.5, Math.min(2, newZoom));

				const centerX = (e.touches[0].clientX + e.touches[1].clientX) / 2;
				const centerY = (e.touches[0].clientY + e.touches[1].clientY) / 2;

				const point = el.getPointByClient(centerX, centerY);

				setZoomValue(el.getZoom());
				el.zoomTo(newZoom, { x: point.x, y: point.y });
				initialDistance = newDistance;
			}
		});
	}
}
