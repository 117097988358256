import React from "react";
import {Toolbar} from "../toolbar";
import CloseMediumIcon from "common/icons/closeMediumIcon";
import "./drawerComponent.scss";

export const DrawerComponent = ({children, open, onClose, graph}) => {
	return <>
		<Toolbar open={open} graph={graph} />
		<div className={`drawer-layout drawer-${open ? 'open' : 'close'}`}>
			<div className="drawer-layout__top">
				<CloseMediumIcon onClick={onClose}/>
			</div>
			{children}
		</div>
	</>
}
