import useQuery from "./useQuery";
import {useState,useEffect} from "react";
import isEmpty from "lodash/isEmpty";

export const EQuery =  {
  page: "page",
  showMode: "showMode",
  walletId:"walletId"
}

export const EShowMode =  {
  infinite: 'infinite',
  page: 'page',
}

const UseQueryState = () => {
  const query = useQuery();
  const [page, setPage] = useState(() => {
    const _page = query.get(EQuery.page);
    return Number(_page) || 1;
  });
  const [showMode, setShowMode] = useState(() => {
    const _showMode = query.get(EQuery.showMode);
    return _showMode || EShowMode.page;
  });
  const updateQuery = () => {
    if (isEmpty(query.toString())) {
      window.history.replaceState({}, "", window.location.pathname);
      return;
    }
    window.history.replaceState(null, "", `?${query.toString()}`);
  };

  useEffect(() => {
    if (page === 1) {
      query.delete(EQuery.page);
    } else {
      query.set(EQuery.page, String(page));
    }
    updateQuery();
  }, [page]);

  useEffect(() => {
    if (showMode === EShowMode.page) {
      query.delete(EQuery.showMode);
    } else {
      query.set(EQuery.showMode, EShowMode.infinite);
    }
    updateQuery();
  }, [showMode]);

  return {
    page,
    setPage,
    showMode,
    setShowMode,
  }
}
export default UseQueryState;
