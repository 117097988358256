import { notification } from "antd";
import i18next from "i18next";

import { colors } from "../config/constants";

export const showError = (text, description) => {
  notification.destroy();
  notification.error({ message: text ||i18next.t("errors.errorTextHandler") , description });
};

export const showSuccess = (text) => {
  notification.destroy();
	notification.success({ message: text || i18next.t("notification.successfully") });
};

export const showInfo = (text) => {
	notification.destroy();
	notification.info({ message: text });
};

export const clearNotification = () => {
	notification.destroy();
};

export const showSuccessGreen = (args) => {
  notification.destroy();
	notification.success({
		...args,
		style: { backgroundColor: colors.green1, border: `1px solid ${colors.green3}` },
	});
};

export const showErrorRed = (args) => {
  notification.destroy();
	notification.error({
		...args,
		style: { backgroundColor: colors.red1, border: `1px solid ${colors.red2}` },
	});
};

export const notificationNoPermission = () => showErrorRed({
	message: i18next.t("validation.permissionRequired"),
	description: i18next.t("validation.permissionRequiredDescription"),
});

export const notificationNoChecksAvailable = () => showError(
	i18next.t("notification.noChecksAvailable"),
	i18next.t("notification.noChecksAvailableDescription"),
);
