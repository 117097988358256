import {blockHeight} from "../graph";

export function getWalletHeight(anchors) {
	if (anchors.length > 8) {
		return anchors.length * 13;
	}

	if (anchors.length > blockHeight.moreThan5.count) {
		return blockHeight.moreThan5.height;
	}

	if (anchors.length > blockHeight.moreThan4.count) {
		return blockHeight.moreThan4.height;
	}

	if (anchors.length > blockHeight.moreThan3.count) {
		return blockHeight.moreThan3.height;
	}

	return 51;
}
