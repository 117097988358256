import {create} from 'zustand';

export const modalTypes = {
	editProject: 'editProject',
	deleteProject: 'deleteProject',
  createProject: 'createProject',
};

export const useModalStore = create((set) => ({
	modalType: null,
	params: {},
	/*
	* @modalType: modalType: ModalsTypes, params: any
	* */
	openModal: (type, params) => set(() => ({modalType: type, params})),
	closeModal: () => set(() => ({modalType: null, params: null}))
}));
