import {useState, useMemo} from 'react';
import ReactPaginate from 'react-paginate';
import PaginationIcon from "../../icons/paginationIcon";
import {PaginationInput} from "./pagination-input";
import {useTranslation} from "react-i18next";
import {useProjectStore} from "store/projects";
import {DEFAULT_PAGE_LIMIT} from "pages/Initial/initial-table/constants";
import {getDeviceSize} from "common/utils/responsive";
import "./pagination.scss";

export const PaginationComponent = ({pageClick, page}) => {
  const { projects, updateStatusShowMore } = useProjectStore(state => state);
	const [inputValue, setInputValue] = useState(null);
  const { t } = useTranslation();
  const isMobile = getDeviceSize();

  const pageCount = useMemo(() =>
    Math.ceil(projects.count / DEFAULT_PAGE_LIMIT), [projects.count]);

  const changePage = (select) => {
    const nextSelectedPage = select?.nextSelectedPage ?? select;
    setInputValue(null);
    updateStatusShowMore(!!nextSelectedPage);
    pageClick(nextSelectedPage + (nextSelectedPage === pageCount ? 0 : 1));
  };

	return (
		<div className="bn-pagination">
      {pageCount > 0 &&
        <ReactPaginate
          disabledClassName="disabled"
          className="pagination"
          breakLabel="..."
          previousLabel={<PaginationIcon/>}
          nextLabel={<PaginationIcon/>}
          onClick={changePage}
          pageCount={pageCount}
          forcePage={page-1}
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakClassName="page-item"
          breakLinkClassName="page-link"
          marginPagesDisplayed={1}
          containerClassName="pagination"
          activeClassName="active"
          pageRangeDisplayed={isMobile ? 3 : 5}
        />
      }
       <div className="bn-pagination__search_wrapper">
        <span>{t("naming.on")}</span>
        <PaginationInput
          type="number"
          className="bn-pagination__search"
          onChange={changePage}
          lastPage={pageCount}
          value={inputValue}
          setValue={setInputValue}
        />
      </div>
    </div>
  );
};
