// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.show-more__skeleton {
  width: 100% !important;
  height: 48px !important;
  border: 0.5px solid var(--Gray-gray-5);
  border-radius: 2px 2px 16px 16px !important;
}
@media (max-width: 767px) {
  .show-more__skeleton {
    border-radius: 2px !important;
    height: 40px !important;
  }
}

.show-more-container {
  margin-top: 8px;
}
@media (max-width: 767px) {
  .show-more-container {
    margin-top: 24px;
    width: 91.1%;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/Initial/initial-table/component/showMore/styles.scss","webpack://./src/common/styles/_mixins.scss"],"names":[],"mappings":"AAEA;EACE,sBAAA;EACA,uBAAA;EACA,sCAAA;EACA,2CAAA;AADF;ACOE;EDVF;IAOI,6BAAA;IACA,uBAAA;EAAF;AACF;;AAEA;EACE,eAAA;AACF;ACHE;EDCF;IAGI,gBAAA;IACA,YAAA;EAGF;AACF","sourcesContent":["@import \"../../../../../common/styles/mixins\";\n\n.show-more__skeleton {\n  width: 100% !important;\n  height: 48px !important;\n  border: 0.5px solid var(--Gray-gray-5);\n  border-radius: 2px 2px 16px 16px !important;\n\n  @include mobile() {\n    border-radius: 2px !important;\n    height: 40px !important;\n  }\n}\n.show-more-container {\n  margin-top: 8px;\n  @include mobile() {\n    margin-top: 24px;\n    width: 91.1%;\n  }\n}\n","@mixin font($size: 14px, $weight: 400, $lineHeight: 18px) {\n  font-size: $size;\n  line-height: $lineHeight;\n  font-weight: $weight;\n  font-family: 'Roboto';\n}\n\n@mixin maxHeight() {\n  height: calc(100vh - 7px);\n}\n\n@mixin mobile {\n  @media (max-width: 767px) {\n    @content;\n  }\n}\n\n@mixin tablet {\n  @media (max-width: 1224px) {\n    @content;\n  }\n}\n\n@mixin laptop {\n  @media (max-width: 1440px) {\n    @content;\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
