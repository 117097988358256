import React, {useEffect, useState} from "react"
import {useTranslation} from "react-i18next";
import CopyContent from "common/ui/copyContent";
import AssetAmount from "common/ui/assetAmount";
import formatDate, {EFormats} from "common/utils/formatDate";
import Spinner from "common/ui/spinner";
import {smartTrim} from "components/creation/utils";
import {DetailTable} from "../detail-table/detail-table";
import {useProjectStore} from "store/projects";

const Overview = ({graph}) => {
  const { drawerData, drawerLoader} = useProjectStore(s => s);
  const [showTable, setVisible] = useState(false);
  const {t} = useTranslation();

  useEffect(() => {
    setVisible(true);
  }, [setVisible]);

  if(drawerLoader) {
    return (
      <div className='drawer'>
        <Spinner/>
      </div>
    )
  }
	return (
    <div className="drawer">
      <div className="drawer__header">
        <h4>{drawerData?.entity_name ? drawerData?.entity_name : 'Unknown'}</h4>
        <p>
          <CopyContent children={drawerData?.address} copyText={drawerData?.address}/>
        </p>
      </div>
      <div className="drawer__content">
        <div className="drawer__table_row">
          <div className="drawer__table_row_wrapper">
            <span>{t("common.address")}:</span>
            <span>{drawerData?.address ?
              <CopyContent children={smartTrim(drawerData?.address,12)} copyText={drawerData?.address}/> : "―"}</span>
          </div>
          <div className="drawer__table_row_wrapper">
            <span>{t("common.category")}:</span>
            <span>{drawerData?.entity_category ? drawerData?.entity_category : "―"}</span>
          </div>
          <div className="drawer__table_row_wrapper">
            <span>{t("common.balance")}:</span>
            <span>{drawerData?.balance_usd ? <AssetAmount
              amount={drawerData.balance_usd}
              asset="USD"
              disableSign={true}/> : "―"}</span>
          </div>
        </div>
        <div className="drawer__table_row">
          <div className="drawer__table_row_wrapper">
            <span>{t("common.inflow")}:</span>
            <b>{drawerData?.inflow_usd ? <AssetAmount
              amount={drawerData.inflow_usd}
              asset="USD"
              disableSign={true}/> : "―"}</b>
          </div>
          <div className="drawer__table_row_wrapper">
            <span>{t("common.outflow")}:</span>
            <b>{drawerData?.outflow_usd ? <AssetAmount
              amount={drawerData.outflow_usd}
              asset="USD"
              disableSign={true}/> : "―"}</b>
          </div>
        </div>
        <div className="drawer__table_row">
          <div className="drawer__table_row_wrapper">
            <span>{t("common.numberAddress")}:</span>
            <span>{drawerData?.address_count}</span>
          </div>
          <div className="drawer__table_row_wrapper">
            <span>{t("common.numberTx")}:</span>
            <span>{drawerData?.transfer_count}</span>
          </div>
        </div>
        <div className="drawer__table_row">
          <div className="drawer__table_row_wrapper">
            <span>{t("common.firstTX")}:</span>
            <span>{formatDate(drawerData?.first_transfer_at, EFormats.D)}
              <em>{formatDate(drawerData?.first_transfer_at, EFormats.T)}</em></span>
          </div>
          <div className="drawer__table_row_wrapper">
            <span>{t("common.lastTx")}:</span>
            <span>{formatDate(drawerData?.last_transfer_at, EFormats.D)}
              <em>{formatDate(drawerData?.last_transfer_at, EFormats.T)}</em></span>
          </div>
        </div>
        {showTable && <DetailTable graph={graph} />}
      </div>
    </div>
  )
}
export default Overview;
