import { AVAILABLE_LANGUAGES, ELanguages, FALLBACK_LNG } from "./constants";
import i18n from "./index";

export const getFallbackLng = () => {
  const lng = process.env.REACT_APP_LANGUAGE;
  if (lng && AVAILABLE_LANGUAGES.includes(lng)) {
    return { [lng]: [lng] };
  }
  return FALLBACK_LNG;
};

export const onChangeLanguageHandler = (lng) => {
  i18n.changeLanguage(lng);
};

export const changeLanguageAuto = () => {
  const browserLanguage = navigator.language;
  const currentLanguage = i18n.language;

  if (currentLanguage === browserLanguage) return;
  if (browserLanguage.slice(0, 2) === "ru" && currentLanguage.slice(0, 2) === "ru") return;

  if (AVAILABLE_LANGUAGES.includes(browserLanguage)) {
    onChangeLanguageHandler(browserLanguage);
  } else if (browserLanguage.slice(0, 2) === "ru") {
    onChangeLanguageHandler(ELanguages.ruRU);
  } else if (currentLanguage !== ELanguages.enUS) {
    onChangeLanguageHandler(ELanguages.enUS);
  }
};

export const changeLanguageSelected = (selected) => {
  const currentLanguage = i18n.language;
  if (AVAILABLE_LANGUAGES.includes(selected) && currentLanguage !== selected) {
    onChangeLanguageHandler(selected);
  }
};
