import {moneyFullFormat} from "common/utils/tokenAssetFormat/moneyFullFormat";
import {moneyFormat} from "common/utils/tokenAssetFormat/moneyFormat";
import {_getSing} from "common/utils/tokenAssetFormat/getSign";

export const moneyFormatWithAsset = ({ amount, asset,reverse=false,sign,isIncoming,disableSign,short,fullNumber,countDec,valueUsd }) => {
  if ( amount === null || amount === undefined) return `??? ${asset || ''}`;
  const $sign = _getSing({sign,disableSign,isIncoming,valueUsd});
  if(fullNumber) {
    return `${moneyFullFormat({amount,countDec})} ${asset || ''}`
  }
  if(reverse) {
    return `${$sign} ${asset || ''}${moneyFormat(amount,short)} `;
  }
  return `${$sign} ${moneyFormat(amount,short)} ${asset || ''}`;
};