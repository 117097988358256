import React from "react"
import {useTranslation} from "react-i18next";

import CopyContent from "common/ui/copyContent";
import formatDate, {EFormats} from "common/utils/formatDate";
import compressText from "common/utils/compressText";
import RedBasketIcon from "common/icons/redBusketIcon";
import {modalTypes, useModalStore} from "store/modal";
import {EParamsType} from "common/ui/modals/deleteModal/deleteModal";
import {getCategoryTranslations} from "common/utils/categoryTranslation";
import Spinner from "common/ui/spinner";
import {useProjectStore} from "store/projects";
import MoneyAmount from "common/ui/moneyAmount";
import {getDeviceSize} from "common/utils/responsive";
import '../details-tab.styles.scss'

const Transfers = ({drawerData}) => {
  const {t} = useTranslation();
  const {isMobile} = getDeviceSize()
  const { drawerLoader} = useProjectStore(s => s);
  const { openModal } = useModalStore((state) => state);
  const handleDelete = () => {
    openModal(modalTypes.deleteProject, {network:drawerData.id,editName:t("common.transfer"),type:EParamsType.tx_hash})
  }
  if(drawerLoader) {
    return (
      <div className="drawer__transfer">
        <div className="spinner-wrapper"><Spinner/></div>
      </div>
    )
  }

  return (
    <div className="drawer__transfer">
      <div className="drawer__header">
        <h4>{t("common.transfer")}</h4>
        <p>
          <CopyContent children={compressText(drawerData?.tx_hash,44)} copyText={drawerData?.tx_hash}/>
        </p>
      </div>
      <div className="drawer__content">
        <div className="drawer__table_row">
          <div className="drawer__table_row_wrapper">
            <span>{t("common.txHash")}:</span>
            <span><CopyContent children={compressText(drawerData?.tx_hash, 24)} copyText={drawerData?.tx_hash}/></span>
          </div>
          <div className="drawer__table_row_wrapper">
            <span>{t("common.occurredAt")}:</span>
            <span>{drawerData?.occurred_at ? formatDate(drawerData?.occurred_at, EFormats.LT) : "―"}</span>
          </div>
          <div className="drawer__table_row_wrapper">
            <span>{t("common.amountCrypto")}:</span>
            <span>{<MoneyAmount
                amount={drawerData.amount}
                fullNumber={true}
                countDec={isMobile ? 12 : 18}
                asset={drawerData?.token_symbol}
                disableSign={true}/>}</span>
          </div>
          <div className="drawer__table_row_wrapper">
            <span>{t("common.amountUsd")}:</span>
            <span>{<MoneyAmount
                amount={drawerData.value_usd}
                asset="$"
                reverse={true}
                short={true}
                disableSign={true}/>}</span>
          </div>
        </div>
        <div className="drawer__table_row">
          <div className="drawer__table_row_wrapper">
            <span>{t("common.senderAddress")}:</span>
            <span>
              <CopyContent children={compressText(drawerData?.sender_address, 6,4)}
                 copyText={drawerData?.sender_address}/>
            </span>
          </div>
          <div className="drawer__table_row_wrapper">
            <span>{t("common.senderEntity")}:</span>
            <span className='entity'>
              <CopyContent children={compressText(drawerData?.sender_entity_name,20)}
                copyText={drawerData?.sender_entity_name}/>
            </span>
          </div>
          <div className="drawer__table_row_wrapper">
            <span>{t("common.senderCategory")}:</span>
            <span>{getCategoryTranslations(drawerData?.sender_entity_category)}</span>
          </div>
        </div>
        <div className="drawer__table_row">
          <div className="drawer__table_row_wrapper">
            <span>{t("common.recipientAddress")}:</span>
            <span>
               <CopyContent children={compressText(drawerData?.recipient_address, 6,4)}
                  copyText={drawerData?.recipient_address}/>
            </span>
          </div>
          <div className="drawer__table_row_wrapper">
            <span>{t("common.recipientEntity")}:</span>
            <span className='entity'>
              <CopyContent children={compressText(drawerData?.recipient_entity_name,20)}
                copyText={drawerData?.recipient_entity_name}/></span>
          </div>
          <div className="drawer__table_row_wrapper">
            <span>{t("common.recipientCategory")}:</span>
            <span>{getCategoryTranslations(drawerData?.recipient_entity_category)}</span>
          </div>
        </div>
        <div className='delete-transfer'>
          <div className="delete-content" onClick={handleDelete}>
            <RedBasketIcon/>
            <span>{t("initial.deleteTransfer")}</span>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Transfers;
