import React, {useEffect, useRef} from "react";
import cn from "classnames";

import {Toolbar} from "../toolbar";
import CloseMediumIcon from "common/icons/closeMediumIcon";
import LoadDataTransfer from "common/utils/loadDataTransfer";
import "./drawerComponent.scss";
import {useProjectStore} from "store/projects";
import Spinner, {ESpinnerType} from "../spinner";

export const DrawerComponent = ({children, open, onClose, graph}) => {
  const parentRef = useRef(null);
	const { drawerLoader, drawerData } = useProjectStore(s => s);
  const { isScrolled} = LoadDataTransfer({parentRef});

	useEffect(() => {
		if (parentRef.current) {
			parentRef.current.scrollTo({top: 0});
		}
	}, [drawerData]);

	useEffect(() => {
		return () => {
			onClose();
		}
	}, []);

  return <>
		<Toolbar open={open} graph={graph} />
		<div ref={parentRef}
         className={`drawer-layout drawer-${open ? 'open' : 'close'}`}>
			<div className={cn("drawer-layout__top", {
        _scrolled: isScrolled,
      })}>
				<CloseMediumIcon onClick={onClose}/>
			</div>
			{drawerLoader && <div className="loader"><Spinner size="large" iconType={ESpinnerType.loading} /></div>}
			{children}
		</div>
	</>
}
