export const wordBreak = (text, len, lines) => {
  const array = text?.split('');
  const ellipsis = '…';
  let newText = '';
  let lineCount = 0;

  if (text?.length <= len) {
    return text;
  }

  for (let i = 0; i < array?.length; i++) {
    newText += array[i];
    if ((i + 1) % len === 0) {
      lineCount++;
      if (lineCount >= lines) {
        newText += ellipsis;
        break;
      }
      newText += (array[i+1] === ' ' || (array[i+1] === '-' || array[i+1] === '-')) ? '\n' : ' -\n';
    }
  }
  return newText;
};
