const CloseMediumIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    {...props}>
    <path
      d="M13.0982 15.4359C12.8807 15.2264 12.7602 14.9665 12.7365 14.6564C12.7454 14.3458 12.8536 14.0829 13.061 13.8677C13.2684 13.6525 13.5191 13.5429 13.8132 13.539C14.14 13.5347 14.4121 13.6373 14.6295 13.8468L19.9736 18.9967C20.1241 19.1417 20.2632 19.1481 20.3908 19.0156L25.5831 13.6275C25.7905 13.4123 26.0576 13.3026 26.3844 13.2982C26.7111 13.2939 26.9833 13.3965 27.2007 13.606C27.4181 13.8155 27.5307 14.0837 27.5385 14.4104C27.5462 14.7371 27.4464 15.0081 27.239 15.2233L22.0467 20.6114C21.9191 20.7438 21.9306 20.8825 22.0811 21.0276L27.4252 26.1775C27.6426 26.387 27.7469 26.6471 27.7379 26.9577C27.7616 27.2678 27.6698 27.5305 27.4624 27.7457C27.255 27.9609 26.9879 28.0707 26.6612 28.075C26.367 28.0789 26.1113 27.9761 25.8938 27.7666L20.5498 22.6167C20.3992 22.4717 20.2601 22.4653 20.1325 22.5978L14.9402 27.9859C14.7329 28.2011 14.4658 28.3108 14.139 28.3152C13.8122 28.3195 13.5401 28.2169 13.3227 28.0074C13.1052 27.7979 12.9926 27.5297 12.9849 27.203C12.9771 26.8763 13.077 26.6053 13.2843 26.3901L18.4766 21.002C18.6042 20.8696 18.5928 20.7309 18.4422 20.5858L13.0982 15.4359Z"
      fill="#B9BBC2"/>
  </svg>
)
export default CloseMediumIcon;