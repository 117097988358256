// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.drawer-layout {
  position: fixed;
  height: 100vh;
  height: calc(100vh - 7px);
  width: 464px;
  top: 44px;
  transition: all 0.25s ease-in-out;
  padding: 22px 16px;
  box-shadow: -10px 0px 30px 0px rgba(0, 0, 0, 0.1);
  background: var(--white);
  overflow-x: auto;
  z-index: 99;
}
.drawer-layout__top {
  position: relative;
  height: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}
@media (max-width: 767px) {
  .drawer-layout__top {
    justify-content: space-between;
  }
}
.drawer-layout__top svg {
  cursor: pointer;
  margin-top: 14px;
}

.drawer-layout:has(.drawer__transfer) {
  padding: 37px 16px;
}

.drawer-open {
  right: 0;
  top: 0;
}

.drawer-close {
  right: -500px;
  top: 0;
}`, "",{"version":3,"sources":["webpack://./src/common/ui/drawerComponent/drawerComponent.scss","webpack://./src/common/styles/_mixins.scss"],"names":[],"mappings":"AAEA;EACE,eAAA;EACA,aAAA;ECIA,yBAAA;EDFA,YAAA;EACA,SAAA;EACA,iCAAA;EACA,kBAAA;EACA,iDAAA;EACA,wBAAA;EACA,gBAAA;EACA,WAAA;AADF;AAIE;EACE,kBAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,yBAAA;EACA,WAAA;AAFJ;ACRE;EDIA;IASI,8BAAA;EADJ;AACF;AAGI;EACE,eAAA;EACA,gBAAA;AADN;;AAKA;EACE,kBAAA;AAFF;;AAKA;EACE,QAAA;EACA,MAAA;AAFF;;AAKA;EACE,aAAA;EACA,MAAA;AAFF","sourcesContent":["@import \"../../../common/styles/mixins\";\n\n.drawer-layout {\n  position: fixed;\n  height: 100vh;\n  @include maxHeight();\n  width: 464px;\n  top: 44px;\n  transition: all 0.25s ease-in-out;\n  padding: 22px 16px;\n  box-shadow: -10px 0px 30px 0px rgba(0, 0, 0, 0.10);\n  background: var(--white);\n  overflow-x: auto;\n  z-index: 99;\n\n\n  &__top {\n    position: relative;\n    height: 10px;\n    display: flex;\n    align-items: center;\n    justify-content: flex-end;\n    width: 100%;\n\n    @include mobile() {\n      justify-content: space-between;\n    }\n\n    svg {\n      cursor: pointer;\n      margin-top: 14px;\n    }\n  }\n}\n.drawer-layout:has(.drawer__transfer) {\n  padding: 37px 16px;\n}\n\n.drawer-open {\n  right: 0;\n  top: 0;\n}\n\n.drawer-close {\n  right: -500px;\n  top: 0;\n}\n","@mixin font($size: 14px, $weight: 400, $lineHeight: 18px) {\n  font-size: $size;\n  line-height: $lineHeight;\n  font-weight: $weight;\n  font-family: 'Roboto';\n}\n\n@mixin maxHeight() {\n  height: calc(100vh - 7px);\n}\n\n@mixin mobile {\n  @media (max-width: 767px) {\n    @content;\n  }\n}\n\n@mixin tablet {\n  @media (max-width: 1224px) {\n    @content;\n  }\n}\n\n@mixin laptop {\n  @media (max-width: 1440px) {\n    @content;\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
