import {useMemo} from "react";
import {useTranslation} from "react-i18next";

export const ETransferType =  {
  deposit: "deposit",
  withdrawal: "withdrawal",
  deposit_attempt: "deposit_attempt",
  withdrawal_attempt: "withdrawal_attempt",
}
const GetTypeTransfer = ({type,input_count}) => {
  const {t} = useTranslation();
  const inputCount = input_count < 2

  return useMemo(() => {
    switch (type) {
      case ETransferType.deposit:
        return inputCount ? t("common.deposit") : t("common.deposits")
      case ETransferType.withdrawal:
        return inputCount ? t("common.withdrawal") : t("common.withdrawals")
      default:
        return inputCount ? t("common.transfer") : t("common.transfers")
    }
  },[t,inputCount,type])
}
export default GetTypeTransfer