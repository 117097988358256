import { create } from 'zustand';
import i18n from "i18next";
import { createJSONStorage, devtools, persist } from 'zustand/middleware';
// local files
import request from "api";
import {cleanStorage, EStorageKeys, setStorage} from "common/utils/storageHelper";
import {showSuccess} from "common/utils/notification";

export const useAuthStore = create()(
	devtools(
		persist(
			(set) => ({
				user: null,
				isAuthenticated: false,
				signIn: async (data,cb) => {
					try {
						const response = await request.post("/auth/email/sign-in/", data);
						setStorage(EStorageKeys.TOKEN, response.data);
						showSuccess(i18n.t("notification.signIn"));
            cb()
					} catch (error) {
						console.log(new Error(error));
					}
				},
				logout: () => {
					set({ user: null, isAuthenticated: false });
					cleanStorage();
				},
				updateUser: (userData) => set((state) => ({ user: { ...state.user, ...userData } })), // Действие для обновления данных пользователя
			}),
			{
				name: 'auth-storage', // Имя для сохранения в localStorage
				storage: createJSONStorage(() => localStorage), // Используем localStorage для сохранения состояния
			}
		)
	)
);
