import {create} from 'zustand';
import request from "../api";

export const useNetworkStore = create((set) => ({
	networks: [],
	getNetworks: async () => {
		try {
			const { data } = await request.get("/catalog/networks/");
			set(state => state.networks = data?.results);
		} catch (e) {
			console.log(e);
		}
	},
}))


