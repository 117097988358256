import i18n from "i18next";
import * as yup from "yup";

export const isValidEmail = (value) => emailRegex.test(normalizeValue(value));

const getMatch = (value) => (isValidEmail(value) && emailRegex);

const getText = (value) => {
  const textWithoutSpace = normalizeValue(value);
  const stringRegex = new RegExp(/[аАбБвВгГдДеЕёЁжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩъЪыЫьЬэЭюЮяЯ|a-zA-Z]/gi);
  const isEmail = stringRegex.test(textWithoutSpace);

  if (isEmail) {
    return i18n.t('validation.invalidEmail');
  } else return i18n.t('validation.invalidPhone');
};
export const stringField = yup.string();

export const loginValidationFieldRequired = yup.lazy(value =>
  stringField
    .trim()
    .test('empty', '', (val) => !!val?.length)
    .matches(getMatch(value), getText(value))
    .test("len", i18n.t('validation.invalidPhone'), e => handleLoginLength(e))
);

export const requiredStringField = stringField.test('empty', '', (val) => !!val?.length);

export const emailRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z\-0-9]{2,}))$/;


const handleLoginLength = (e) => {
  const valueStr = /[\D]+/g;
  return !(!!e && !isValidEmail(e) && e.trim().replace(valueStr, "").toString().length > 11);
};

export const normalizeValue = (value) => {
  if (!value) return "";
  const r = /[\s]/gi;
  return value.trim().replace(r, "");
};


// SCHEMAS
export const SignInSchema = yup.object().shape({
  email: loginValidationFieldRequired,
  password: requiredStringField,
});
