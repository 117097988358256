import React, { useState} from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import {useNavigate} from "react-router-dom";
import cn from "classnames";
import {useTranslation} from "react-i18next";
import {Form} from "antd";
import { useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";

import { useAuthStore } from 'store/auth';
import {paths} from "../../../../router/paths";
import ButtonComponent from "common/ui/button/button";
import {SignInSchema} from "../shemaSignIn";
import ControlledInput from "common/ui/controlledInput";

const SignInForm = () => {
  const recaptchaRef = React.useRef();
  const signIn = useAuthStore((state) => state.signIn);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const { control, handleSubmit, formState: { errors, isValid }} = useForm({
    resolver: yupResolver(SignInSchema),
    mode: 'onChange',
  });

  const submit = async ({email,password}) => {
    if (!loading) {
      setLoading(true)
      const recaptcha = await recaptchaRef.current?.executeAsync();
      await signIn({email, password, recaptcha});
      navigate(paths.main);
      setLoading(false)
    }
  };

  return (
    <Form
      onFinish={handleSubmit(submit)}
      className={cn("auth-form")}>
      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey={process.env.REACT_APP_GOOGLE_RE_CAPTCHA_CLIENT_SITE_KEY || ''}
      />
      <ControlledInput
        name='email'
        control={control}
        type='text'
        className="auth-form__input"
        label={t("auth.email")}
        errorText={errors.email?.message}/>
      <ControlledInput
        name='password'
        control={control}
        type='password'
        className="auth-form__input"
        label={t("auth.password")}
        errorText={errors.password?.message}/>
      <div className="save-content">
        <ButtonComponent
          disabled={!isValid}
          type="submit"
          className="auth-form__btn"
          loading={loading}
          text={t("auth.logIn")}/>
      </div>
    </Form>
  );
};

export default SignInForm;
