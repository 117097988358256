import React from "react";
import TableRow from "../tableRow";
import {useTranslation} from "react-i18next";
import {useProjectStore} from "store/projects";
import formatDate, {EFormats} from "common/utils/formatDate";
import {getCategoryTranslations} from "common/utils/categoryTranslation";
import compressText from "common/utils/compressText";
import CopyContent from "common/ui/copyContent";
import RedBasketIcon from "common/icons/redBusketIcon";
import {modalTypes, useModalStore} from "store/modal";
import {EParamsType} from "common/ui/modals/deleteModal/deleteModal";
import MoneyAmount from "common/ui/moneyAmount";
import GetTypeTransfer, {ETransferType} from "common/utils/tokenAssetFormat/getTypeTransfer";
import './index.scss'

const TableRowGroup = ({ children }) => {
  return <div className={"table-row-group_wrapper"}>{children}</div>;
};
const MainInfo = () => {
  const {t} = useTranslation();
  const { drawerData} = useProjectStore(s => s);
  const {id, entity_name, address } = drawerData;
  const { openModal } = useModalStore((state) => state);

  const handleDelete = () => {
    openModal(modalTypes.deleteProject, {
      network: id,
      editName: entity_name || compressText(address,6,4),
      type: EParamsType.rectNode
    });
  };

  return (
        <div className={"sidebar_main-info__wrapper"}>
          <div className={"sidebar_main-info__inner-wrapper"}>
            <TableRowGroup>
              <TableRow title={t("common.address")} value={<CopyContent
                children={compressText(drawerData?.address, 6, 4)}
                copyText={drawerData?.address}/>}/>
              <TableRow title={t("common.category")} value={getCategoryTranslations(drawerData?.entity_category)}/>
              <TableRow title={t("common.balance")} value={<MoneyAmount
                amount={drawerData.balance_usd}
                reverse={true}
                disableSign={true}
                short={true}
                asset="$"/>}/>
            </TableRowGroup>
            <TableRowGroup>
              <TableRow title={t("common.inflow")} value={
                <>
                  <MoneyAmount
                    amount={drawerData.inflow_usd}
                    asset="$"
                    reverse={true}
                    short={true}
                    disableSign={true}
                    style={{marginRight: '3px'}}
                  />
                  <MoneyAmount
                    amount={drawerData.input_count}
                    asset={GetTypeTransfer({type:ETransferType.deposit,input_count:drawerData.input_count})}
                    disableSign={true}
                    fullNumber={true}
                    style={{color: '#8C8D94'}}
                    brackets={true}
                  />
                </>
              }/>
              <TableRow title={t("common.outflow")} value={
                <>
                  <MoneyAmount
                    amount={drawerData.outflow_usd}
                    asset="$"
                    reverse={true}
                    short={true}
                    disableSign={true}
                    style={{marginRight: '3px'}}
                  />
                  <MoneyAmount
                    amount={drawerData.output_count}
                    asset={GetTypeTransfer({type:ETransferType.withdrawal,input_count:drawerData.output_count})}
                    disableSign={true}
                    fullNumber={true}
                    style={{color: '#8C8D94'}}
                    brackets={true}
                  />
                </>
              }/>
            </TableRowGroup>
            <TableRowGroup>
              <TableRow title={t("initial.addresses")}
                value={<MoneyAmount
                  amount={drawerData.address_count}
                  fullNumber={true}
                  disableSign={true}/>}
              />
              <TableRow title={t("common.transfers")}
                value={<MoneyAmount
                    amount={drawerData?.transfer_count}
                    fullNumber={true}
                    disableSign={true}/>}/>
            </TableRowGroup>
            <TableRowGroup>
              <TableRow
                title={t("common.firstTX")}
                value={formatDate(drawerData?.first_transfer_at, EFormats.LT)}
              />
              <TableRow
                title={t("common.lastTx")}
                value={formatDate(drawerData?.last_transfer_at, EFormats.LT)}
              />
            </TableRowGroup>
            <div className='delete-transfer'>
              <div className="delete-content" onClick={handleDelete}>
                <RedBasketIcon/>
                <span>{t("initial.deleteWallet")}</span>
              </div>
            </div>
          </div>
        </div>
    );
};

export default MainInfo;

