import React, {useEffect, useMemo} from "react";
import "./initial-search-bar.scss";
import Plus from "../../../common/icons/plus";
import ButtonComponent from "../../../common/ui/button/button";
import {SearchInput} from "../../../common/ui/searchInput/searchInput";
import {useTranslation} from "react-i18next";
import {modalTypes, useModalStore} from "../../../store/modal";
import {useNetworkStore} from "../../../store/networks";
import {Dropdown} from "antd";

export const InitialSearchBar = () => {
  const { t } = useTranslation();
  const { openModal } = useModalStore((state) => state);
  const {networks,getNetworks} = useNetworkStore((state) => state);
  const onChange = (e) => {
    openModal(modalTypes.createProject, {network: e.key});
  }

  useEffect( () => {
    getNetworks();
  }, []);
  const items = useMemo(() => {
    return networks.map(item => ({
      label: `${item.name} (${item.code})`,
      key: item.id
    }))
  }, [networks]);

	return <div className="initial-search-panel">
		<SearchInput onChange={null} />
		<div className="project-block">
      <Dropdown
        menu={{items, onClick: onChange}}
        paddingBlock={20}
        trigger={['click']}
        overlayStyle={{width: '200px'}}
        placement="bottomLeft">
        <ButtonComponent
          text={t("initial.newProject")}
          colorType="pink"
          icon={<Plus/>}
          width={160}
          className="init-new-project"
        />
      </Dropdown>
		</div>
	</div>
}
