import React, {useMemo, useState} from 'react';
import {modalTypes, useModalStore} from "store/modal";
import ModalContainer from "../modalContainer";
import ButtonComponent from "common/ui/button/button";
import RedWarnIcon from "common/icons/redWarnIcon";
import {useTranslation} from "react-i18next";
import {useProjectStore} from "store/projects";
import "./deleteModal.scss";
import {useParams} from "react-router-dom";

export const EParamsType = {
  tx_hash: "tx_hash",
  rectNode:"rect-node"
}
// delete props
export const DeleteModal = ({graph}) => {
	const { projectId} = useParams();
  const { modalType, closeModal, params } = useModalStore((state) => state);
  const { deleteProject, deleteWalletProject, deleteTransferProject} = useProjectStore((state) => state);
  const { t } = useTranslation();
	const [loading, setLoading] = useState(false);

  const deleteAction = useMemo(() => {
    const eventParams = { id: params?.network };

    switch (params?.type) {
      case EParamsType.tx_hash:
        return async () => {
          await deleteTransferProject(eventParams);
        };
      case EParamsType.rectNode:
        return async () => {
          await deleteWalletProject({projectId, data: eventParams});
        };
      default:
        return async () => {
          await deleteProject(eventParams);
        };
    }
  }, [params?.type, params?.network, deleteWalletProject, deleteProject, deleteTransferProject]);

  const onClick = () => {
		if (loading) return;
    setLoading(true);

    deleteAction().finally(() => {
      setLoading(false);
			closeModal();
    });
  };

  return (
		<ModalContainer closeModal={closeModal} width={416} open={modalType === modalTypes.deleteProject}
                    modalTypes={modalTypes}>
			<div className="modal-delete-wrapper">
				<div className="modal-delete-content">
					<RedWarnIcon/>
					<h6>{t("initial.deleteMemberTitle",
            {name: params?.editName ? params?.editName?.name || params?.editName : null})}</h6>
					<p>{t("initial.deleteModalText")} </p>
				</div>
				<div className="modal-delete-footer">
					<ButtonComponent lineWidth={1} onClick={() => closeModal()} colorType="white" text={t("action.cancel")} />
					<ButtonComponent colorType="red" loading={loading} onClick={onClick} text={t("action.delete")} />
				</div>
			</div>
		</ModalContainer>
	);
};
