import React, {useEffect, useState} from 'react';
import { useParams} from "react-router-dom";
import {getEdges, getNodes} from "./utils";
import {initGraph, nodeCustomEvents, registerFn} from "./graph";
import {MainLayout} from "common/layout/main/MainLayout";
import {SearchPanel} from "./components/search-panel";
import {BlockDetails} from "pages/Graph/components/block-details";
import {DeleteModal} from "common/ui/modals/deleteModal/deleteModal";
import CreateEditProjectModal from "common/ui/modals/createEditProjectModal";
import {useProjectStore} from "store/projects";
import {useDrawerStore} from "store/drawer";
import {graphOnDragCanvas} from "./utils/graphOnDragCanvas";
import {onEdgeClickState} from "./utils/onEdgeClickState";
import {defaultMovingCanvas} from "./utils/defaultMovingCanvas";
import "./creation.styles.scss";
import {zoomOnMobile} from "./utils/zoomOnMobile";

export default function ({objects}) {
	let graph = null;
	const { updateNodePosition, graphCanvasPositions, updateCanvasPosition, updateObjectsPosition, setGraph } = useProjectStore(s => s);
	const ref = React.useRef(null);
	const [el, setEl] = useState(null);
  const { setHoverNode,node } = useDrawerStore(s => s);
  const { projectId} = useParams();
	const { setZoomValue } = useProjectStore(s => s);
  const nodeId = node?.getModel().id;
  const selectedWallet = objects?.wallets.find((item) => item?.id === nodeId);
  const selectedId = selectedWallet?.id

	useEffect(() => {
		if (graph) {
			graph.setMode('noneAvailable');
		}
		registerFn();
	}, [graph]);

	// TODO remove
	useEffect(() => {
		if (el) {
			const nodes = getNodes(objects?.transfers, objects.wallets);
			const newEdges = getEdges(objects?.transfers || []);
			el.destroyLayout();
			el.changeData({nodes, edges: newEdges});
		}
	}, [el, objects]);

	useEffect(() => {
		if (!graph && !el) {
			const nodes = getNodes(objects?.transfers, objects.wallets);
			const newEdges = getEdges(objects?.transfers || []);

			graph = initGraph({
				graph,
				data: {nodes, edges: newEdges},
				ref,
        setHoverNode
			});
			setGraph(graph);
			onEdgeClickState(graph);

			setEl(graph);
		}
	}, [objects.wallets]);

	useEffect(() => {
		if (graph) {
			const canvasPosition = graphCanvasPositions[projectId] || {x: 0, y: 0};

			// set the position of canvas by dragging
			graphOnDragCanvas({
				graph,
				canvasPosition,
				updateNodePosition,
				updateCanvasPosition,
				updateObjectsPosition,
				objects,
				id:projectId
			});
		}
	}, [graphCanvasPositions, projectId]);

  useEffect(() => {
	  if (!!el) {
		  const canvasPosition = graphCanvasPositions[projectId] || {x: 0, y: 0};
			zoomOnMobile(el, setZoomValue, ref);
		  defaultMovingCanvas({canvasPosition, setZoomValue, graph: el, projectId, updateCanvasPosition});
	  }
  }, [el]);

  useEffect(() => {
    if (selectedId) {
      const nodeItem = el?.findById(selectedId);
      if (nodeItem) {
        el?.setItemState(nodeItem, nodeCustomEvents.clicked, true);
      }
    }
  }, [selectedWallet,objects]);

  return <MainLayout>
    <SearchPanel graph={el}/>
		<div className='graph-container' ref={ref}></div>
		<BlockDetails graph={el}/>
    <DeleteModal />
    <CreateEditProjectModal/>
	</MainLayout>;
}
