import i18n from "../../i18n";
import {ELanguages} from "../../i18n/constants";

export const commafy = (num) => {
  const addThousandsSeparator = i18n.language === ELanguages.enUS ? ',' : ' '
  const emptyStr = '';
  const group_regex = /\d{3}/g;
  const trimComma = str => str.replace(/^[,]+|[,]+$/g, emptyStr)
  const str = num + emptyStr;
  const [integer, decimal] = str.split('.')
  const conversed = reverseString(integer);
  const grouped = trimComma(reverseString(
    conversed.replace(group_regex, match => `${match}${addThousandsSeparator}`)
  ));

  return !decimal ? grouped : `${grouped}.${decimal}`;
}

function reverseString(str) {
  return str.split("").reverse().join("");
}
