import React, {useEffect, useState} from 'react';
import {getEdges, getNodes} from "./utils";
import {initGraph, registerFn} from "./graph";
import {MainLayout} from "common/layout/main/MainLayout";
import {SearchPanel} from "./components/search-panel";
import {onDragNode} from "./utils/nodeDragging";
import {zoomCanvas} from "./utils/zoomCanvas";
import {BlockDetails} from "pages/Graph/components/block-details";
import {DeleteModal} from "common/ui/modals/deleteModal/deleteModal";
import CreateEditProjectModal from "common/ui/modals/createEditProjectModal";
import {useProjectStore} from "store/projects";
import {graphOnDragCanvas} from "./utils/graphOnDragCanvas";
import "./creation.styles.scss";
import {onNodeClickSetActive} from "./utils/onNodeClickSetActive";
import {onEdgeClickState} from "./utils/onEdgeClickState";

let eventsFixed = false;

export default function ({objects}) {
	let graph = null;
	const { updateNodePosition, canvasPosition, resetStateByKey, updateCanvasPosition, updateObjectsPosition, setGraph } = useProjectStore(s => s);
	const ref = React.useRef(null);
	const [el, setEl] = useState(null);

	useEffect(() => {
		if (graph) {
			graph.setMode('noneAvailable');
		}
	}, [graph]);

	useEffect(() => {
		if (el) {
			const nodes = getNodes(objects?.transfers, objects.wallets);
			const newEdges = getEdges(objects?.transfers || []);
			el.destroyLayout();
			el.changeData({nodes, edges: newEdges});
		}
	}, [el, objects]);

	registerFn();

	useEffect(() => {
		if (!graph && !el) {
			const nodes = getNodes(objects?.transfers, objects.wallets);
			const newEdges = getEdges(objects?.transfers || []);

			graph = initGraph({
				graph,
				data: {nodes, edges: newEdges},
				ref,
			});
			setGraph(graph);
			onEdgeClickState(graph);

			if (canvasPosition.x && canvasPosition.y) {
				graph.moveTo(canvasPosition?.x, canvasPosition?.y);
			}

			graphOnDragCanvas({
				graph,
				canvasPosition,
				updateNodePosition,
				updateCanvasPosition,
				updateObjectsPosition,
				objects,
			});
			setEl(graph);
		}
	}, [objects.wallets]);

	useEffect(() => {
		window.oncontextmenu = () => false;
		const canvas = document.getElementsByTagName('canvas')[0];
		if (eventsFixed) return;

		const {addEvent, removeEvent} = onDragNode({graph, canvas, openAddressDetails});

		const { initZoom } = zoomCanvas({canvas, graph});

		addEvent();
		eventsFixed = true;
		initZoom();

		return () => {
			resetStateByKey([{
				key: 'objects',
				value: {
					transfers: null,
					wallets: null,
				}
			}]);
			removeEvent();
		}
	}, [resetStateByKey]);

	const openAddressDetails = (event) => {
		onNodeClickSetActive(graph, event);
	}

	return <MainLayout>
		<SearchPanel graph={el} />
		<div className='graph-container' ref={ref}></div>
		<BlockDetails graph={el}/>
    <DeleteModal graph={el} />
    <CreateEditProjectModal/>
	</MainLayout>;
}
