export const ELanguages = {
  enUS: "en-US",
  ruRU: "ru-RU",
};

export const AVAILABLE_LANGUAGES = [ELanguages.enUS, ELanguages.ruRU];

export const LANGUAGES = {
  [ELanguages.ruRU]: { nativeName: 'Русский' },
  [ELanguages.enUS]: { nativeName: 'English' },
};

export const FALLBACK_LNG = {
  [ELanguages.ruRU]: [ELanguages.ruRU],
  [ELanguages.enUS]: [ELanguages.enUS],
};
