const RussianFlag= () => (
  <svg
    className="flag"
    xmlns="http://www.w3.org/2000/svg"
    width="512"
    height="512"
    style={{ "borderRadius":"50%" }}
    viewBox="0 0 512 512">
    <path fill="#0052b4" d="m0 167 253.8-19.3L512 167v178l-254.9 32.3L0 345z"/>
    <path fill="#eee" d="M0 0h512v167H0z"/>
    <path fill="#d80027" d="M0 345h512v167H0z"/>
  </svg>
);

export default RussianFlag;