import React from "react"
import ArrowRight from "common/icons/arrowRight";
import Plus from "common/icons/plus";
import {useParams} from "react-router-dom";
import {useProjectStore} from "store/projects";
import compressText from "common/utils/compressText";
import AssetAmount from "common/ui/assetAmount";
import Spinner, {ESpinnerType} from "common/ui/spinner";
import "../search-panel.scss";
/*
* @type: string - searchItemType;
* @account: [];
* @transfers: [];
* */
export const SearchItem = ({wallets, transfers, fetching, searchValue}) => {
  const { projectId } = useParams();
  const { addTransfer, addWallet,walletTransferLoader } = useProjectStore(s => s);

  const addItem = async (ext_id, isWallet = false) => {
    const body = {id: projectId, ext_id};

    if (isWallet) {
      await addWallet(body);
    } else {
      await addTransfer(body);
    }
  };
  if (!searchValue?.length) {
    return null;
  }

  if(fetching) {
    return <div className="item">
      <Spinner iconType={ESpinnerType.loading}/>
    </div>
  }

	if (!wallets?.length && !transfers?.length) {
		return <div className="item"><span>No Data</span></div>;
	}

  if (!!wallets?.length) {
    return wallets.map(account => (<div className="item">
      <p className='item__data-address'>{compressText(account.address ,37)}</p>
      {walletTransferLoader &&  <Spinner iconType={ESpinnerType.loading} className='spinner' size='small'/>}
      {!walletTransferLoader && (account.id  ? <span className='select'></span> :
        <Plus className="add" onClick={(() => addItem(account.ext_id, true))}/>)}
    </div>));
  }

  if (!!transfers?.length) {
    return transfers.map(trf => (<div className="item">
      <div>
        <p>{compressText(trf.sender_address, 6,6)}</p>
        <ArrowRight/>
        <p>{compressText(trf.recipient_address,6,6)}</p>
      </div>
      <div>
        <b>
          <AssetAmount
            amount={trf.amount}
            asset={trf.token_symbol || null}
            disableSign={true}
          />
        </b>
        {walletTransferLoader &&  <Spinner iconType={ESpinnerType.loading} className='spinner' size='small'/>}
        {!walletTransferLoader && (trf.id  ? <span className='select'></span> :
          <Plus className="add" onClick={(() => addItem(trf.ext_id))}/>)}
      </div>
    </div>))
  }
};
