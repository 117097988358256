/*
* @text: string
* @_leftSize: number
* @rightSize: number
* */
const compressText = (text, _leftSize, rightSize = 0) => {
  const leftSize = _leftSize || 4;
  if (text && text.length < 8 + leftSize) return text;
  return `${text?.slice(0, leftSize)}...${text?.slice(text?.length - rightSize, text?.length)}`;
};

export default compressText;
