const WalletIcon = (props) => (<svg
  width="12"
  height="12"
  viewBox="0 0 12 12"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  {...props}>
  <path
    d="M9.22535 3H2.77479C2.18913 3 1.71436 3.47476 1.71436 4.06042V8.79673C1.71436 9.38238 2.18913 9.85714 2.77479 9.85714H9.22535C9.81101 9.85714 10.2858 9.38238 10.2858 8.79673V4.06042C10.2858 3.47476 9.81101 3 9.22535 3Z"
    stroke={props?.stroke || "#232325"} stroke-width="0.6" stroke-linecap="round" stroke-linejoin="round"/>
  <path
    d="M1.71436 3.57143V3.06391C1.71434 2.8952 1.77924 2.73101 1.89936 2.5959C2.01949 2.46079 2.18838 2.36202 2.38078 2.31434L7.57245 1.02668C7.68857 0.997919 7.81107 0.992321 7.93022 1.01033C8.04936 1.02833 8.16188 1.06944 8.25885 1.1304C8.35581 1.19135 8.43455 1.27048 8.48882 1.36149C8.54309 1.4525 8.5714 1.5529 8.5715 1.65472V2.69776"
    stroke={props?.stroke || "#232325"} stroke-width="0.6" stroke-linecap="round" stroke-linejoin="round"/>
  <path
    d="M7.9602 7.3745C8.33976 7.3745 8.64745 7.06681 8.64745 6.68725C8.64745 6.30769 8.33976 6 7.9602 6C7.58064 6 7.27295 6.30769 7.27295 6.68725C7.27295 7.06681 7.58064 7.3745 7.9602 7.3745Z"
    stroke={props?.stroke || "#232325"} stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>)
export default WalletIcon;