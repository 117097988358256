const RedBasketIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    {...props}>
    <g clip-path="url(#clip0_869_4859)">
      <path
        d="M9.8377 10.2399C9.81548 10.6958 9.42591 11.05 8.95573 11.05H3.04427C2.57539 11.05 2.18453 10.6946 2.1623 10.24C2.1623 10.24 2.1623 10.2399 2.1623 10.2399L1.84302 3.75H1.10417C1.0212 3.75 0.95 3.68452 0.95 3.6V3.2C0.95 2.94923 1.16053 2.75 1.41667 2.75H3.03333V1.8C3.03333 1.32923 3.43136 0.95 3.91667 0.95H8.08333C8.56864 0.95 8.96667 1.32923 8.96667 1.8V2.75H10.5833C10.8395 2.75 11.05 2.94923 11.05 3.2V3.6C11.05 3.68452 10.9788 3.75 10.8958 3.75H10.157M9.8377 10.2399L9.8377 10.24L9.78776 10.2375L9.8377 10.2399ZM9.8377 10.2399L10.157 3.75M10.157 3.75H10.1094V3.7L10.1593 3.70246L10.157 3.75ZM4.07083 1.95H7.92917V2.75H4.07083V1.95ZM9.11805 3.75L8.80787 10.05H3.19213L2.88195 3.75H9.11805Z"
        fill="white" stroke="#E6424C" stroke-width="0.8"/>
    </g>
    <defs>
      <clipPath id="clip0_869_4859">
        <rect width="12" height="12" fill="white"/>
      </clipPath>
    </defs>
  </svg>
)
export default RedBasketIcon;