import {TableActions} from "../../table-actions/table-actions";
import React from "react";
import EllipsAction from "common/icons/ellipsAction";

export const GetColumns = (t) => [
  {
    title: t("initial.projectName"),
    dataIndex: 'name',
    render: (value) => <b>{value?.name} {value?.date}</b>,
  },
  {
    title: t("initial.lastUpdate"),
    dataIndex: 'updates',
    sorter: false,
    showSorterTooltip: false,
    render: (item) => {
      return <div>
        <span>{item?.date}</span>
        <span className="grey">{item?.time}</span>
      </div>
    },
  },
  {
    title: t("initial.blockchain"),
    dataIndex: 'blockchain',
  },
  {
    title: t("initial.asset"),
    dataIndex: 'asset',
  },
  {
    title: t("initial.accounts"),
    dataIndex: 'accounts',
  },
  {
    title: '',
    render: (item) => <TableActions item={item} icon={<EllipsAction/>}/>
  }
];
