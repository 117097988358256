// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.initial-search-panel {
  display: flex;
  justify-content: space-between;
  margin: 0 0 40px 0;
}
.initial-search-panel .init-new-project {
  width: 160px;
  height: 32px;
}
.initial-search-panel .init-new-project span {
  color: var(--white);
}
.initial-search-panel input {
  border: none;
  width: 100%;
  height: 100%;
  display: block;
  background: transparent;
  position: relative;
  outline: none;
  padding: 0 18px 0 6px;
  font-family: var(--roboto);
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  font-family: "Arial";
  color: var(--Gray-gray-9);
}
.initial-search-panel input::placeholder {
  color: var(--Gray-gray-6);
  font-family: Roboto;
  font-family: var(--roboto);
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  font-family: "Arial";
}`, "",{"version":3,"sources":["webpack://./src/pages/Initial/initial-search-bar/initial-search-bar.scss","webpack://./src/common/styles/_mixins.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,8BAAA;EACA,kBAAA;AADF;AAGE;EACE,YAAA;EACA,YAAA;AADJ;AAEI;EACE,mBAAA;AAAN;AAIE;EACE,YAAA;EACA,WAAA;EACA,YAAA;EACA,cAAA;EACA,uBAAA;EACA,kBAAA;EACA,aAAA;EACA,qBAAA;EACA,0BAAA;ECvBF,eDwBgB;ECvBhB,iBDuB2B;ECtB3B,gBDsBsB;ECrBtB,oBAAA;EDsBE,yBAAA;AACJ;AACI;EACE,yBAAA;EACA,mBAAA;EACA,0BAAA;EC9BJ,eD+BkB;EC9BlB,iBD8B6B;EC7B7B,gBD6BwB;EC5BxB,oBAAA;ADgCF","sourcesContent":["@import \"../../../common/styles/mixins\";\n\n.initial-search-panel {\n  display: flex;\n  justify-content: space-between;\n  margin: 0 0 40px 0;\n\n  .init-new-project {\n    width: 160px;\n    height: 32px;\n    span {\n      color: var(--white);\n    }\n  }\n\n  input {\n    border: none;\n    width: 100%;\n    height: 100%;\n    display: block;\n    background: transparent;\n    position: relative;\n    outline: none;\n    padding: 0 18px 0 6px;\n    font-family: var(--roboto);\n    @include font(14px, 400, 22px);\n    color: var(--Gray-gray-9);\n\n    &::placeholder {\n      color: var(--Gray-gray-6);\n      font-family: Roboto;\n      font-family: var(--roboto);\n      @include font(14px, 400, 22px);\n    }\n  }\n}\n","@mixin font($size: 14px, $weight: 400, $lineHeight: 18px) {\n  font-size: $size;\n  line-height: $lineHeight;\n  font-weight: $weight;\n  font-family: 'Arial';\n}\n\n@mixin maxHeight() {\n  height: calc(100vh - 48px);\n}\n\n@mixin mobile {\n  @media (max-width: 767px) {\n    @content;\n  }\n}\n\n@mixin tablet {\n  @media (max-width: 1224px) {\n    @content;\n  }\n}\n\n@mixin laptop {\n  @media (max-width: 1440px) {\n    @content;\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
