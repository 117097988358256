import {useTranslation} from "react-i18next";
import {showSuccess} from "common/utils/notification";
import Copy from "common/icons/copy";
import './styles.scss'

const CopyContent = ({ children, copyText }) => {
  const { t } = useTranslation();

  return (
    <div className="copy-content">
      {children} <Copy className="icon" onClick={() => {
      navigator.clipboard.writeText(copyText);
      showSuccess(t("notification.copiedClipboard"));
    }}/>
    </div>
  );
};

export default CopyContent;