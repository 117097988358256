import {getDeviceSize} from "common/utils/responsive";
import SelectLanguage from "common/ui/selectLanguage";
import './styles.scss'
import BitokIcon from "common/icons/bitok";

const AuthTemplate = ({ title, children }) => {
  const {isMobile} = getDeviceSize();

  return (
    <div className="auth">
      {!isMobile && <div className="auth-imgs">
        <img
          alt="okman"
          src={"./okman-big.png"}
          className="auth-imgs__okman-big"
        />
        <img
          alt="okman-small"
          src={"./okman-small.png"}
          className="auth-imgs__okman-small"
        />
      </div>}
      <div className="auth-content">
        <div className="auth-box">
          <div className="auth-box__title">
            {title}
          </div>
          {children}
          <SelectLanguage/>
          {!isMobile && <div className="auth-logo__by-bitok"><BitokIcon/></div>}
        </div>
      </div>
    </div>
  );
};

export default AuthTemplate;