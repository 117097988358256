import {useParams, Link} from "react-router-dom";
import BackButton from "common/icons/backButton";
import Logo from "common/icons/logo";
import EditIcon from "common/icons/edit";
import formatDate, {EFormats} from "common/utils/formatDate";
import {HeaderMenuDropdown} from "common/ui/headerMenuDropdown/headerMenuDropdown";
import React, {useEffect} from "react";
import {useProjectStore} from "store/projects";
import {modalTypes, useModalStore} from "store/modal";
import {useTranslation} from "react-i18next";
import {paths} from "../../../config/paths";
import SelectLanguage from "common/ui/selectLanguage";
import "../mainHeader.scss";

export function MainContent ({isMobile, hideProject}) {
  const { getSingleProject,singleProject, graphEl } = useProjectStore(state => state);
  const { openModal } = useModalStore((state) => state);
  const {t} = useTranslation();
  const { projectId } = useParams();

  useEffect(() => {
      getSingleProject({id:projectId});
  }, [projectId, getSingleProject]);

  const items = [
    {
      label: (<span>{t("initial.editName")}</span>),
      key: modalTypes.editProject,
    },
    {
      label: (<span>{t("initial.deleteProject")}</span>),
      danger: true,
      key: modalTypes.deleteProject,
    },
  ];

  const refreshGraph = () => {
    graphEl.render();
    graphEl.fitCenter();
  }

  const handleEdit = () => {
    openModal(modalTypes.editProject,{network:projectId,editName:singleProject.name})
  };

	return <>
		<Link className="logo" to={paths.HOME}>{isMobile ? <BackButton/> : <Logo/>}</Link>
		{!hideProject && <div className="edit-wrapper">
      <span>{singleProject?.name} {singleProject && formatDate(singleProject?.created_at, EFormats.LT)}</span>
			<EditIcon onClick={handleEdit}/>
		</div>}
    <div className='header-left'>
      <span className="refresh" onClick={refreshGraph}>&#9851;</span>
      {!isMobile && <SelectLanguage
        placement="top"
        renderInWrapper={true}/>}
      {isMobile ? <HeaderMenuDropdown items={items}/> : <div className="menu">
        <span>john.doe@gmail.com</span>
      </div>}
    </div>
	</>
}
