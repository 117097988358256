import RussianFlag from "../common/icons/russianFlag";
import BritishFlag from "../common/icons/britishFlag";

export const ELanguages = {
  enUS: "en-US",
  ruRU: "ru-RU",
};

export const AVAILABLE_LANGUAGES = [ELanguages.enUS, ELanguages.ruRU];

export const LANGUAGES = {
  [ELanguages.ruRU]: {
    nativeName: 'Русский',
    icon: RussianFlag
  },
  [ELanguages.enUS]: {
    nativeName: 'English',
    icon: BritishFlag
  },
};

export const FALLBACK_LNG = {
  [ELanguages.ruRU]: [ELanguages.ruRU],
  [ELanguages.enUS]: [ELanguages.enUS],
};
