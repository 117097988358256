import compressText from "common/utils/compressText";
import {edgePercentageAnchors} from "./utils/edgePercentageAnchors";
import {getTransfers} from "./utils/getTransfers";
import {getWalletHeight} from "./utils/getWalletHeight";
import {wordBreak} from "common/utils/wordBreak";
import {setNodeBackground} from "./utils/setNodeBackground";
import {moneyFormat} from "common/utils/tokenAssetFormat/moneyFormat";
import {moneyFormatWithAsset} from "common/utils/tokenAssetFormat/moneyFormatAsset";

function setPoints(wallet, points) {
	return wallet.map(item => {
		const breakLength = 10;
		const node = {
			...item,
			title: wordBreak(item.entity_name,breakLength,2) || compressText(item.address, 6, 4),
			name: 'node',
			isBreak: !!wordBreak(item.entity_name,breakLength,2) && item.entity_name.length > breakLength,
      turnOver: `${moneyFormat(item.transfer_count)} ($${moneyFormat(item.turnover_usd)})`,
			entity_category: item.entity_category ? compressText(item.entity_category, 10, 0) : null,
			layoutOrder: 0,
			x: item.pos_x,
			y: item.pos_y,
			style: {
				height: 51,
			},
		};

		if (points[item.id]) {
			return {
				...node,
				anchorPoints: points[item.id],
				style: {
					height: getWalletHeight(points[item.id]),
				}
			}
		}

		return node;
	});
}

export function getNodes(transfers = [], wallets = []) {
	const mergedData = getPoints(transfers, wallets).nodes;
	return setPoints(wallets, mergedData);
}

function setIndexByDate(data) {
	const result = {};
	const sortedData = getTransfers(data)

	for (const item of sortedData) {
		if (!result[item.id]) {
			result[item.id] = item;
		} else {
			result[item.id] = {...result[item.id], ...item};
		}
	}

	return Object.values(result);
}

// hard data
export const getEdges = (data) => {
	if (!data.length) {
		return [];
	}

	return setIndexByDate(data)
		.map(item => {
			return {
				source: item.sender_id,
				target: item.recipient_id,
				type: 'hvh',
				id: item.id,
				text: moneyFormatWithAsset({amount: item.amount, asset: item.token_symbol,disableSign:true,short:true}),
				sourceAnchor: item.sourceAnchor,
				targetAnchor: item.targetAnchor,
			}
		});
}

function sortByOccurredAt(data) {
	return data.sort((a, b) => new Date(b.occurred_at) - new Date(a.occurred_at))
}

const getFillOfAnchors = (nodes = [], item) => {
	const node = nodes.find(({id}) => (item.direction ? item.recipient_id : item.sender_id) === id) || null;
	return node ? setNodeBackground(node?.entity_category)?.bgColor : null;
}

// data: transfer[]
export function getPoints(data, wallets) {
	const nodes = {};
	const sortedData = sortByOccurredAt(data);
	const transfers = [];

	function sortData(item, key, direction) {
		if (!nodes[item[key]]) {
			nodes[item[key]] = [{...item, direction}];
		} else {
			nodes[item[key]].push({...item, direction});
		}
	}
	// should sort by date
	sortedData.forEach(item => {
		sortData(item, 'recipient_id', 0);
		sortData(item, 'sender_id', 1);
	});

	for (const [id, val] of Object.entries(nodes)) {
		nodes[id] = val.map((item, index) => {
			const obj = {};
			obj[item.direction ? 'sourceAnchor' : 'targetAnchor'] = index;
			transfers.push({...item, ...obj});
			// source слева 0
			// target откуда 1
			return [item.direction, edgePercentageAnchors(val.length)[index], { fill: getFillOfAnchors(wallets, item) }]
		});
	}

	return {
		nodes,
		transfers,
	};
}
