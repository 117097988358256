import React, {useEffect, useMemo, useState} from 'react';
import {ConfigProvider, Table} from 'antd';
import {useNavigate} from "react-router-dom";
import {InitialSearchBar} from "../initial-search-bar/initial-search-bar";
import {PaginationComponent} from "common/ui/pagination/pagination";
import {getDeviceSize} from "common/utils/responsive";
import {useTranslation} from "react-i18next";
import {GetColumns} from "./component/initial-table-header";
import {useProjectStore} from "store/projects";
import InitialTableMobile from "./component/initial-table-mobile";
import {DEFAULT_PAGE_LIMIT} from "./constants";
import {InitialTableItem} from "./component/initial-table-item";
import ShowMore from "./component/showMore";
import useQueryState, {EShowMode} from "hooks/useQueryState";
import "./initial-table.scss";

export const InitialTable = () => {
  const {isMobile} = getDeviceSize()
  const { t } = useTranslation();
  const { projects,updateProjectParams, params, loadProjects} = useProjectStore(state => state);
  const { page, setPage,setShowMode } = useQueryState();
  const columns = GetColumns(t);
  const navigate = useNavigate();

  const showMoreDeleteLastItem = useMemo(() => {
    return (projects?.next === null && projects.count === (projects.results?.length + 1)
      && projects.previous !== null && projects.count % DEFAULT_PAGE_LIMIT === 1)
  }, [projects.count,projects.results?.length,projects.previous]);

  const deleteLastItem = useMemo(() => {
    return (projects.results?.length === 0 && projects.next === null)
  },[projects.results?.length,projects.next])

  const loadPage = async (page, reload = true) => {
    setPage(page)
    setShowMode(EShowMode.page);
    updateProjectParams({params: {...params, page: page}, fetching: false, reload});

    if (!reload) {
      await loadProjects();
    }
  };

  useEffect(() => {
    if (deleteLastItem || showMoreDeleteLastItem) {
      updateProjectParams({params: {...params, page: page - 1 }, fetching: false});
      if (page > 1) {
        setPage(projects.previous)
      }
    }
  },[projects.results?.length])

  const onClick = (path) => {
    navigate(`/${path}`);
  };

  if (projects.results?.length === 0) {
    return null;
  }

  return <div className="initial-detail-table">
    {isMobile ? <InitialTableMobile projects={projects?.results} page={page} loadPage={loadPage} onClick={onClick}/> :
      <>
        <InitialSearchBar/>
        <h4>{t("initial.projects")}</h4>
        <ConfigProvider
          theme={{
            components: {
              Table: {
                bodySortBg: 'transparent',
              }
            }
          }}>
          <Table
            columns={columns}
            dataSource={InitialTableItem(projects?.results)}
            pagination={false}
            rowHoverable={false}
            onRow={({key}) => ({onClick: () => onClick(key)})}
          />
        </ConfigProvider>
      </>
    }
    <>
      <ShowMore loadPage={loadPage} page={page}/>
      {loadPage && projects?.count > DEFAULT_PAGE_LIMIT && <PaginationComponent
        className="pagination"
        pageClick={loadPage}
        page={page}/>
      }
    </>
  </div>;
};
