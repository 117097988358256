const BitokIcon = () => (
  <svg width="69" height="24" viewBox="0 0 76 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 2.46484H9.37938C11.1842 2.46484 12.597 2.91148 13.6179 3.80476C14.6388 4.69803 15.1492 5.99237 15.1492 7.68777C15.1492 8.54458 14.9487 9.32847 14.5476 10.0394C14.1465 10.7322 13.5996 11.27 12.9069 11.6528C13.8549 11.9992 14.5658 12.5279 15.0398 13.2388C15.5138 13.9316 15.7508 14.8431 15.7508 15.9733C15.7508 17.1218 15.4865 18.1245 14.9578 18.9813C14.4473 19.8199 13.7364 20.4671 12.8249 20.9228C11.9316 21.3603 10.9381 21.5791 9.84425 21.5791H0V2.46484ZM8.58637 10.2309C9.31558 10.2309 9.88982 10.0486 10.3091 9.68396C10.7284 9.31936 10.9381 8.736 10.9381 7.93387C10.9381 7.05883 10.7284 6.45724 10.3091 6.12909C9.90805 5.80095 9.28823 5.63688 8.44965 5.63688H4.15646V10.2309H8.58637ZM8.83248 18.4071C9.72575 18.4071 10.4003 18.2339 10.856 17.8875C11.33 17.5229 11.567 16.894 11.567 16.0007C11.567 15.0892 11.3209 14.4329 10.8287 14.0318C10.3547 13.6308 9.64372 13.4302 8.69575 13.4302H4.15646V18.4071H8.83248Z" fill="#21130a"/>
    <path d="M20.3483 6.42991C19.7102 6.42991 19.1724 6.22027 18.7349 5.80097C18.2974 5.38168 18.0786 4.85301 18.0786 4.21496C18.0786 3.59513 18.2974 3.07558 18.7349 2.65628C19.1724 2.21876 19.7102 2 20.3483 2C20.9863 2 21.5241 2.21876 21.9616 2.65628C22.3991 3.07558 22.6179 3.59513 22.6179 4.21496C22.6179 4.85301 22.3991 5.38168 21.9616 5.80097C21.5241 6.22027 20.9863 6.42991 20.3483 6.42991ZM18.27 8.45345H22.4265V21.5791H18.27V8.45345Z" fill="#21130a"/>
    <path d="M30.9456 21.9893C29.4143 21.9893 28.2476 21.57 27.4455 20.7314C26.6616 19.8746 26.2696 18.6896 26.2696 17.1765V11.4341H24.3555V8.45345H26.2696V4.51575H30.3987V8.45345H33.4887V11.4341H30.3987V16.6296C30.3987 17.3953 30.5446 17.9513 30.8363 18.2977C31.1279 18.6258 31.6475 18.7899 32.3949 18.7899C32.9965 18.7899 33.5708 18.6441 34.1177 18.3524V21.4424C33.2791 21.807 32.2218 21.9893 30.9456 21.9893Z" fill="#21130a"/>
    <path d="M58.0854 2.46484H62.2419V10.7778L69.461 2.46484H74.4105L66.3163 11.7895L74.9574 21.5791H70.0079L62.2419 12.8013V21.5791H58.0854V2.46484Z" fill="#21130a"/>
    <path d="M56.4002 9.52636C56.9447 11.5586 56.8035 13.7137 55.9984 15.6576C55.1932 17.6014 53.7692 19.2251 51.9471 20.2771C50.1251 21.3291 48.0068 21.7504 45.9208 21.4758C43.8349 21.2012 41.8979 20.246 40.4101 18.7583C38.9224 17.2705 37.9672 15.3335 37.6926 13.2476C37.418 11.1616 37.8393 9.04333 38.8913 7.22127C39.9432 5.39921 41.567 3.97517 43.5108 3.17003C45.4546 2.36489 47.6098 2.22363 49.642 2.76817L47.1684 12L56.4002 9.52636Z" fill="#21130a"/>
  </svg>

);

export default BitokIcon;