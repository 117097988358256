import React, {useEffect, useState} from 'react';
import {getEdges, getNodes} from "./utils";
import {initGraph, nodeCustomEvents, onNodeClickSetActive, registerFn} from "./graph";

import { debounce } from "lodash";
import {MainLayout} from "common/layout/main/MainLayout";
import {SearchPanel} from "./search-panel";
import {onDragNode} from "./utils/nodeDragging";
import {zoomCanvas} from "./utils/zoomCanvas";
import {BlockDetails} from "components/block-details";
import {modalTypes, useModalStore} from "store/modal";
import {DeleteModal} from "common/ui/modals/deleteModal/deleteModal";
import CreateEditProjectModal from "common/ui/modals/createEditProjectModal";
import "./creation.styles.scss";
import {useProjectStore} from "../../store/projects";

let eventsFixed = false;

export default function ({objects}) {
	let graph = null;
	const { updateObjectsPosition } = useProjectStore(s => s);
  const { openModal } = useModalStore((state) => state);
	const [pickedNode, setNode] = useState(false);
	const ref = React.useRef(null);
	const [el, setEl] = useState(null);

	useEffect(() => {
		if (graph) {
			graph.setMode('noneAvailable');
		}
	}, [graph]);

	useEffect(() => {
		if (el) {
			const nodes = getNodes(objects?.transfers, objects.wallets);
			const newEdges = getEdges(objects?.transfers || []);
			el.read({nodes, edges: newEdges});

			setTimeout(() => {
				for (const node of el.getNodes()) {
					const model = node.getModel();
					el.updateItem(node, {x: model.pos_x, y: model.pos_y }, true);
				}
			});
		}
	}, [el, objects]);

	registerFn();

	useEffect(() => {
		if (!graph && !el) {
			const nodes = getNodes(objects?.transfers, objects.wallets);
			const newEdges = getEdges(objects?.transfers || []);
			graph = initGraph(graph, {nodes, edges: newEdges}, ref);

			setEl(graph);
		}
	}, [objects.wallets]);

	const handleNodeClick = (event) => {
		if (event.target.cfg.name === 'delete-icon') {
      openModal(modalTypes.deleteProject, {network: event.item.getModel().id,
          editName: event.item.getModel().title,type:event.item.getModel().type}
      );
		} else {
			onNodeClickSetActive(graph, event);
			setNode(event.item);
		}
	};
	const handleNodeTouch = (event) => {
		if (event.target.cfg.name === 'delete-icon') {
			alert(`вы хотите удалить ${event.item.getModel().title}?`);
		}
	}

	useEffect(() => {
		if (!!graph) {
			graph?.on('dragend', debounce(() => {
				const data = graph.save().nodes;
				updateObjectsPosition(data);
			}, 500));

			graph.on('node:click', event => handleNodeClick(event));
			graph.on('node:touchstart', event => handleNodeTouch(event));
		}
	}, [graph]);

	useEffect(() => {
		window.oncontextmenu = () => false;
		const canvas = document.getElementsByTagName('canvas')[0];
		if (eventsFixed) return;

		const {addEvent, removeEvent} = onDragNode({graph, canvas, openAddressDetails});

		const { initZoom } = zoomCanvas({canvas, graph});

		addEvent();
		eventsFixed = true;
		initZoom();

		return () => {
			removeEvent();
			// removeZoomEvent();
		}
	}, []);

	const openAddressDetails = (event) => {
		onNodeClickSetActive(graph, event);
		setNode(event.item)
	}

	const onClose = () => {
		if (graph) {
			graph.setItemState(pickedNode, nodeCustomEvents.clicked, false);
		} else {
			console.error('Graph is null');
		}
		setNode(null);
	}

	return <MainLayout>
		<SearchPanel />
		<div className="graph-container" ref={ref}></div>
		<BlockDetails open={pickedNode} onClose={onClose} graph={el}/>
    <DeleteModal/>
    <CreateEditProjectModal/>
	</MainLayout>;
}
