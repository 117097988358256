import React, {useState, useRef} from "react"
import { debounce } from "lodash";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";

import {SearchInput} from "common/ui/searchInput/searchInput";
import Close from "common/icons/close";
import {useProjectStore} from "store/projects";
import RedSearchIcon from "common/icons/redSearchIcon";
import {getDeviceSize} from "common/utils/responsive";
import {useOutsideClick} from "common/utils/clickOutside";
import {SearchItem} from "./search-item/search-item";
import "./search-panel.scss";

export const SearchPanel = ({graph}) => {
	const { searchProject, searchData,resetSearchProject } = useProjectStore(state => state);
	const { isMobile } = getDeviceSize();
	const [searchValue, setSearch] = useState('');
	const [loading, setLoading] = useState(false);
	const ref = useRef(null);
	const [toggle, setToggle] = useState(true);
	const params = useParams();
  const {t} = useTranslation()

	// to close on clicking outside
	useOutsideClick(ref, onClose);

	const onChange = async (query) => {
		setLoading(true);
		setSearch(query);
		if (!query.trim().length) return;

		await searchProject({ query, id: params.projectId, cb: () => {
			setLoading(false);
		}});
	}
	function onClose(close = true) {
		setToggle(close);
		setSearch(null);
    resetSearchProject()
	}

	function onOpen(){
		if (toggle) {
			setToggle(false);
		}
	}

	return <div className="search-panel" ref={ref}>
		<div className={`wrapper${isMobile && !toggle ? ' open' : ''}`}>
			<div className={`search-block${toggle ? ' search-closed' : ''}`} onClick={() => onOpen(false)}>
				<SearchInput
					onRemove={() => onClose(false)}
					removeIcon={<Close className='search-close-icon'/>}
					hideRemoveIcon={toggle}
					searchIcon={<RedSearchIcon />}
					onChange={debounce(onChange, 150)}
          fetching={loading}
					placeholder={t("initial.enterTxHash")} width={isMobile ? '100%' : '385px'} />
				<div className="list">
					<SearchItem
						graph={graph}
						searchValue={searchValue}
						wallets={searchData?.wallets}
						transfers={searchData?.transfers}
            fetching={loading}
          />
				</div>
			</div>
		</div>
	</div>
};
