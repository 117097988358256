import React from "react";
import formatDate, {EFormats} from "common/utils/formatDate";
import {TableActions} from "../../table-actions/table-actions";
import EllipsAction from "common/icons/ellipsAction";
import {useTranslation} from "react-i18next";

const InitialTableMobile = ({ projects,onClick }) => {
  const {t} = useTranslation()
  return (
    <div className="initial-detail-table-mobile">
      {projects && projects.map((item) => (
        <div className="item" key={item.id} onClick={() => onClick(item.id)}>
          <div className="block">
            <b>{item.name}</b>
            <span>{formatDate(item.created_at, EFormats.D)} {formatDate(item.updated_at, EFormats.T)}</span>
          </div>
          <div className="block">
            <div className='block__with-icon'>
              <p>{item.network_name && item.network_code ? `${item.network_name} (${item.network_code})` : "―"}</p>
              <i>{item.account_count ? `${item.account_count} ${t('initial.addresses')}` : null}</i>
            </div>
            <div className=""><TableActions item={item} icon={<EllipsAction transform="rotate(90)"/>}/></div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default InitialTableMobile;
