import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useParams} from "react-router-dom";
import {BackTop,Table} from 'antd';
import { useVT } from 'virtualizedtableforantd4';
import {debounce} from "lodash";
import {useTranslation} from "react-i18next";

import {useProjectStore} from "store/projects";
import {useDrawerStore} from "store/drawer";
import {walletTransferItem} from "./components/dataTransfer";
import Columns from "./components/columns";
import Spinner from "common/ui/spinner";
import TransferIcon from "common/icons/transferIcon";
import {useAddNewTransfer} from "../../../utils/addTransfer";
import {setNodeActiveOnTableHover} from "../../../utils/setNodeActiveOnTableHover";
import "./detail-table.scss";

export const DetailTable = ({graph}) => {
  const {node} = useDrawerStore(s => s);
  const { onMouseLeave, onMouseEnter } = setNodeActiveOnTableHover({graph, node});
  const {walletTransfers, updateWalletTransfers, getWalletTransfer, clearWalletTransfers } = useProjectStore(state => state);
  const [loading, setLoading] = useState(false);
  const ref = useRef(null);
  const { projectId } = useParams();
  const { postNewTransfer } = useAddNewTransfer({graph, projectId});
  const {t} = useTranslation();

  const next_token = walletTransfers?.next_token;
  const columns = Columns({t});
  let positionY = 600;
  const y = 450;
  const visibilityHeight = 1000

  const data = useMemo(() => {
    return walletTransferItem(walletTransfers?.transfers);
  }, [walletTransfers]);

  const fetchData = () => {
    const id = node.getModel()?.id;
    getWalletTransfer({
      id,
      params: { next_token, limit: 20 }
    }).then(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    fetchData();
    return () => {
      clearWalletTransfers();
    }
  },[]);

  const [vt] = useVT(() => ({
    scroll: { y },
    overscanRowCount: 1,
    onScroll: debounce(({top, isEnd}) => {
      if (top > positionY || isEnd) {
        positionY = top + 600;
        if (isEnd) {
          setLoading(true);
        }
        fetchData();
      }
    }, 100)
  }));

  const onClick = (item) => {
    const key = item.key;

    if (key && !item.id) {
      setLoading(true);
      postNewTransfer(key)
        .then(transfer => {
          updateWalletTransfers({...item, id: transfer.id});
        })
        .finally(() => {
          setLoading(false);
      });
    }
  }

  return(
    <div id="scrollableDiv" className="table-wrapper" ref={ref}>
      <h4>{t("common.transfers")} <TransferIcon/></h4>
      {!walletTransfers ? <Spinner/> :
        <>
          <Table
            id="my-special-table"
            columns={columns}
            dataSource={data}
            rowHoverable={false}
            onRow={(record) => ({
              onClick: () => onClick(record),
              onMouseEnter: debounce(() => onMouseEnter(record), 100),
              onMouseLeave: debounce(() => onMouseLeave(record), 100),
            })}
            loading={loading}
            pagination={false}
            rowClassName={(record) => record.id ? 'added' : null}
            scroll={{ y }}
            components={vt}
          />
          <BackTop
            visibilityHeight={visibilityHeight}
            target={() => ref.current?.querySelector('.ant-table-body')} />
        </>
       }
    </div>
  )
}
